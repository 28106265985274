// @mui material components
import React, {useEffect, useState} from "react";
import Grid from "@mui/material/Grid";

import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import ReviewDashboardNavbar from "components/Navbars/ReviewDashboardNavbar";
import Footer from "components/Footer";
import PageLoading from "components/PageLoading"
import {apiUrl, chk} from "../../App";
import api from "../../utils/axiosConfig";
import Invoice from "../billing/components/Invoice";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Rating from '@mui/material/Rating';
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import Stack from '@mui/material/Stack';
import Navbar from "layouts/reviews/components/Navbar";
import isMobile from "react-swipe-to-delete-component/src/js/utils/isMobile";
import Typography from "@mui/material/Typography";
import MDInput from "../../components/MDInput";
import {Link} from "react-router-dom";
import Icon from "@mui/material/Icon";


function Dashboard() {

    const [loading, setLoading] = React.useState(true);
    const [dash, setDash] = React.useState({});
    const [business, setBusiness] = React.useState({});
    const [reviews, setReviews] = React.useState({});
    const [sources, setSources] = React.useState({});
    const [contacts, setContacts] = React.useState({});
    const [error, setError] = React.useState(false);

    useEffect(() => {
        // axios.defaults.withCredentials = true;
        const ck = chk();
        const session = ck.session;
        const cs = ck.cs;

        api.post(apiUrl() + "api/v2/birdeye/Dashboard/", {cs: cs, session: session}).then(response => {
            if (response.data.status === 'ok') {
                setDash(response.data.dashboard);
                setBusiness(response.data.business);
                setReviews(response.data.reviews);
                setSources(response.data.sources);
                // setContacts(response.data.contacts);
                let new_posts_arr = [];
                let posts_array = response.data.contacts.contacts;
                for (let i = 0; i < posts_array.length; i++) {
                    let temp_obj = {};
                    temp_obj.name = posts_array[i].firstName + ' ' + posts_array[i].lastName;
                    temp_obj.email = posts_array[i].email;
                    temp_obj.phone = posts_array[i].phone;
                    new_posts_arr.push(temp_obj);
                }
                setContacts(new_posts_arr);
            }else{
                setError(response.data.message);
            }
        }).then(response => {
            setLoading(false);
        });
    }, []);


    const [isDeviceMobile, setIsMobile] = useState(false);

    function handleWindowSizeChange() {
        if (window.innerWidth <= 768) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
    }

    useEffect(() => {
        handleWindowSizeChange();
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);


    if (loading) {
        return (<PageLoading/>)
    }
    if (error) {
        return (
            <DashboardLayout>
                <DashboardNavbar />
                {error}
            </DashboardLayout>
                )
    }

    return (<DashboardLayout>
        <ReviewDashboardNavbar contacts={contacts}/>

            <Grid
                container
                spacing={0}
                direction="row"
                sx={{marginBottom:"20px"}}
            >
                <Grid item sx={{backgroundColor: "#1976d2", padding: "10px"}} xs={12} lg={3}>
                    <Paper sx={{textAlign: "center"}}>
                        Reviews Received<br/>
                        <b>{dash.allTime.reviewCount.count}</b>
                    </Paper>
                </Grid>
                <Grid item sx={{backgroundColor: "#1976d2", padding: "10px"}} xs={12} lg={3}>
                    <Paper sx={{textAlign: "center"}}>
                        Overall Rating: <b>{dash.allTime.benchmark.rating}</b><br/>
                        <Rating defaultValue={dash.allTime.benchmark.rating} precision={0.1} readOnly/>
                    </Paper>
                </Grid>
                <Grid item sx={{backgroundColor: "#1976d2", padding: "10px"}} xs={12} lg={3}>
                    <Paper sx={{textAlign: "center"}}>
                        3★ or less<br/>
                        <b>{dash.allTime.sentiments.negative}</b>
                    </Paper>
                </Grid>
                <Grid item sx={{backgroundColor: "#1976d2", padding: "10px"}} xs={12} lg={3}>
                    <Paper sx={{textAlign: "center"}}>
                        Haven't Responded<br/>
                        <b>{sources.totals.no_response}</b>
                    </Paper>
                </Grid>
            </Grid>

            <Grid
                container
                spacing={0}
                direction="row"
            >
                <Grid item sx={{backgroundColor: "#1976d2", padding: "10px"}} xs={12} lg={4}>
                    <Paper sx={{minHeight: "360px", textAlign: "center"}}>
                        <Box sx={{height: "50px", borderBottom: "1px solid #DDD", paddingTop: "10px"}}>
                            Benchmark Breakdown
                        </Box>
                        <Grid
                            container
                            spacing={0}
                            direction="row"
                            style={{minHeight: '50px'}}
                        >
                            <Grid item sx={{padding: "10px"}} xs={2} md={1}>
                                <b>5★</b>
                            </Grid>
                            <Grid item sx={{padding: "10px"}} xs={8} md={9}>
                                <Box sx={{height: "12px", width: "100%", border: "1px solid #EEE", marginTop: "12px"}}>
                                    <Box sx={{width: Math.round(sources.totals[5] / dash.allTime.reviewCount.count * 100) + '%', height: "10px", backgroundColor: "#faaf00"}}/>
                                </Box>
                            </Grid>
                            <Grid item sx={{padding: "10px"}} xs={2}>
                                {sources.totals[5]}<br/>
                            </Grid>


                            <Grid item sx={{padding: "10px"}} xs={2} md={1}>
                                <b>4★</b>
                            </Grid>
                            <Grid item sx={{padding: "10px"}} xs={8} md={9}>
                                <Box sx={{height: "12px", width: "100%", border: "1px solid #EEE", marginTop: "12px"}}>
                                    <Box sx={{width: Math.round(sources.totals[4] / dash.allTime.reviewCount.count * 100) + '%', height: "10px", backgroundColor: "#faaf00"}}/>
                                </Box>
                            </Grid>
                            <Grid item sx={{padding: "10px"}} xs={2}>
                                {sources.totals[4]}<br/>
                            </Grid>


                            <Grid item sx={{padding: "10px"}} xs={2} md={1}>
                                <b>3★</b>
                            </Grid>
                            <Grid item sx={{padding: "10px"}} xs={8} md={9}>
                                <Box sx={{height: "12px", width: "100%", border: "1px solid #EEE", marginTop: "12px"}}>
                                    <Box sx={{width: Math.round(sources.totals[3] / dash.allTime.reviewCount.count * 100) + '%', height: "10px", backgroundColor: "#faaf00"}}/>
                                </Box>
                            </Grid>
                            <Grid item sx={{padding: "10px"}} xs={2}>
                                {sources.totals[3]}<br/>
                            </Grid>


                            <Grid item sx={{padding: "10px"}} xs={2} md={1}>
                                <b>2★</b>
                            </Grid>
                            <Grid item sx={{padding: "10px"}} xs={8} md={9}>
                                <Box sx={{height: "12px", width: "100%", border: "1px solid #EEE", marginTop: "12px"}}>
                                    <Box sx={{width: Math.round(sources.totals[2] / dash.allTime.reviewCount.count * 100) + '%', height: "10px", backgroundColor: "#faaf00"}}/>
                                </Box>
                            </Grid>
                            <Grid item sx={{padding: "10px"}} xs={2}>
                                {sources.totals[2]}<br/>
                            </Grid>


                            <Grid item sx={{padding: "10px"}} xs={2} md={1}>
                                <b>1★</b>
                            </Grid>
                            <Grid item sx={{padding: "10px"}} xs={8} md={9}>
                                <Box sx={{height: "12px", width: "100%", border: "1px solid #EEE", marginTop: "12px"}}>
                                    <Box sx={{width: Math.round(sources.totals[1] / dash.allTime.reviewCount.count * 100) + '%', height: "10px", backgroundColor: "#faaf00"}}/>
                                </Box>
                            </Grid>
                            <Grid item sx={{padding: "10px"}} xs={2}>
                                {sources.totals[1]}<br/>
                            </Grid>


                        </Grid>
                    </Paper>
                </Grid>
                <Grid item sx={{backgroundColor: "#1976d2", padding: "10px"}} xs={12} lg={4}>
                    <Paper sx={{minHeight: "360px", textAlign: "center"}}>
                        <Box sx={{height: "50px", borderBottom: "1px solid #DDD", paddingTop: "10px"}}>
                            Review Sources
                        </Box>

                        {sources.sources.map((source, key) => <Box key={key}>
                            {/*<b>{source.source}</b> = {source.count} | {source.avg} | {source.image}*/}

                            <Grid
                                container
                                spacing={0}
                                direction="row"
                                style={{minHeight: '50px'}}
                            >
                                <Grid item sx={{padding: "10px"}} xs={2}>
                                    <img alt={source.source} style={{height: "35px"}} src={"/images/review_sources/" + source.image}/>
                                </Grid>
                                <Grid item sx={{padding: "10px"}} xs={3}>
                                    <Rating defaultValue={source.avg} precision={0.1} readOnly size={isDeviceMobile ? "small" : "small"}  />
                                </Grid>

                                <Grid item sx={{padding: "10px"}} xs={3} md={5}>
                                    {(!isDeviceMobile) ?
                                    source.source
                                        :<></> }
                                    <span style={{fontSize: "12px"}}>&nbsp;&nbsp;&nbsp;({source.count})</span>

                                </Grid>

                                <Grid item sx={{padding: "10px"}} xs={3} md={2}>
                                    <b>{source.avg} <span style={{color: "#faaf00"}}>★</span></b>
                                </Grid>
                            </Grid>

                        </Box>)}
                    </Paper>
                </Grid>
                <Grid item sx={{backgroundColor: "#1976d2", padding: "10px"}} xs={12} lg={4}>
                    <Paper sx={{minHeight: "350px", textAlign: "center"}}>
                        <Box sx={{height: "50px", borderBottom: "1px solid #DDD", paddingTop: "10px"}}>
                            Latest Reviews
                        </Box>
                        <Box sx={{height: "300px", margin: "10px", overflowX: "scroll"}}>
                            {reviews.slice(0, 10).map((review, key) =>
                                <Box key={key} sx={{borderBottom: "1px solid #DDD", marginBottom: "10px"}}>
                                    <Grid
                                        container
                                        spacing={0}
                                        direction="row"
                                    >
                                        <Grid item sx={{padding: "10px"}} xs={1.5}>
                                            <img style={{width: "30px"}} src={"/images/review_sources/google.png"} alt={review.sourceType}/>
                                            {/*<img style={{width: "30px"}} src={"/images/review_sources/" + review.image} alt={review.sourceType}/>*/}
                                        </Grid>

                                        <Grid item sx={{padding: "10px", textAlign: "left"}} xs={5.5}>
                                            <Box><Rating defaultValue={review.rating} precision={0.1} readOnly size={"small"}/></Box>
                                            <Box sx={{fontSize: "12px", marginTop: "-10px", marginLeft: "5px", fontWeight: "bold"}}>{review.reviewer.nickName}</Box>
                                        </Grid>
                                        <Grid item sx={{padding: "10px", textAlign: "right"}} xs={5}>
                                            <span style={{fontSize: "12px", fontWeight: "bold"}}>{review.reviewDate}</span>
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        container
                                        spacing={0}
                                        direction="row"
                                    >
                                        <Grid item sx={{padding: "10px", textAlign: "left", fontSize: "14px", marginTop: "-15px"}} xs={12}>
                                            {review.comments}
                                        </Grid>
                                    </Grid>

                                </Box>
                            )}
                        </Box>

                    </Paper>
                </Grid>


            </Grid>
            {/*<div style={{borderBottom: "1px solid #DDD", marginBottom: "10px", padding: "5px"}}>*/}
            {/*    <pre>*/}
            {/*            {JSON.stringify(contacts, null, 2)}*/}
            {/*        </pre>*/}
            {/*</div>*/}


        <Footer/>
    </DashboardLayout>);
}

export default Dashboard;
