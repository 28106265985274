/**
 =================================
 YourWebPro Contractor Portal Application V2
 NodeJS  |  React  |  MaterialUI  |  Material Dash 2
 2022 / 2023
 =================================
 ==========================================================
 Main components such as charts, boxes, sidenav, dash found in /components directory

 Layouts such at Dash, Profile, MapYourProjects, Billing are located in /layouts

 Theme files for colors and base layout definitions found in /assets/theme
 ==========================================================
 */


import React, {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import Select from 'react-select';


export default function HoursSelect({day, pass, buildHours, setBuildHours}) {

    let initialClosed = true;
    if (day) {
        initialClosed = false;
    }

    const [isClosed, setIsClosed] = useState(initialClosed);

    function handleOpenChange(e) {
        const tempObject = buildHours.filter(i => i.openDay !== pass);
        let tempHours = buildHours.filter(i => i.openDay === pass)[0];

        if(!tempHours){
            tempHours = {}
            tempHours.openDay = pass;
            tempHours.closeDay = pass;
            tempHours.openTime = {};
            tempHours.closeTime = {};
        }


        if (e.value === '24') {
            alert(24);
            tempHours.closeTime.hours = e.value;
        }

        console.log(tempHours);

        tempHours.openTime.hours = e.value;
        tempObject.push(tempHours);
        setBuildHours(tempObject);

    }

    function handleCloseChange(e) {
        const tempObject = buildHours.filter(i => i.openDay !== pass);
        let tempHours = buildHours.filter(i => i.openDay === pass)[0];

        if(!tempHours){
            tempHours = {}
            tempHours.openTime = {};
            tempHours.closeTime = {};
        }

        if (e.value === '24') {
            alert(24);
            tempHours.openTime.hours = e.value;
        }

        console.log(tempHours);

        tempHours.closeTime.hours = e.value;
        tempObject.push(tempHours);
        setBuildHours(tempObject);
    }

    function handleChange(checked) {
        console.log("initial " + isClosed + " -> " + checked)
        if(checked) {
            const tempObject = buildHours.filter(i => i.openDay !== pass);
            setBuildHours(tempObject);
        }

        setIsClosed(checked);

    }

    const hours = [
        {value: '24', label: '24HR'},
        {value: '0', label: '12AM'},
        {value: '1', label: '1AM'},
        {value: '2', label: '2AM'},
        {value: '3', label: '3AM'},
        {value: '4', label: '4AM'},
        {value: '5', label: '5AM'},
        {value: '6', label: '6AM'},
        {value: '7', label: '7AM'},
        {value: '8', label: '8AM'},
        {value: '9', label: '9AM'},
        {value: '10', label: '10AM'},
        {value: '11', label: '11AM'},
        {value: '12', label: '12PM'},
        {value: '13', label: '1PM'},
        {value: '14', label: '2PM'},
        {value: '15', label: '3PM'},
        {value: '16', label: '4PM'},
        {value: '17', label: '5PM'},
        {value: '18', label: '6PM'},
        {value: '19', label: '7PM'},
        {value: '20', label: '8PM'},
        {value: '21', label: '9PM'},
        {value: '22', label: '10PM'},
        {value: '23', label: '11PM'},
    ];


    return (
        <Box>
            <Grid container
                  direction="row"
                // justifyContent="space-between"
                // alignItems="center"
                  spacing={3}
            >

                <Grid item xs={4}>
                    <Checkbox
                        defaultChecked={(!day)}
                        onChange={e => {
                            handleChange(e.target.checked);
                        }}
                    /> closed
                </Grid>
                <Grid item xs={4} className={pass}>
                    {(!isClosed) ?
                        <Select
                            placeholder={"Open"}
                            defaultValue={(day) ? hours.filter(item => item.value == day.openTime.hours) : ''}
                            onChange={e => handleOpenChange(e)}
                            options={hours}
                        />
                        : <></>}
                </Grid>
                <Grid item xs={4} className={pass}>
                    {(!isClosed) ?
                        <Select

                            placeholder={"Close"}
                            defaultValue={(day) ? hours.filter(item => item.value == day.closeTime.hours) : ''}
                            onChange={e => handleCloseChange(e)}
                            options={hours}
                        />
                        : <></>}
                </Grid>

            </Grid>
            {/*{JSON.stringify(day, null, 2)}*/}
        </Box>
    )
}