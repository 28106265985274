/**
 =================================
 YourWebPro Contractor Portal Application V2
 NodeJS  |  React  |  MaterialUI  |  Material Dash 2
 2022 / 2023
 =================================
 ==========================================================
 Main components such as charts, boxes, sidenav, dash found in /components directory

 Layouts such at Dash, Profile, MapYourProjects, Billing are located in /layouts

 Theme files for colors and base layout definitions found in /assets/theme
 ==========================================================
 */

// @material-ui core components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import React, {useEffect, useState} from "react";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import colors from "assets/theme/base/colors"

const ywpBlue = colors.ywpBlue.main;
const ywpWhite = colors.ywpWhite.main;

import FormField from "components/FormField";
import selectData from "layouts/profile/components/BasicInfo/data/selectData";
import MDButton from "components/MDButton";
import {useSnackbar} from "notistack";
import CircularProgress from "@mui/material/CircularProgress";
import api from "utils/axiosConfig";
import {apiUrl, chk} from "App";
import Cookies from 'universal-cookie';
const cookies = new Cookies();

function BasicInfo({profile}) {
    // Set Form Data
    const [firstName, setFirstName] = React.useState(profile.contractor.info.firstname);
    const [lastName, setLastName] = React.useState(profile.contractor.info.lastname);
    const [addr1, setAddr] = React.useState(profile.contractor.info.address);
    const [addr2, setAddr2] = React.useState(profile.contractor.info.address2);
    const [city, setCity] = React.useState(profile.contractor.info.city);
    const [state, setState] = React.useState(profile.contractor.info.state);
    const [zip, setZip] = React.useState(profile.contractor.info.zip);
    const [email1, setEmail1] = React.useState(profile.contractor.info.contractoremail);
    const [email2, setEmail2] = React.useState(profile.contractor.info.contractorEmail2);
    const [phone, setPhone] = React.useState(profile.contractor.info.contractorPhone);
    const [altPhone, setAltPhone] = React.useState(profile.contractor.info.contractorAltPhone);
    // const [businessName, setBusinessName] = React.useState(profile.contractor.website_info.businessname.value);
    const [businessName, setBusinessName] = React.useState(profile.contractor.info.contractor);
    const [updateLoading, setUpdateLoading] = React.useState(false);

    const {enqueueSnackbar} = useSnackbar();

    // Set selected languages --
    let langs_text = [];

    if (profile.contractor.config.lang_en === '1') {
        langs_text.push("English");
    }
    if (profile.contractor.config.lang_es === '1') {
        langs_text.push("Español");
    }
    if (profile.contractor.config.lang_fr === '1') {
        langs_text.push("French");
    }

    const langs = {
        language: langs_text,
    }


    function updateProfile() {
        setUpdateLoading(true);
        const ck = chk();
        const session = ck.session;
        const cs = ck.cs;
        api.post(apiUrl() + "api/v2/profile/update/", {
            cs: cs,
            session: session,
            firstName: firstName,
            lastName: lastName,
            addr1: addr1,
            addr2: addr2,
            city: city,
            state: state,
            zip: zip,
            email1: email1,
            email2: email2,
            phone: phone,
            altPhone: altPhone,
            businessName: businessName,
        }).then(response => {
            console.log(response);
            if (response.data.status === "ok") {
                const message = "Your Profile has been saved.";
                const variant = "success";
                enqueueSnackbar(message, {variant});
                setUpdateLoading(false);
            }

        });
    }


    return (
        <Card id="basic-info" sx={{overflow: "visible"}}>
            <MDBox p={3}>
                <MDTypography variant="h5">Basic Info</MDTypography>
            </MDBox>
            <MDBox component="form" pb={3} px={3}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <FormField
                            label="First Name *"
                            placeholder="Abe"
                            defaultValue={firstName}
                            onKeyUp={(e) => setFirstName(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormField
                            label="Last Name *"
                            placeholder="Froman"
                            defaultValue={lastName}
                            onKeyUp={(e) => setLastName(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={6}>
                                <FormField
                                    label="Address 1 *"
                                    placeholder="123 EZ St"
                                    defaultValue={addr1}
                                    onKeyUp={(e) => setAddr(e.target.value)}/>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormField
                                    label="Address 2"
                                    placeholder="Box 123"
                                    defaultValue={addr2}
                                    onKeyUp={(e) => setAddr2(e.target.value)}
                                />
                            </Grid>

                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={4}>
                                <FormField
                                    label="City *"
                                    placeholder="Frankfort"
                                    defaultValue={city}
                                    onKeyUp={(e) => setCity(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <FormField
                                    label="State *"
                                    placeholder="KY"
                                    defaultValue={state}
                                    onKeyUp={(e) => setState(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <FormField
                                    label="Zip *"
                                    placeholder="95487"
                                    defaultValue={zip}
                                    onKeyUp={(e) => setZip(e.target.value)}
                                />
                            </Grid>

                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormField
                            label="Email *"
                            placeholder="example@email.com"
                            inputProps={{type: "email"}}
                            defaultValue={email1}
                            onKeyUp={(e) => setEmail1(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormField
                            label="Secondary email"
                            placeholder="example@email.com"
                            inputProps={{type: "email"}}
                            defaultValue={email2}
                            onKeyUp={(e) => setEmail2(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormField
                            label="Phone Number *"
                            placeholder="555 555 5555"
                            inputProps={{type: "text"}}
                            defaultValue={phone}
                            onKeyUp={(e) => setPhone(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormField
                            label="Cell Phone Number (for SMS)"
                            placeholder="555 555 5555"
                            inputProps={{type: "text"}}
                            defaultValue={altPhone}
                            onKeyUp={(e) => setAltPhone(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <MDTypography fontSize="12px">Language Spoken</MDTypography>
                        <Autocomplete
                            multiple
                            options={selectData.language}
                            filterSelectedOptions
                            ChipProps={{
                                style: {
                                    backgroundColor: ywpBlue,
                                    borderRadius: "5px",
                                    color: ywpWhite,
                                    fontFamily: "Source Sans Pro",
                                },
                            }}
                            defaultValue={langs.language}

                            renderInput={(params) => <FormField {...params} InputLabelProps={{shrink: true}}/>}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormField
                            label="Business Name *"
                            placeholder="Business Name"
                            inputProps={{type: "text"}}
                            defaultValue={businessName}
                            onKeyUp={(e) => setBusinessName(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <MDBox ml="auto" textAlign="right">

                            {updateLoading && (
                                <MDBox style={{width: "200px", textAlign: "center", float: "right"}}>
                                    <CircularProgress color="info" size={30}/>
                                </MDBox>
                            )}
                            {!updateLoading && (
                                <MDButton variant="gradient" color="info" size="small" onClick={updateProfile}>
                                    update profile
                                </MDButton>
                            )}

                        </MDBox>
                    </Grid>
                </Grid>
            </MDBox>
        </Card>
    );
}

export default BasicInfo;
