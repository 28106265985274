/**
 =================================
 YourWebPro Contractor Portal Application V2
 NodeJS  |  React  |  MaterialUI  |  Material Dash 2
 2022 / 2023
 =================================
 ==========================================================
 Main components such as charts, boxes, sidenav, dash found in /components directory

 Layouts such at Dash, Profile, MapYourProjects, Billing are located in /layouts

 Theme files for colors and base layout definitions found in /assets/theme
 ==========================================================
 */

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Material Dashboard 2 PRO React components
import MDInput from "components/MDInput";

function FormField({ label, ...rest }) {
    return (
        <MDInput
            variant="standard"
            label={label}
            fullWidth
            InputLabelProps={{ shrink: true }}
            {...rest}
        />
    );
}

// Setting default values for the props of FormField
FormField.defaultProps = {
    label: " ",
};

// Typechecking props for FormField
FormField.propTypes = {
    label: PropTypes.string,
};

export default FormField;
