/**
 =================================
 YourWebPro Contractor Portal Application V2
 NodeJS  |  React  |  MaterialUI  |  Material Dash 2
 2022 / 2023
 =================================
 ==========================================================
 Main components such as charts, boxes, sidenav, dash found in /components directory

 Layouts such at Dash, Profile, MapYourProjects, Billing are located in /layouts

 Theme files for colors and base layout definitions found in /assets/theme
 ==========================================================
 */


import React, {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import Select from 'react-select';
import DataTable from "components/Tables/DataTable";
import MDButton from "components/MDButton";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import FormField from "components/FormField";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import Typography from "@mui/material/Typography";
import 'react-dropzone-uploader/dist/styles.css'
import Dropzone from 'react-dropzone-uploader'
import Icon from "@mui/material/Icon";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import api from "../../../../utils/axiosConfig";
import {apiUrl, chk} from "App";
import {useSnackbar} from "notistack";
import Link from "@mui/material/Link";
import CircularProgress from "@mui/material/CircularProgress";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function TabPanel(props: TabPanelProps) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </div>
    );
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

export default function Posts({tableHeaderData, tableRowData, phone, setTableRowData, setDeletePost}) {
    const {enqueueSnackbar} = useSnackbar();
    const [value, setValue] = React.useState(0);
    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };


    const [updateOpen, setUpdateOpen] = React.useState(false);
    const [offerOpen, setOfferOpen] = React.useState(false);
    const [eventOpen, setEventOpen] = React.useState(false);
    const [fileName, setFileName] = React.useState('');
    const [descNum, setDescNum] = React.useState(0);
    const [descVal, setDescVal] = React.useState('');
    const [linkAction, setLinkAction] = React.useState('');
    const [postImage, setPostImage] = React.useState('');
    const [link, setLink] = React.useState('');
    const [linkLabel, setLinkLabel] = React.useState('');
    const [isLinkPhone, setLinkisPhone] = React.useState(false);


    const [allOpen, setAllOpen] = React.useState(false);
    const [CULoading, setCULoading] = React.useState(false);

    const handleUpdateOpen = (e) => {
        setUpdateOpen(true);
    };
    const handleOfferOpen = (e) => {
        setOfferOpen(true);
    };
    const handleEventOpen = (e) => {
        setEventOpen(true);
    };


    const handleUpdateClose = () => {
        setUpdateOpen(false);
    };
    const handleOfferClose = () => {
        setOfferOpen(false);
    };
    const handleEventClose = () => {
        setEventOpen(false);
    };


    function submitUpdate() {
        const ck = chk();
        const session = ck.session;
        const cs = ck.cs;
        setCULoading(true);
        api.post(apiUrl() + "api/v2/gmb/set/post/", {cs: cs, session: session, description: descVal, link: link, action: linkAction, image: postImage}).then(response => {
            if (response.data.status === 'ok') {
                const message = "Successfully Created Google Profile Post";
                const variant = "success";
                enqueueSnackbar(message, {variant});
                let new_posts_arr = [];
                let posts_array = response.data.posts.localPosts;
                for (let i = 0; i < posts_array.length; i++) {

                    let temp_obj = {};
                    temp_obj.title = posts_array[i].summary;
                    temp_obj.posted = posts_array[i].createTime;
                    temp_obj.link = <Link href={posts_array[i].searchUrl} target="_blank"> <Icon fontSize="large" style={{cursor: "pointer"}} color="success">visibility</Icon></Link>
                    temp_obj.type = posts_array[i].callToAction.actionType;
                    temp_obj.status = posts_array[i].state;
                    temp_obj.topic = posts_array[i].topicType;
                    temp_obj.actions = <div><Icon fontSize="large" style={{cursor: "pointer"}} color="info">edit</Icon><Icon fontSize="large" style={{cursor: "pointer"}} color="error" onClick={(e) => setDeletePost(e, posts_array[i]['name'])}>delete</Icon></div>;
                    new_posts_arr.push(temp_obj);
                }
                setTableRowData(new_posts_arr);
                setUpdateOpen(false);
            } else {
                let message = "There was an error creating your post.";
                if (response.data.status === 'error'){
                    message = response.data.message;
                }
                const variant = "error";
                enqueueSnackbar(message, {variant});
            }
        }).then(response => {
            setCULoading(false);

        });
    }

    function submitOffer() {
        alert('offer')
    }

    function submitEvent() {
        alert('event')
    }

    const getUploadParams = ({file, meta}) => {
        const ck = chk();
        const session = ck.session;
        const cs = ck.cs;
        const url = apiUrl() + 'api/v2/gmb/upload/photo/'
        // return {
        //     url, meta: {
        //         fileUrl:  `${url}/${encodeURIComponent(meta.name)}`,
        //
        //     },cs, session
        // }
        const body = new FormData()
        body.append('fileField', file)
        body.append('cs', cs)
        body.append('session', session)
        return {url: url, body}
    }


    const handlePhotoChangeStatus = ({meta, file, xhr}, status) => {
        if (status === 'done') {
            const file = JSON.parse(xhr.response)
            setFileName(file.file.name);
            setPostImage(file.file.url);
        }

    }

    const PhotosLayout = ({input, previews, submitButton, dropzoneProps, files, extra: {maxFiles}}) => {
        return (
            <div>
                {previews}

                <div {...dropzoneProps} style={{border: "5px dashed #DDD", overflow: "auto", marginTop: "20px"}}>
                    {files.length < maxFiles && input}
                </div>

                {files.length > 0 && submitButton}
            </div>
        )
    }

    const photosPreview = ({meta, files}) => {
        const {name, percent, status, previewUrl, size} = meta
        let fileSize = Math.round(size / 1024);

        return (

            <Box className={"preview-images-small"}>
                <div>
                    <img style={{height: "120px", maxWidth: "180px"}} src={previewUrl} alt={name + ", " + size + "kb"} title={name + ", " + size + "kb"}/>
                </div>
                <div>
                    <span style={{fontSize: "14px"}}>{name}</span>
                    <progress max="100" value={percent} style={{width: "120px", marginRight: "20px", marginBottom: "5px"}}></progress>
                    <Icon sx={{color: "#FF0000", cursor: "pointer"}} title="Delete Selected">cancel</Icon>
                </div>
            </Box>

        )
    }

    const types = [
        {value: 'BOOK', label: 'Book'},
        {value: 'ORDER', label: 'Order Online'},
        {value: 'SHOP', label: 'Buy'},
        {value: 'LEARN_MORE', label: 'Learn More'},
        {value: 'SIGN_UP', label: 'Sign Up'},
        {value: 'CALL', label: 'Call Now'},
    ];

    function setDescription(val) {
        setDescNum(val.length);
        setDescVal(val);
    }

    function handleChangeLinkType(e) {
        setLinkAction(e.value);
        if (e.value === 'CALL') {
            setLink(phone);
            setLinkLabel("Phone Number");
            setLinkisPhone(true);


        } else {
            setLink('');
            setLinkLabel("Link for your button *");
            setLinkisPhone(false);
        }
    }


    return (

        <Box style={{marginTop: "20px"}}>
            <DataTable table={
                {columns: tableHeaderData, rows: tableRowData}
            } canSearch/>
            <MDButton size="small" color="success" style={{float: "right", marginTop: "20px"}} onClick={handleUpdateOpen}>+ Add Update</MDButton>
            {/*<MDButton size="small" color="success" style={{float: "right", marginTop: "20px", marginRight: "20px"}} onClick={handleOfferOpen}>+ Add Offer</MDButton>*/}
            {/*<MDButton size="small" color="success" style={{float: "right", marginTop: "20px", marginRight: "20px"}} onClick={handleEventOpen}>+ Add Event</MDButton>*/}

            <Dialog
                fullWidth
                open={updateOpen}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleUpdateClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{"Add Update"}</DialogTitle>
                <DialogContent>
                    <Tabs value={value} onChange={handleTabChange} aria-label="basic tabs example">
                        <Tab label="Post Details" {...a11yProps(0)} />
                        <Tab label="Photos" {...a11yProps(1)} />
                    </Tabs>
                    <TabPanel value={value} index={0}>

                        <Box sx={{height: "160px"}} p={1}>
                            <FormControl fullWidth sx={{m: 1}}>
                                <TextField
                                    onKeyUp={(e) => setDescription(e.target.value)}
                                    // defaultValue={description}
                                    id="standard-multiline-flexible"
                                    label="Add a description"
                                    multiline
                                    variant="standard"
                                    inputProps={{
                                        maxLength: 1500,
                                        style: {
                                            height: "100px",
                                        },
                                    }}
                                />
                            </FormControl>
                            <Box sx={{float: "right"}}>
                                <Typography sx={{fontSize: "10px"}}>
                                    {descNum} / 1500
                                </Typography>
                            </Box>
                        </Box>
                        <Box sx={{height: "160px"}}>
                            <Typography>
                                Add a Button (optional)
                            </Typography>
                            <Select
                                placeholder={"None"}
                                onChange={e => handleChangeLinkType(e)}
                                options={types}
                                style={{height: "150px"}}
                                maxMenuHeight={150}
                            />
                            <Box sx={{height: "20px"}}></Box>

                            {isLinkPhone && linkLabel && (
                                <Box>
                                    <TextField
                                        fullWidth
                                        value={phone}
                                        label={linkLabel}
                                        variant="standard"
                                        disabled
                                    />
                                    <Typography sx={{fontSize: "10px"}}>
                                        Customers will call this number
                                    </Typography>
                                </Box>
                            )}
                            {!isLinkPhone && linkLabel && (
                                <Box>
                                    <TextField
                                        fullWidth
                                        defaultValue=''
                                        label={linkLabel}
                                        variant="standard"
                                        onChange={e => setLink(e.target.value)}
                                    />
                                    <Typography sx={{fontSize: "10px"}}>
                                        (Example: google.com)
                                    </Typography>
                                </Box>
                            )}
                        </Box>

                        {(fileName) ?
                            <Box pl={5}>
                                <Typography>
                                    Image:<br />{fileName}
                                </Typography>
                            </Box>
                            : <></>}
                        {(CULoading) ?
                            <Box>
                                <CircularProgress sx={{float: "right"}} color="info" size={40}/>
                            </Box>
                            :
                            <DialogActions>
                                <MDButton onClick={handleUpdateClose} variant="outlined" color="info">Cancel</MDButton>
                                <MDButton id="deletePinSubmit" onClick={submitUpdate} variant="outlined" color="error">Post</MDButton>
                            </DialogActions>
                        }


                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <Box sx={{p: 1, height: "390px"}}>
                            <Dropzone
                                getUploadParams={getUploadParams}
                                onChangeStatus={handlePhotoChangeStatus}
                                LayoutComponent={PhotosLayout}
                                PreviewComponent={photosPreview}
                                maxFiles={1}
                                accept="image/*,video/*"
                                inputContent={(files, extra) => (extra.reject ? 'Image and video files only accepted.' : '+ Add Photos')}
                                sx={{border: "1px solid #FF0000"}}
                                styles={{
                                    dropzoneReject: {borderColor: 'red', backgroundColor: '#DAA'},
                                    inputLabel: (files, extra) => (extra.reject ? {color: 'red'} : {}),
                                }}
                                initialFiles={[]}
                            />
                        </Box>

                    </TabPanel>

                </DialogContent>

            </Dialog>

            <Dialog
                open={offerOpen}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleOfferClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{"Offer"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        This will permanently remove the pin from MapYourProjects.
                        This action cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <MDButton onClick={handleOfferClose} variant="outlined" color="info">Cancel</MDButton>
                    <MDButton id="deletePinSubmit" onClick={submitOffer} variant="outlined" color="error">Delete Pin</MDButton>
                </DialogActions>
            </Dialog>

            <Dialog
                open={eventOpen}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleEventClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{"Event"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        This will permanently remove the pin from MapYourProjects.
                        This action cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <MDButton onClick={handleEventClose} variant="outlined" color="info">Cancel</MDButton>
                    <MDButton id="deletePinSubmit" onClick={submitEvent} variant="outlined" color="error">Delete Pin</MDButton>
                </DialogActions>
            </Dialog>

        </Box>
    )


}