/**
 =================================
 YourWebPro Contractor Portal Application V2
 NodeJS  |  React  |  MaterialUI  |  Material Dash 2
 2022 / 2023
 =================================
 ==========================================================
 Main components such as charts, boxes, sidenav, dash found in /components directory

 Layouts such at Dash, Profile, MapYourProjects, Billing are located in /layouts

 Theme files for colors and base layout definitions found in /assets/theme
 ==========================================================
 */

// @material-ui core components


// Material Dashboard 2 PRO React components

import colors from "assets/theme/base/colors"
import React, {useCallback, useState, useEffect, useMemo} from 'react'

import MDButton from "components/MDButton";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import 'react-dropzone-uploader/dist/styles.css'
import Dropzone from 'react-dropzone-uploader'


const ywpBlue = colors.ywpBlue.main;
const ywpWhite = colors.ywpWhite.main;


let image = fetch('/images/home-decor-1.png').then(r => r.blob());

function Photos({handleNext, handleBack, getUploadParams, handlePhotoChangeStatus, PhotosLayout, fileList}) {

    return (
        <Paper square elevation={0} sx={{p: 3}}>
            <Box sx={{height:"70px;"}}>
                <Typography>Upload Project Photos</Typography>
            </Box>
            {(fileList) ?
                fileList
                :
                <></>}
            <Dropzone
                getUploadParams={getUploadParams}
                onChangeStatus={handlePhotoChangeStatus}
                LayoutComponent={PhotosLayout}
                accept="image/*,video/*"
                inputContent={(files, extra) => (extra.reject ? 'Image and video files only accepted.' : '+ Drag or click to add files')}
                sx={{border: "1px solid #FF0000"}}
                styles={{
                    dropzoneReject: {borderColor: 'red', backgroundColor: '#DAA'},
                    inputLabel: (files, extra) => (extra.reject ? {color: 'red'} : {}),
                }}

            />


            <Box p={2} mt={2} sx={{
                borderTop: "1px solid #DDD",
                height: "60px"
            }}>
                <MDButton variant="outlined" color="info" size="medium" sx={{float: "left"}} onClick={handleBack}>
                    PREV
                </MDButton>
                <MDButton variant="outlined" color="info" size="medium" sx={{float: "right"}} onClick={handleNext}>
                    NEXT
                </MDButton>
            </Box>

        </Paper>
    );
}

export default Photos;
