import React, {useState} from "react";
import ReactDOM from "react-dom";
import {BrowserRouter} from "react-router-dom";
import App from "App";
import {GoogleOAuthProvider} from '@react-oauth/google';
import {MaterialUIControllerProvider} from "context";
import {SnackbarProvider, useSnackbar} from 'notistack';
import {apiUrl} from "App.js";
import Login from "layouts/login";
import CompleteSetup from "layouts/completeSetup";
import OTL from "layouts/otl";
import Cookies from 'universal-cookie';
import api from 'utils/axiosConfig';

const cookies = new Cookies();

// cookies.remove('session', {path: '/'});
// cookies.remove('count', {path: '/'});

const session = cookies.get('session');
const cs = cookies.get('count');
const sa = cookies.get('sa');

const timer = ms => new Promise(res => setTimeout(res, ms));

function ckSess(session, cs) {
    api.post(apiUrl() + "api/v2/cs/", {session: session, cs: cs}).then(response => {

        cookies.set('bp', response.data.bp, {path: '/'});
        cookies.set('ib', response.data.ib.ind, {path: '/'});
        cookies.set('ibc', response.data.ib.count, {path: '/'});
        cookies.set('nt', response.data.nt.ind, {path: '/'});
        cookies.set('ntc', response.data.nt.count, {path: '/'});
        if (response.data.status !== 'ok') {
            cookies.remove('session', {path: '/'});
            cookies.remove('count', {path: '/'});
            window.location.reload(false);
        }
    });
}

const queryParameters = new URLSearchParams(window.location.search)
const otl = queryParameters.get("otl")
// console.log("OTL is " + otl);


if (sa == 'true') {
    ReactDOM.render(
        <BrowserRouter>
            <GoogleOAuthProvider clientId="1049371351849-i5lspgegusmbosuio4khff0emdlt81ue.apps.googleusercontent.com">
                <MaterialUIControllerProvider>
                    <SnackbarProvider maxSnack={3} autoHideDuration={4000}>
                        <CompleteSetup />
                    </SnackbarProvider>
                </MaterialUIControllerProvider>
            </GoogleOAuthProvider>
        </BrowserRouter>,
        document.getElementById("root")
    );
} else if (session) {
    (async function () {
        while (true) {
            await timer(30000); // API every 30 Seconds
            ckSess(session, cs);
        }
    })()
    ReactDOM.render(
        <BrowserRouter>
            <GoogleOAuthProvider clientId="1049371351849-i5lspgegusmbosuio4khff0emdlt81ue.apps.googleusercontent.com">
                <MaterialUIControllerProvider>
                    <SnackbarProvider maxSnack={3} autoHideDuration={4000}>
                        <App/>
                    </SnackbarProvider>
                </MaterialUIControllerProvider>
            </GoogleOAuthProvider>
        </BrowserRouter>,
        document.getElementById("root")
    );
} else if (otl) {
    ReactDOM.render(
        <BrowserRouter>
            <MaterialUIControllerProvider>
                <SnackbarProvider maxSnack={3} autoHideDuration={4000}>
                    <OTL/>
                </SnackbarProvider>
            </MaterialUIControllerProvider>
        </BrowserRouter>,
        document.getElementById("root")
    );
} else {
    ReactDOM.render(
        <BrowserRouter>
            <MaterialUIControllerProvider>
                <SnackbarProvider maxSnack={3} autoHideDuration={4000}>
                    <Login/>
                </SnackbarProvider>
            </MaterialUIControllerProvider>
        </BrowserRouter>,
        document.getElementById("root")
    );
}