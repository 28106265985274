/**
 =================================
 YourWebPro Contractor Portal Application V2
 NodeJS  |  React  |  MaterialUI  |  Material Dash 2
 2022 / 2023
 =================================
 ==========================================================
 Main components such as charts, boxes, sidenav, dash found in /components directory

 Layouts such at Dash, Profile, MapYourProjects, Billing are located in /layouts

 Theme files for colors and base layout definitions found in /assets/theme
 ==========================================================
 */
import React, {useEffect, useState} from "react";
// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Select, {SelectChangeEvent} from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';


// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Billing page components
import Invoice from "layouts/billing/components/Invoice";
import Icon from "@mui/material/Icon";

function PastDue({handleClickOpenPayment, billing}) {

    const [age, setAge] = useState(0);

    const handleChange = (event: SelectChangeEvent) => {
        setAge(event.target.value);
    };

    return (
        <Card sx={{height: "100%"}}>
            <Grid
                container
                spacing={0}
                // direction="column"
                // alignItems="center"
                // justifyContent="center"

            >
                <Grid item lg={6} xs={12}>
                    <MDBox p={1} display="flex" alignItems="center">
                        <MDTypography variant="h1" fontWeight="medium" color="info">
                            {billing.due}<br/>
                            <MDTypography fontWeight="medium" fontSize="16px" color="error">
                                PAYMENT DUE<br />
                                <span style={{color:"#2887a1", fontSize:"12px", cursor:"pointer"}}>View past due invoices ({billing.back_inv})</span>
                            </MDTypography>
                        </MDTypography>


                    </MDBox>
                </Grid>
                <Grid item
                      lg={6}
                      xs={12}
                >
                    <MDBox p={1} display="flex" justifyContent="right" mt={8} mr={0}>
                        <MDButton variant="gradient" color="info" onClick={handleClickOpenPayment}>
                            <Icon sx={{fontWeight: "bold"}}>money</Icon>
                            &nbsp;Pay Bill
                        </MDButton>
                    </MDBox>
                </Grid>
            </Grid>
        </Card>


    );
}

export default PastDue;
