/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Billing page components
import Newsletter from "../Newsletter";
import React, {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import {apiUrl, chk} from "../../../../App";
import api from "../../../../utils/axiosConfig";

const styles = () =>
    createStyles({
        li: {
            '::firstChild': {
                backgroundColor: "red"
            }
        }
    });

function Newsletters() {
    const [loading, setLoading] = useState(true);
    const [newsLettersError, setNewsLettersError] = useState(true);
    const [newsLetters, setNewsLetters] = useState(true);


    useEffect(() => {
        // axios.defaults.withCredentials = true;
        const ck = chk();
        const session = ck.session;
        const cs = ck.cs;

        api.post(apiUrl() + "api/v2/Newsletters/", {cs: cs, session: session}).then(response => {
            if (response.data.status === 'ok') {
                setNewsLetters(response.data.newletters);
                setNewsLettersError(false);
            } else {
                setNewsLettersError(true);
            }
            setLoading(false);
        }).then(response => {

        });
    }, []);

    if (loading) {
        return (
            <Box sx={{height: "400px"}}>
                <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    style={{height: '400px'}}
                >

                    <Grid item xs={3}>
                        <CircularProgress color="info" size={120}/>
                    </Grid>

                </Grid>
            </Box>
        )
    } else {

        return (
            <Box>
                <Card sx={{height: "400px", overflowX: "hidden"}}>
                    {(newsLettersError) ?
                        <Box p={5}>
                            Ooops!<br/>
                            Error fetching Newsletters
                        </Box>
                        :
                        <MDBox p={2}>
                            <Box sx={{borderBottom: "1px solid #DDD"}}>
                                <Grid
                                    container
                                    spacing={2}
                                    direction="row"
                                >
                                    <Grid item lg={4}>
                                        Date
                                    </Grid>
                                    <Grid item lg={8} sx={{textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap"}}>
                                        Subject
                                    </Grid>
                                </Grid>
                            </Box>

                            <Box sx={{marginTop: "20px;"}}>

                                {newsLetters.map(item =>
                                    <Grid
                                        container
                                        spacing={2}
                                        direction="row"
                                        sx={{fontSize: "16px", marginTop: "5px;", borderBottom: "1px solid #DDD"}}
                                        key={item.link}
                                    >
                                        <Grid item lg={4}>
                                            {item.date}
                                        </Grid>
                                        <Grid item lg={8} sx={{textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap"}}>
                                            <a style={{color: "#2873C3", fontWeight: "bold"}} href={item.link} target={"_blank"}>{item.title}</a>
                                        </Grid>
                                    </Grid>
                                )}
                            </Box>


                        </MDBox>
                    }
                </Card>
                <Box sx={{height:"60px"}}>
                    <MDButton
                        href={"https://us17.campaign-archive.com/home/?u=3abde4b170f90b48f4cb3d480&id=dd0d256554"}
                        target={"_blank"}
                        variant="outlined"
                        color="info"
                        size="small"
                        style={{margin: "15px", float: "right"}}
                    >
                        view all
                    </MDButton>
                </Box>
            </Box>
        );
    }
}

export default Newsletters;
