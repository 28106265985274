// @mui material components
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import Footer from "components/Footer";
import Box from "@mui/material/Box";
import {useEffect} from "react";
import {apiUrl, chk} from "App";
import api from "utils/axiosConfig";
import {useState} from "react";
import PageLoading from "components/PageLoading";
import React from "react";

import Typography from "@mui/material/Typography";
import Report from "layouts/seo/components/report";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Grid from "@mui/material/Grid";
import MDButton from "components/MDButton";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import DatePicker from 'sassy-datepicker';
import './styles/seo.css';
import {useSnackbar} from "notistack";


import MDInput from "components/MDInput";
import CircularProgress from "@mui/material/CircularProgress";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


function TabPanel(props: TabPanelProps) {
    const {children, value, index, ...other} = props;


    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


function Dashboard() {
    const [isSeo, setIsSeo] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [report, setReport] = useState('');
    const [value, setValue] = React.useState(0);
    const [openBasicCalc, setOpenBasicCalc] = React.useState(false);
    const [openAdvancedCalc, setOpenAdvancedCalc] = React.useState(false);
    const [expenditure, setExpenditure] = React.useState(0);
    const [adWords, setAdWords] = React.useState(0);
    const [startDateOpen, setStartDateOpen] = React.useState();
    const [endDateOpen, setEndDateOpen] = React.useState(false);
    const [getDateLoader, setgetDateLoader] = React.useState(false);
    const [leads, setLeads] = React.useState(0);
    const [leadsClosed, setLeadsClosed] = React.useState(0);
    const [cpl, setCPL] = React.useState(Math.round(expenditure / leads));
    const [cpcl, setCPCL] = React.useState(Math.round(expenditure / leadsClosed));
    const [startDate, setStartDate] = React.useState('xxx');
    const [endDate, setEndDate] = React.useState('xxx');
    const {enqueueSnackbar} = useSnackbar();


    // ADVANCED SECTION
    const [advMarketingExp, setAdvMarketingExp] = React.useState(0);
    const [advMarketingLeads, setAdvMarketingLeads] = React.useState(0);
    const [advMarketingQual, setAdvMarketingQual] = React.useState(0);
    const [advMarketingClose, setAdvMarketingClose] = React.useState(0);
    const [advMarketingYWPExp, setAdvMarketingYWPExp] = React.useState(0);
    const [advMarketingYWPClose, setAdvMarketingYWPClose] = React.useState(0);
    const [advMarketingSales, setAdvMarketingSales] = React.useState(0);

    // Set By useEffect
    const [advCostPerLead, setAdvCostPerLead] = React.useState(0);
    const [advCostPerQualLead, setAdvCostPerQualLead] = React.useState(0);
    const [advCostPerClosedLead, setAdvCostPerClosedLead] = React.useState(0);
    const [advYwpCostPerClosedLead, setAdvYwpCostPerClosedLead] = React.useState(0);
    const [incomePerClosedLead, setIncomePerClosedLead] = React.useState(0);
    const [incomePerYWPLead, setIncomePerYwpLead] = React.useState(0);


    useEffect(() => {
        const advExp = advMarketingExp / advMarketingLeads;
        const advQual = advMarketingExp / advMarketingQual;
        const advClose = advMarketingExp / advMarketingClose;
        const advYwpCPL = advMarketingYWPExp / advMarketingYWPClose;
        const IPCL = advMarketingSales / advMarketingClose;
        const IPYL = (advMarketingYWPClose / advMarketingClose) * advMarketingSales;

        setAdvCostPerLead((typeof (advExp) === 'number'
            && advExp > 0
            && isFinite(advExp)) ? advExp.toFixed(2) : 0)
        setAdvCostPerQualLead((typeof (advQual) === 'number'
            && advQual > 0
            && isFinite(advQual)) ? advQual.toFixed(2) : 0)
        setAdvCostPerClosedLead((typeof (advClose) === 'number'
            && advClose > 0
            && isFinite(advClose)) ? advClose.toFixed(2) : 0)
        setAdvYwpCostPerClosedLead((typeof (advYwpCPL) === 'number'
            && advYwpCPL > 0
            && isFinite(advYwpCPL)) ? advYwpCPL.toFixed(2) : 0)
        setIncomePerClosedLead((typeof (IPCL) === 'number'
            && IPCL > 0
            && isFinite(IPCL)) ? IPCL.toFixed(2) : 0)
        setIncomePerYwpLead((typeof (IPYL) === 'number'
            && IPYL > 0
            && isFinite(IPYL)) ? IPYL.toFixed(2) : 0)

    }, [advMarketingExp,
        advMarketingLeads,
        advMarketingQual,
        advMarketingClose,
        advMarketingYWPExp,
        advMarketingYWPClose,
        advMarketingSales]);


    useEffect(() => {
        const CPL = (parseInt(expenditure) + parseInt(adWords)) / parseInt(leads);
        const CPCL = (parseInt(expenditure) + parseInt(adWords)) / parseInt(leadsClosed);
        setCPL((typeof (CPL) === 'number'
            && CPL > 0
            && isFinite(CPL)) ? Math.round(CPL * 100) / 100 : 0)
        setCPCL((typeof (CPCL) === 'number'
            && CPCL > 0
            && isFinite(CPCL)) ? Math.round(CPCL * 100) / 100 : 0)

    }, [expenditure,
        adWords,
        leads,
        leadsClosed]);


    const startDateChange = (date) => {
        setgetDateLoader(true);
        setStartDateOpen(false);
        let newDate = date.toString();

        newDate = newDate.replace("Mon ", "");
        newDate = newDate.replace("Tue ", "");
        newDate = newDate.replace("Wed ", "");
        newDate = newDate.replace("Thu ", "");
        newDate = newDate.replace("Fri ", "");
        newDate = newDate.replace("Sat ", "");
        newDate = newDate.replace("Sun ", "");
        newDate = newDate.split(' 00')[0]

        setStartDate(newDate);
        const ck = chk();
        const session = ck.session;
        const cs = ck.cs;
        api.post(apiUrl() + "api/v2/seo/getLeads/", {
            cs: cs,
            session: session,
            startDate: newDate,
            endDate: endDate,

        }).then(response => {
            if (response.data.status === "ok") {
                setLeads(response.data.leads);
                setLeadsClosed(response.data.leads);
                handleCalculation(response.data.leads, response.data.leads, expenditure);
            }
            if (response.data.status == 'error') {
                const message = response.data.message;
                const variant = "error";
                enqueueSnackbar(message, {variant});
            }
            setgetDateLoader(false);
        });

    };
    const endDateChange = (date) => {
        let newDate = date.toString();
        setEndDateOpen(false);
        setgetDateLoader(true);
        newDate = newDate.replace("Mon ", "");
        newDate = newDate.replace("Tue ", "");
        newDate = newDate.replace("Wed ", "");
        newDate = newDate.replace("Thu ", "");
        newDate = newDate.replace("Fri ", "");
        newDate = newDate.replace("Sat ", "");
        newDate = newDate.replace("Sun ", "");
        newDate = newDate.split(' 00')[0]
        setEndDate(newDate);
        const ck = chk();
        const session = ck.session;
        const cs = ck.cs;
        api.post(apiUrl() + "api/v2/seo/getLeads/", {
            cs: cs,
            session: session,
            startDate: startDate,
            endDate: newDate,
        }).then(response => {
            if (response.data.status === "ok") {
                setLeads(response.data.leads);
                setLeadsClosed(response.data.leads);
                handleCalculation(response.data.leads, response.data.leads, expenditure);
            }
            if (response.data.status == 'error') {
                const message = response.data.message;
                const variant = "error";
                enqueueSnackbar(message, {variant});
            }
            setgetDateLoader(false);
        });


    };

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };


    useEffect(() => {
        setLoading(true);
        const ck = chk();
        const session = ck.session;
        const cs = ck.cs;
        // axios.defaults.withCredentials = true;
        api.post(apiUrl() + "api/v2/seo/", {cs: cs, session: session}).then(response => {
            if (response.data.status === 'ok') {
                setIsSeo(response.data.seo);
                setReport(response.data.report);
                setExpenditure(response.data.expenditure);
                setAdWords(response.data.adwords_budget);
                setAdvMarketingYWPExp((parseInt(response.data.expenditure) + parseInt(response.data.adwords_budget)) * 100 / 100)
                setStartDate(response.data.start);
                setEndDate(response.data.end);
                setLeads(response.data.leads);
                setLeadsClosed(response.data.leads);
                setCPL(Math.round((response.data.expenditure + response.data.adwords_budget) / response.data.leads));
                setCPCL(Math.round((response.data.expenditure + response.data.adwords_budget) / response.data.leads));
            }
            setLoading(false);
        });
    }, []);

    if (isLoading) {
        return (
            <PageLoading/>
        )
    }

    function handleCloseBasic() {
        setOpenBasicCalc(false)
    }

    function handleCloseAdvanced() {
        setOpenAdvancedCalc(false)
    }

    function handleBasicMarketingCalc() {
        setOpenBasicCalc(true)
    }

    function handleAdvancedMarketingCalc() {
        setOpenAdvancedCalc(true)
    }

    function handleOpenStartDate() {
        setStartDateOpen(true);
    }

    function handleOpenEndDate() {
        setEndDateOpen(true);
    }


    return (
        <DashboardLayout>
            <DashboardNavbar/>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                <Tab label="Marketing Tools" {...a11yProps(0)} />
                <Tab label="SEO" {...a11yProps(1)} />
            </Tabs>
            <TabPanel value={value} index={0}>
                <Box sx={{minHeight: "400px;", backgroundImage: 'url("/images/marketing-bg.jpg")', margin: "50px"}}>

                    <Grid container spacing={3}>
                        <Grid p={2} item lg={3} sx={{minHeight: "400px;", backgroundColor: "#FFF"}}>
                            <MDButton sx={{width: "100%"}} color="info" size="small"
                                      onClick={handleBasicMarketingCalc}>
                                Marketing Calculator
                            </MDButton>
                            <MDButton sx={{marginTop: "15px", width: "100%"}} color="info" size="small"
                                      onClick={handleAdvancedMarketingCalc}>
                                Advanced Calculator
                            </MDButton>
                        </Grid>
                        <Grid item lg={9} sx={{minHeight: "400px;", backgroundColor: "#FFF", opacity: ".5"}}>

                        </Grid>
                    </Grid>
                </Box>
            </TabPanel>
            <TabPanel value={value} index={1}>
                {(isSeo) ?
                    <Box sx={{height: "80vh"}}>
                        {/*<IframeResizer*/}

                        {/*    src={report}*/}
                        {/*    style={{width: '100%', height: '100%'}}*/}
                        {/*/>*/}
                        <Report report={report}/>
                        <Footer />
                    </Box>
                    :
                    <Box>
                        There was an error retrieving your Marketing information.<br/>
                        You may not be signed up for our Marketing services. <br/><br/>
                        If you have questions about marketing, please <a href={"mailto:info@yourwebpro.com"}>Contact
                        Sales</a><br/>
                        If you are a marketing customer and are getting this message, please <a
                        href={"mailto:support@yourwebpro.com"}>Contact Support</a>
                    </Box>
                }
            </TabPanel>
            <Dialog
                open={openBasicCalc}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleCloseBasic}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{"Marketing Calculator"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        This calculator is to help you visualize what you're spending on your marketing efforts ... And
                        to help you maximize your
                        return on that investment.<br/>
                    </DialogContentText>
                </DialogContent>
                <DialogContent>
                    <Grid container spacing={3}>
                        <Grid item lg={8}>
                            <Typography sx={{fontSize: "14px", lineHeight: "45px"}}>
                                How much do you spend with YourWebPro?
                            </Typography>
                        </Grid>
                        <Grid item lg={4}>
                            <MDInput
                                autoFocus="autoFocus"
                                defaultValue={expenditure}
                                fullWidth
                                label="YWP Expenditure"
                                inputProps={{type: "text"}}
                                onKeyUp={(e) => setExpenditure(e.target.value)}
                            />
                        </Grid>
                        <Grid item lg={8}>
                            <Typography sx={{fontSize: "14px", lineHeight: "45px"}}>
                                How much do you spend on AdWords?
                            </Typography>
                        </Grid>
                        <Grid item lg={4}>
                            <MDInput
                                autoFocus="autoFocus"
                                defaultValue={adWords}
                                fullWidth
                                label="AdWords Expenditure"
                                inputProps={{type: "text"}}
                                onKeyUp={(e) => setAdWords(e.target.value)}
                            />
                        </Grid>
                        <Grid item lg={8}>
                            <Typography sx={{fontSize: "14px", lineHeight: "45px"}}>
                                Inbox leads from <span style={{
                                textDecoration: "underline",
                                color: "#124676",
                                cursor: "pointer",
                                fontWeight: "bold"
                            }}
                                                       onClick={handleOpenStartDate}
                            >{startDate}</span> to <span
                                style={{
                                    textDecoration: "underline",
                                    color: "#124676",
                                    cursor: "pointer",
                                    fontWeight: "bold"
                                }}
                                onClick={handleOpenEndDate}
                            >{endDate}</span> {(getDateLoader) ? <CircularProgress color="info" size={15}/> : <></>}
                            </Typography>
                            {(startDateOpen) ?
                                <Box sx={{
                                    position: "absolute",
                                    backgroundColor: "#FFF",
                                    border: "1px solid #124676",
                                    top: "140px;"
                                }}>
                                    <DatePicker onChange={startDateChange}/>
                                </Box>
                                : <></>
                            }
                            {(endDateOpen) ?
                                <Box sx={{
                                    position: "absolute",
                                    backgroundColor: "#FFF",
                                    border: "1px solid #124676",
                                    top: "140px;"
                                }}>
                                    <DatePicker onChange={endDateChange}/>
                                </Box>
                                : <></>
                            }

                        </Grid>
                        <Grid item lg={4}>
                            <MDInput
                                autoFocus="autoFocus"
                                defaultValue={leads}
                                key={leads}
                                fullWidth
                                label="Leads"
                                inputProps={{type: "text"}}
                                onKeyUp={(e) => setLeads(e.target.value)}
                            />
                        </Grid>
                        <Grid item lg={8}>
                            <Typography sx={{fontSize: "14px", lineHeight: "45px"}}>
                                How Many of those leads did you close?
                            </Typography>
                        </Grid>
                        <Grid item lg={4}>
                            <MDInput
                                autoFocus="autoFocus"
                                defaultValue={leadsClosed}
                                key={leadsClosed}
                                fullWidth
                                label="Leads Closed"
                                inputProps={{type: "text"}}
                                onKeyUp={(e) => setLeadsClosed(e.target.value)}

                            />
                        </Grid>
                        <Box sx={{width: "100%", textAlign: "center"}}>

                        </Box>
                    </Grid>
                    <hr style={{border: "5px solid #124676", marginTop: "10px"}}/>
                </DialogContent>
                <DialogContent>
                    <Grid container spacing={3}>
                        <Grid item lg={6}>
                            <Box sx={{textAlign: "center"}}>
                                <Typography>
                                    Your Cost Per Lead
                                </Typography>
                            </Box>
                            <Box sx={{textAlign: "center"}}>
                                <Typography sx={{fontSize: "36px", color: "#124676", fontWeight: "bold"}}>
                                    ${(typeof (cpl) === 'number' && cpl > 0 && isFinite(cpl)) ? cpl : 0}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item lg={6}>
                            <Box sx={{textAlign: "center"}}>
                                <Typography>
                                    Your Cost Per <b>Closed</b> Lead
                                </Typography>
                            </Box>
                            <Box sx={{textAlign: "center"}}>
                                <Typography sx={{fontSize: "36px", color: "#124676", fontWeight: "bold"}}>
                                    ${(typeof (cpcl) === 'number' && cpcl > 0 && isFinite(cpcl)) ? cpcl : 0}
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <MDButton onClick={handleCloseBasic} variant="outlined" color="info">Close</MDButton>
                </DialogActions>
            </Dialog>


            <Dialog
                open={openAdvancedCalc}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleCloseAdvanced}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{"Advanced Calculator"}</DialogTitle>
                <DialogContent>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                            This calculator is to help you visualize what you're spending on your Help you visualize what you're spending on all your marketing efforts combined... And
                            to help you maximize your return on that investment.<br/>
                        </DialogContentText>
                    </DialogContent>
                    <Grid container spacing={3}>
                        <Grid item lg={8}>
                            <Typography sx={{fontSize: "14px", lineHeight: "28px"}}>
                                What is your total marketing budget?
                            </Typography>
                            <p style={{fontSize: "11px"}}><i>Be sure to include what you pay for YourWebPro as well as any other marketing efforts you are currently using to generate leads</i></p>
                        </Grid>
                        <Grid item lg={4}>
                            <MDInput
                                autoFocus="autoFocus"
                                defaultValue={advMarketingExp}
                                fullWidth
                                label="Marketing Expenditure"
                                inputProps={{type: "text"}}
                                onKeyUp={(e) => setAdvMarketingExp(e.target.value)}
                            />
                        </Grid>
                        <Grid item lg={8}>
                            <Typography sx={{fontSize: "14px", lineHeight: "28px"}}>
                                How many total leads?
                            </Typography>
                            <p style={{fontSize: "11px"}}><i>Combine total leads from all marketing efforts</i></p>
                        </Grid>
                        <Grid item lg={4}>
                            <MDInput
                                autoFocus="autoFocus"
                                defaultValue={advMarketingLeads}
                                fullWidth
                                label="Total Leads"
                                inputProps={{type: "text"}}
                                onKeyUp={(e) => setAdvMarketingLeads(e.target.value)}
                                // onChange={handleCalculation}
                            />
                        </Grid>
                        <Grid item lg={8}>
                            <Typography sx={{fontSize: "14px", lineHeight: "28px"}}>
                                How many qualified leads?
                            </Typography>
                            <p style={{fontSize: "11px"}}><i>Of you total amount of leads, how many were "qualified"</i></p>
                        </Grid>
                        <Grid item lg={4}>
                            <MDInput
                                autoFocus="autoFocus"
                                defaultValue={advMarketingQual}
                                fullWidth
                                label="Qualified Leads"
                                inputProps={{type: "text"}}
                                onKeyUp={(e) => setAdvMarketingQual(e.target.value)}
                                // onChange={handleCalculation}
                            />
                        </Grid>
                        <Grid item lg={8}>
                            <Typography sx={{fontSize: "14px", lineHeight: "28px"}}>
                                How many leads did you close?
                            </Typography>
                            <p style={{fontSize: "11px"}}><i>Of your {advMarketingQual} qualified leads, how many led to a sale?</i></p>
                        </Grid>
                        <Grid item lg={4}>
                            <MDInput
                                autoFocus="autoFocus"
                                defaultValue={advMarketingClose}
                                fullWidth
                                label="Closes"
                                inputProps={{type: "text"}}
                                onKeyUp={(e) => setAdvMarketingClose(e.target.value)}
                                // onChange={handleCalculation}
                            />
                        </Grid>
                        <Grid item lg={8}>
                            <Typography sx={{fontSize: "14px", lineHeight: "28px"}}>
                                YourWebPro Monthly Budget?
                            </Typography>
                            <p style={{fontSize: "11px"}}><i>How much are you paying YourWebPro Monthly? (Including AdWords)</i></p>
                        </Grid>
                        <Grid item lg={4}>
                            <MDInput
                                autoFocus="autoFocus"
                                defaultValue={advMarketingYWPExp}
                                fullWidth
                                label="YWP Budget"
                                inputProps={{type: "text"}}
                                onKeyUp={(e) => setAdvMarketingYWPExp(e.target.value)}
                                // onChange={handleCalculation}
                            />
                        </Grid>
                        <Grid item lg={8}>
                            <Typography sx={{fontSize: "14px", lineHeight: "28px"}}>
                                YourWebPro Closes
                            </Typography>
                            <p style={{fontSize: "11px"}}><i>Of the {advMarketingClose} closed leads, how many were received from YourWebPro?</i></p>
                        </Grid>
                        <Grid item lg={4}>
                            <MDInput
                                autoFocus="autoFocus"
                                defaultValue={advMarketingYWPClose}
                                fullWidth
                                label="YWP Closes"
                                inputProps={{type: "text"}}
                                onKeyUp={(e) => setAdvMarketingYWPClose(e.target.value)}
                                // onChange={handleCalculation}
                            />
                        </Grid>
                        <Grid item lg={8}>
                            <Typography sx={{fontSize: "14px", lineHeight: "28px"}}>
                                Total Sales
                            </Typography>
                            <p style={{fontSize: "11px"}}><i>Of the {advMarketingClose} closed sales, what was the total income of combined sales?</i></p>
                        </Grid>
                        <Grid item lg={4}>
                            <MDInput
                                autoFocus="autoFocus"
                                defaultValue={advMarketingSales}
                                fullWidth
                                label="Combined Sales"
                                inputProps={{type: "text"}}
                                onKeyUp={(e) => setAdvMarketingSales(e.target.value)}
                                // onChange={handleCalculation}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <hr style={{border: "5px solid #124676", marginTop: "10px"}}/>
                <DialogContent>
                    <Grid container spacing={3}>
                        <Grid item lg={4}>
                            <Box sx={{textAlign: "center"}}>
                                <Typography sx={{fontSize: "18px"}}>
                                    Avg Cost <br/>Per Lead
                                </Typography>
                            </Box>
                            <Box sx={{textAlign: "center"}}>
                                <Typography sx={{fontSize: "32px", color: "#124676", fontWeight: "bold"}}>
                                    ${advCostPerLead}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item lg={4}>
                            <Box sx={{textAlign: "center"}}>
                                <Typography sx={{fontSize: "18px"}}>
                                    Cost Per <br/><b>Qualified</b> Lead
                                </Typography>
                            </Box>
                            <Box sx={{textAlign: "center"}}>
                                <Typography sx={{fontSize: "32px", color: "#124676", fontWeight: "bold"}}>
                                    ${advCostPerQualLead}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item lg={3}>
                            <Box sx={{textAlign: "center"}}>
                                <Typography sx={{fontSize: "18px"}}>
                                    Cost Per <br/><b>Closed</b> Lead
                                </Typography>
                            </Box>
                            <Box sx={{textAlign: "center"}}>
                                <Typography sx={{fontSize: "32px", color: "#124676", fontWeight: "bold"}}>
                                    ${advCostPerClosedLead}
                                </Typography>
                            </Box>
                        </Grid>

                        {/*Next Row */}

                        <Grid item lg={4}>
                            <Box sx={{textAlign: "center"}}>
                                <Typography sx={{fontSize: "18px"}}>
                                    YWP Cost <br/>Per <b>Closed</b> Lead
                                </Typography>
                            </Box>
                            <Box sx={{textAlign: "center"}}>
                                <Typography sx={{fontSize: "32px", color: "#124676", fontWeight: "bold"}}>
                                    ${advYwpCostPerClosedLead}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item lg={4}>
                            <Box sx={{textAlign: "center"}}>
                                <Typography sx={{fontSize: "18px"}}>
                                    Income Per <br/><b>Closed</b> Lead
                                </Typography>
                            </Box>
                            <Box sx={{textAlign: "center"}}>
                                <Typography sx={{fontSize: "32px", color: "#124676", fontWeight: "bold"}}>
                                    ${incomePerClosedLead}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item lg={3}>
                            <Box sx={{textAlign: "center"}}>
                                <Typography sx={{fontSize: "18px"}}>
                                    Income Per <br/><b>YWP</b> Lead
                                </Typography>
                            </Box>
                            <Box sx={{textAlign: "center"}}>
                                <Typography sx={{fontSize: "32px", color: "#124676", fontWeight: "bold"}}>
                                    ${incomePerYWPLead}
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>


                </DialogContent>

                <DialogActions>
                    <MDButton onClick={handleCloseAdvanced} variant="outlined" color="info">Close</MDButton>
                </DialogActions>
            </Dialog>


            {/*<Footer/>*/}
        </DashboardLayout>
    );
}

export default Dashboard;