import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

const Copyright = () => {
    return (
        <Typography variant="body2" color="#000" align="center">
            {'Copyright © '}
            <Link color="#000" href="https://yourwebpro.com/">
                YourWebPro
            </Link>{' '}
            {new Date().getFullYear()}
        </Typography>
    );
};

export default Copyright;