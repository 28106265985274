/**
 =================================
 YourWebPro Contractor Portal Application V2
 NodeJS  |  React  |  MaterialUI  |  Material Dash 2
 2022 / 2023
 =================================
 ==========================================================
 Main components such as charts, boxes, sidenav, dash found in /components directory

 Layouts such at Dash, Profile, MapYourProjects, Billing are located in /layouts

 Theme files for colors and base layout definitions found in /assets/theme
 ==========================================================
 */

import Grid from "@mui/material/Grid";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import Footer from "components/Footer";
import React, {useEffect, useState} from "react";
import {apiUrl, chk} from "App";
import api from "utils/axiosConfig";
import {useSnackbar} from "notistack";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import Manage from "layouts/gmb/components/Manage";
import Select from 'react-select';
import Link from "@mui/material/Link";

const Checkbox = ({children, ...props}: JSX.IntrinsicElements['input']) => (
    <label style={{marginRight: '1em'}}>
        <input type="checkbox" {...props} />
        {children}
    </label>
);
const options = [
    {value: 'chocolate', label: 'Chocolate'},
    {value: 'strawberry', label: 'Strawberry'},
    {value: 'vanilla', label: 'Vanilla'},
];
export default function GMB() {


    const [isClearable, setIsClearable] = useState(true);
    const [isSearchable, setIsSearchable] = useState(true);
    const [isDisabled, setIsDisabled] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isRtl, setIsRtl] = useState(false);


    const [selectedOption, setSelectedOption] = useState(null)

    const {enqueueSnackbar} = useSnackbar();
    const [gmbStatus, setGMBStatus] = useState('none');
    const [incompleteLoading, setIncompleteLoading] = useState(false);
    const [gmbGroups, setGmbGroups] = useState([]);
    const [gmbGroupsSelect, setGmbGroupsSelect] = useState([]);
    const [locationList, setLocationList] = useState([]);
    const [locationListSelect, setLocationListSelect] = useState([]);
    const [locationSet, setLocationSet] = useState(false);
    const [finished, setFinished] = useState(false);
    const [initialSaveLoading, setInitialSaveLoading] = useState(false);
    const [info, setInfo] = useState();
    const [pageLoading, setPageLoading] = useState(true);
    const [posts, setPosts] = useState();
    const [media, setMedia] = useState();


    useEffect(() => {
        // axios.defaults.withCredentials = true;
        const ck = chk();
        const session = ck.session;
        const cs = ck.cs;

        api.post(apiUrl() + "api/v2/gmb/get/", {cs: cs, session: session}).then(response => {
            if (response.data.status === 'ok') {
                setGMBStatus('ok');
                setInfo(response.data);


                api.post(apiUrl() + "api/v2/gmb/get/all/posts/", {cs: cs, session: session}).then(response => {
                    if (response.data.status === 'ok') {
                        setPosts(response.data);
                    }

                }).then(response => {
                    api.post(apiUrl() + "api/v2/gmb/get/all/media/", {cs: cs, session: session}).then(response => {
                        if (response.data.status === 'ok') {
                            setMedia(response.data);
                            setPageLoading(false);
                        }

                    })
                });

                return;
            }
            if (response.data.status === 'not_found') {
                setGMBStatus('not_found');
                const message = "No Login information available";
                const variant = "error";
                enqueueSnackbar(message, {variant});
                setPageLoading(false);
                return;
            }
            if (response.data.status === 'incomplete') {
                setGMBStatus('incomplete');
                setIncompleteLoading(true);
                setPageLoading(false);
                api.post(apiUrl() + "api/v2/gmb/get/all/", {cs: cs, session: session}).then(response => {
                    if (response.data.status === 'ok') {

                        let group_select = [];

                        for (let i = 0; i < response.data.business.length; i++) {

                            let temp_obj = {};
                            temp_obj.value = response.data.business[i].name;
                            temp_obj.label = response.data.business[i].AccountName;
                            group_select.push(temp_obj);
                        }

                        const options = [
                            {value: 'chocolate', label: 'Chocolate'},
                            {value: 'strawberry', label: 'Strawberry'},
                            {value: 'vanilla', label: 'Vanilla'},
                        ];

                        setGmbGroups(response.data.business);
                        setGmbGroupsSelect(group_select);
                        setIncompleteLoading(false);
                    } else {
                        setGMBStatus('error');
                        const message = "We're sorry we could not verify your Google Profile. 100";
                        const variant = "error";
                        enqueueSnackbar(message, {variant});
                        setIncompleteLoading(false);
                    }
                }).catch(error => {
                    setGMBStatus('error');
                    const message = "We're sorry we could not verify your Google Profile. 200";
                    const variant = "error";
                    enqueueSnackbar(message, {variant});
                    setIncompleteLoading(false);
                });
                return;
            }
            setGMBStatus('error');
            const message = "We're sorry we could not verify your Google Profile. 300";
            const variant = "error";
            enqueueSnackbar(message, {variant});
            setIncompleteLoading(false);

        }).then(response => {

        });
    }, []);


    const [account, setAccount] = React.useState('');
    const [location, setLocation] = React.useState('');
    const [profile, setProfile] = React.useState('');

    const handleAccountSelect = (event) => {
        setAccount(event.value);
        // setLocationList(gmbGroups[event.target.value].locations);
        let filterToLocations = gmbGroups.filter(item => item.name.includes(event.value));
        let location_select = [];
        for (let i = 0; i < filterToLocations[0].locations.length; i++) {

            let temp_obj = {};
            temp_obj.value = filterToLocations[0].locations[i].name;
            temp_obj.label = filterToLocations[0].locations[i].title;
            location_select.push(temp_obj);
        }
        setLocationList(filterToLocations[0].locations);
        setLocationListSelect(location_select);
        setLocationSet(true);

    };

    const handleLocationSelect = (event) => {
        setLocation(event.value);
        setFinished(true);
        let filterData = locationList.filter(item => item.name.includes(event.value));
        setProfile(filterData);
    };


    const saveInitialProfile = (event) => {
        setInitialSaveLoading(true);
        const ck = chk();
        const session = ck.session;
        const cs = ck.cs;
        api.post(apiUrl() + "api/v2/gmb/set/initial/", {cs: cs, session: session, account: account, location: location}).then(response => {
            if (response.data.status === 'ok') {
                setGMBStatus('ok');
                setInfo(response.data);
                setPosts(response.data);
                setMedia(response.data);

            }
        });

    };

    if (pageLoading || incompleteLoading) {
        return (
            <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
                style={{minHeight: '80vh'}}
            >
                <Grid item xs={3}>
                    <Box style={{textAlign: "center"}}>
                        <CircularProgress color="info" size={120}/><br/>
                        Please wait as we gather your Google information for you,<br/>
                        This may take a minute ...
                    </Box>
                </Grid>

            </Grid>
        )
    }


    return (
        <DashboardLayout>
            <DashboardNavbar/>

            {(gmbStatus === "ok") ?
                <Manage
                    info={info}
                    posts={posts}
                    media={media}
                />
                : <></>
            }
            {(gmbStatus === "incomplete") ?
                <MDBox>
                    <MDBox>

                        <Grid
                            container
                            direction="row"
                            // justifyContent="space-between"
                            // alignItems="center"
                            spacing={3}
                            style={{marginTop: "20px"}}
                        >

                            <Grid item sx={{
                                // width: {xs: 400, md: 250},
                                backgroundColor: "#FFF",
                                marginBottom: 4,
                                padding: 2,
                                marginLeft: 4,
                                borderRadius: "10px"
                            }}
                                  sm={4}
                            >


                                Google My Business Set Up<br/><br/>
                                <Box>
                                    <Typography>
                                        Select Account
                                    </Typography>
                                    <Select
                                        defaultValue={account}
                                        onChange={handleAccountSelect}
                                        options={gmbGroupsSelect}
                                        isClearable={true}
                                        isSearchable={true}
                                    />

                                </Box>
                                {(locationSet) ?
                                    <Box mt={5}>
                                        <Typography>
                                            Select Location
                                        </Typography>
                                        <Select
                                            defaultValue={location}
                                            onChange={handleLocationSelect}
                                            options={locationListSelect}
                                            isClearable={true}
                                            isSearchable={true}
                                        />
                                    </Box>
                                    : <></>
                                }
                                {(finished) ?
                                    <Grid container mt={5}>
                                        {(!initialSaveLoading) ?
                                            <Grid item>
                                                <MDButton onClick={saveInitialProfile} color="success" sx={{marginLeft: "10px"}}>Save Profile</MDButton>
                                            </Grid>
                                            :
                                            <Grid item pl={2}>
                                                <CircularProgress color="info" size={40}/>
                                            </Grid>
                                        }
                                    </Grid>


                                    : <></>
                                }
                            </Grid>
                            <Grid item sx={{
                                // width: {xs: 400, md: 250},
                                backgroundColor: "#FFF",
                                marginBottom: 4,
                                padding: 2,
                                marginLeft: 4,
                                borderRadius: "10px"
                            }}
                                  sm={6}
                            >
                                Profile:


                                {(profile) ?
                                    <Box>
                                        <b>Business:</b> {profile[0].title}<br/>
                                        <b>Phone:</b> {profile[0].phoneNumbers.primaryPhone}<br/>
                                        <b>Website:</b> {profile[0].websiteUri}<br/>
                                        <b>Address:</b> <br/>
                                        {(profile[0].storefrontAddress) ?
                                            <Box>
                                                {(profile[0].storefrontAddress.addressLines) ?
                                                    <span>{profile[0].storefrontAddress.addressLines[0]}<br/></span>
                                                    : <></>
                                                }
                                                {profile[0].storefrontAddress.locality}<br/>
                                                {profile[0].storefrontAddress.administrativeArea}<br/>
                                                {profile[0].storefrontAddress.postalCode}<br/>
                                            </Box> : <></>}

                                        {(profile[0].profile) ?
                                            <Box>
                                                <b>Profile:</b> {profile[0].profile.description}<br/>
                                            </Box> : <></>}

                                        {/*<pre>*/}
                                        {/*{JSON.stringify(profile, null, 2)}*/}
                                        {/*</pre>*/}
                                    </Box>
                                    : <></>


                                }
                            </Grid>
                        </Grid>
                    </MDBox>


                </MDBox>
                : <></>
            }
            {(gmbStatus === "error") ?
                <div>
                    ERROR
                </div>
                : <></>
            }
            {(gmbStatus === "not_found") ?
                <div>
                    It appears you are not logged into Google.<br/>Please visit the Dashboard and log in from there.
                </div>
                : <></>
            }
            <Box sx={{height: "200px"}}>
            </Box>
            <Footer/>
        </DashboardLayout>

    )

}