import React, {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import api from 'utils/axiosConfig';
import {apiUrl, chk} from "App.js";
import Typography from "@mui/material/Typography";
import Icon from "@mui/material/Icon";

const PerformanceScores = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [performance, setPerformance] = useState([]);
    const [startColor, setStartColor] = useState([]);
    const [stopColor, setStopColor] = useState([]);
    const [speedIndex, setSpeedIndex] = useState([]);
    const [pageSize, setPageSize] = useState([]);
    const [avgScore, setAvgScore] = useState([]);
    const [grade, setGrade] = useState([]);
    const [screenshot, setScreenshot] = useState([]);
    const [desktopReport, setDesktopReport] = useState([]);
    const [mobileReport, setMobileReport] = useState([]);


    useEffect(() => {
        setIsLoading(true);
        const ck = chk();
        const session = ck.session;
        const cs = ck.cs;

        api.post(apiUrl() + "api/v2/analytics/Performance/", {cs: cs, session: session}).then(response => {
            console.log(response.data)
            setPerformance(response.data);
            setStartColor(response.data.performance_totals.stop_0);
            setStopColor(response.data.performance_totals.stop_1);
            setPageSize(response.data.page_size);
            setSpeedIndex(response.data.speed_index);
            setAvgScore(response.data.performance_totals.total);
            setGrade(response.data.performance_totals.grade);
            setScreenshot(response.data.screenshot);
            setDesktopReport(response.data.desktop_report)
            setMobileReport(response.data.mobile_report)

            // setScreenshot(response.data.raw.audits["final-screenshot"].details.data);
        }).then(response => {
            setIsLoading(false);
        });

    }, []);
    return (
        <Box mt={4} p={2} sx={{flexGrow: 1}}>
            <Box>Performance Score</Box>
            {(isLoading) ?
                <Box sx={{textAlign: "center"}}>
                    <CircularProgress color="info" size={100}/>
                </Box>
                :
                <Grid
                    container
                    direction="row"
                    style={{minHeight: '50px'}}
                >
                    <Grid item sm={12} lg={6} sx={{
                        textAlign: "center",
                        border: "15px solid",
                        borderImage: "linear-gradient(" + stopColor + ", " + startColor + ") 30",
                    }}>
                        <Box sx={{
                            textAlign: "center",
                            border: "4px solid #DDD",
                        }}>

                            <Typography
                                variant="h1"
                                align="left"
                                color="grey.700"
                                sx={{
                                    fontSize: "70px",
                                    fontWeight: "700",
                                    backgroundcolor: "primary",
                                    backgroundImage: `linear-gradient(90deg, ${stopColor}, ${startColor})`,
                                    backgroundSize: "100%",
                                    backgroundRepeat: "repeat",
                                    backgroundClip: "text",
                                    WebkitBackgroundClip: "text",
                                    WebkitTextFillColor: "transparent",
                                    textAlign:"center"
                                }}
                            >
                                {grade} ({avgScore}%)
                            </Typography>


                        </Box>
                        <Box sx={{
                            fontSize: "24px",
                            fontWeight: "700",
                            color: "#4fb1ff",
                        }}>
                            Page Details
                        </Box>
                        <Grid
                            container
                            spacing={3}
                            direction="row"
                            style={{minHeight: '50px'}}

                        >
                            <Grid item xs={12} lg={6}>
                                <Box sx={{
                                    fontSize: "26px",
                                    fontWeight: "700",
                                    color: startColor,
                                    border: "4px solid #DDD"
                                }}>
                                    <Typography variant="h4"
                                                sx={{
                                                    color: "#939393",
                                                }}
                                    >
                                        Total Page Size
                                    </Typography>
                                    {pageSize}
                                </Box>
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <Box sx={{
                                    fontSize: "26px",
                                    fontWeight: "700",
                                    color: startColor,
                                    border: "4px solid #DDD"
                                }}>
                                    <Typography variant="h4"
                                                sx={{
                                                    color: "#939393",
                                                }}
                                    >
                                        Total Load Time
                                    </Typography>
                                    {speedIndex}
                                </Box>
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <Box sx={{
                                    fontSize: "26px",
                                    fontWeight: "700",
                                    color: startColor,
                                    cursor: "pointer",
                                }}>
                                    <Typography variant="h4"
                                                sx={{
                                                    color: "#4fb1ff",
                                                }}
                                    >
                                        <a href={desktopReport} target={"_blank"}><Icon fontSize={"large"} sx={{marginRight: "10px", cursor: "pointer", color: "#4fb1ff",}} title="Desktop Report">laptop</Icon></a>
                                        <br/>
                                        <a href={desktopReport} target={"_blank"}><span style={{fontSize:"14px", color: "#4fb1ff",}}>Desktop Report</span></a>

                                    </Typography>

                                </Box>
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <Box sx={{
                                    fontSize: "26px",
                                    fontWeight: "700",
                                    color: startColor,
                                    cursor: "pointer",
                                }}>
                                    <Typography variant="h4"
                                                sx={{
                                                    color: "#4fb1ff",
                                                }}
                                    >
                                        <a href={mobileReport} target={"_blank"}><Icon fontSize={"large"} sx={{marginRight: "10px", color: "#4fb1ff",}} title="Mobile Report">phone_android</Icon></a>
                                        <br/>
                                        <a href={mobileReport} target={"_blank"}><span style={{fontSize:"14px", color: "#4fb1ff"}}>Mobile Report</span></a>
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item sm={12} lg={6} p={3} sx={{textAlign: "center"}}>
                        <img src={screenshot} style={{width: "100%"}}/>
                    </Grid>
                </Grid>
            }
            <pre>
                {/*{JSON.stringify(desktop, null, 2)}*/}
            </pre>
        </Box>
    );
};

export default PerformanceScores;
