

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

function Newsletter({ date, noGutter }) {
  return (
    <MDBox
      component="li"
      display="flex"
      justifyContent="space-between"
      alignItems="flex-start"
      py={1}
      pr={1}
      mb={noGutter ? 0 : 1}
    >
      <MDBox lineHeight={1.125}>
        <MDTypography display="block" variant="button" fontWeight="medium" textAlign="left">
          {date}
        </MDTypography>
      </MDBox>
      <MDBox display="flex" alignItems="center">
       
        <MDBox display="flex" alignItems="center" lineHeight={1} ml={3} sx={{ cursor: "pointer" }}>
          <Icon color={"#2873C3"} fontSize="small">browser_updated</Icon>
        </MDBox>
      </MDBox>
    </MDBox>
  );
}

// Setting default values for the props of Newsletter
Newsletter.defaultProps = {
  noGutter: false,
};

// Typechecking props for the Newsletter
Newsletter.propTypes = {
  date: PropTypes.string,
  id: PropTypes.string,
  price: PropTypes.string,
  noGutter: PropTypes.bool,
};

export default Newsletter;
