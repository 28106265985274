import Grid from "@mui/material/Grid";
import React, {useEffect, useState} from "react";

import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import Footer from "components/Footer";
import Paper from "@mui/material/Paper";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import NewLeads from "../dashboard/components/NewLeads";
import NewReviews from "../dashboard/components/NewReviews";
import Google from "../dashboard/components/Google";
import Facebook from "../dashboard/components/Facebook";
import Box from "@mui/material/Box";
import Visitors from "../dashboard/components/Visitors";
import WebsiteMetrics from "../dashboard/components/WebsiteMetrics";
import colors from "assets/theme/base/colors";
import Invoices from "../dashboard/components/Invoices";
import {Typography} from "@mui/material";
import Newsletters from "../dashboard/components/Newsletters";
import Banner from "../dashboard/components/Banner";
import {apiUrl, chk} from "App";
import api from "utils/axiosConfig";
import Invoice from "../billing/components/Invoice";
import PageLoading from "components/PageLoading";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import MDButton from "../../components/MDButton";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import {useSnackbar} from "notistack";
const ywpBlue = colors.ywpBlue.main;

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});




function Dashboard() {

    const [isLoading, setLoading] = useState(true);
    const [visitsMonthLoading, setVisitsMonthLoading] = useState(true);
    const [visitsTodayLoading, setVisitsTodayLoading] = useState(true);
    const [visitsWeekLoading, setVisitsWeekLoading] = useState(true);
    const {enqueueSnackbar} = useSnackbar();

    const [isError, setError] = useState(false);
    const [newLeads, setNewLeads] = useState(0);
    const [newReviews, setNewReviews] = useState(0);

    const [updateFirstName, setUpdateFirstName] = useState(false);
    const [updateLastName, setUpdateLastName] = useState(false);
    const [updateAddress, setUpdateAddress] = useState(false);
    const [updateAddressCSZ, setUdateAddressCSZ] = useState(false);
    const [updateEmail, setUpdateEmail] = useState(false);
    const [updatePhone, setUdatePhone] = useState(false);
    const [updateMobile, setUdateMobile] = useState(false);
    const [openUpdate, setOpenUpdate] = useState(false);
    const [updateLoading, setUpdateLoading] = useState(false);



    useEffect(() => {
        // axios.defaults.withCredentials = true;
        const ck = chk();
        const session = ck.session;
        const cs = ck.cs;

        api.post(apiUrl() + "api/v2/dash/", {cs: cs, session: session}).then(response => {
            if (response.data.status === 'ok') {

                setNewLeads(response.data.new_leads);
                setNewReviews(response.data.new_reviews);



                if(response.data.contact_info.update == 0){

                    setUpdateFirstName(response.data.contact_info.first_name);
                    setUpdateLastName(response.data.contact_info.last_name);
                    setUpdateAddress(response.data.contact_info.address);
                    setUdateAddressCSZ(response.data.contact_info.address_csz);
                    setUpdateEmail(response.data.contact_info.email);
                    setUdatePhone(response.data.contact_info.phone);
                    setUdateMobile(response.data.contact_info.mobile);
                    const adm = ck.adm;
                    if (!adm){
                        setOpenUpdate(true);
                    }
                }
                setLoading(false);
            } else {
                setLoading(false);
                setError(true);
            }

        }).then(response => {

        });
    }, []);


    function handleCloseUpdateOP() {
        setUpdateLoading(true);

        const ck = chk();
        const session = ck.session;
        const cs = ck.cs;

        api.post(apiUrl() + "api/v2/profile/update/current/", {cs: cs, session: session}).then(response => {
            if (response.data.status === 'ok') {
                const message = "Your response has been recorded.  Your information is up to date.";
                const variant = "success";
                enqueueSnackbar(message, {variant});

            } else {
                const message = "There was a problem recording your response.  If this persists, please contact support.";
                const variant = "error";
                enqueueSnackbar(message, {variant});
            }

        }).then(response => {
            setUpdateLoading(false);
            setOpenUpdate(false);
        });

    }


    if (isLoading) {
        return (
            <PageLoading/>
        )
    } else if (isError) {
        return (
            <DashboardLayout>
                <DashboardNavbar/>
                <Grid
                    container
                    direction="row"
                    spacing={3}

                >
                    {/*Main Grid*/}
                    <Grid item xs={12} lg={8}>
                        There was an error retrieving your dashboard information.<br/> Please retry. If this problems persists, please <a target={"_blank"} href={""}>Contact Support</a>
                    </Grid>
                </Grid>
            </DashboardLayout>
        )
    } else {
        return (
            <DashboardLayout>
                <DashboardNavbar/>
                <Grid
                    container
                    direction="row"
                    spacing={3}

                >
                    {/*Main Grid*/}
                    <Grid item xs={12} lg={8}>
                        <Grid
                            container
                            direction="row"
                            spacing={3}
                        >
                            <Grid className="NewLeads" item xs={12} md={12} lg={6}>

                                <Paper
                                    style={{
                                        height: "30px",
                                        width: "30px",
                                        backgroundColor: "red",
                                        color: "#fff",
                                        padding: "5px",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        margin: "0 0 -15px 15px",
                                        position: "relative",
                                        visibility: (newLeads > 0) ? "visible" : "hidden",
                                    }}
                                >
                                    {(newLeads > 0) ? <ErrorOutlineIcon/> : <></>}
                                </Paper>

                                <NewLeads newLeads={newLeads}/>
                            </Grid>
                            <Grid className="NewReviews" item xs={12} md={12} lg={6}>
                                <Paper
                                    style={{
                                        height: "30px",
                                        width: "30px",
                                        backgroundColor: "red",
                                        color: "#fff",
                                        padding: "5px",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        margin: "0 0 -15px 15px",
                                        position: "relative",
                                        visibility: (newReviews > 0) ? "visible" : "hidden",
                                    }}
                                >
                                    {(newReviews > 0) ? <ErrorOutlineIcon/> : <></>}
                                </Paper>
                                <NewReviews newReviews={newReviews}/>
                            </Grid>
                            <Grid item lg={8}>
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            direction="row"
                            spacing={3}
                        >
                            <Grid className="google" item xs={12} md={12} lg={12} mt={1}>
                                <Google/>
                            </Grid>
                            {/*<Grid className="facebook" item xs={12} md={12} lg={6} mt={1}>*/}

                            {/*    <Facebook/>*/}

                            {/*</Grid>*/}
                        </Grid>

                        <Paper
                            style={{
                                backgroundColor: ywpBlue,
                            }}
                        >
                            <Visitors
                                setVisitsMonthLoading={setVisitsMonthLoading}
                                visitsMonthLoading={visitsMonthLoading}
                                setVisitsTodayLoading={setVisitsTodayLoading}
                                visitsTodayLoading={visitsTodayLoading}
                                setVisitsWeekLoading={setVisitsWeekLoading}
                                visitsWeekLoading={visitsWeekLoading}
                            />
                        </Paper>

                        <Paper
                            style={{
                                backgroundColor: ywpBlue,
                            }}
                        >
                            <WebsiteMetrics/>
                        </Paper>

                    </Grid>
                    {/*End Main Grid*/}


                    {/*Side Grid*/}
                    <Grid item xs={12} lg={4}>
                        <Paper style={{
                            padding: "5px",
                        }}>

                            <Banner/>

                        </Paper>
                        <br/>
                        <Paper style={{
                            padding: "5px",
                        }}>
                            <Typography style={{textAlign: "left", padding: "10px"}}>Newsletters</Typography>
                            <Newsletters/>

                        </Paper>
                    </Grid>
                    {/*End Sid Grid*/}
                </Grid>
                <Footer/>
                <Dialog
                    open={openUpdate}
                    TransitionComponent={Transition}
                    keepMounted
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle>{"Keep Your Information Up To Date!"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                            This is used to keep your Google Information and SSL Certificate current.  Please ensure the following is correct.  If not, click "Update Info".<br />
                        <Box style={{marginLeft:"50px", marginTop:"20px"}}>
                            Main Contact: {updateFirstName} {updateLastName}<br />
                            Address: {updateAddress}<br />
                            {updateAddressCSZ}<br />
                            Email: {updateEmail}<br />
                            Phone: {updatePhone}<br />
                            Mobile: {updateMobile}<br />
                            <hr />
                            <span style={{color:"#FF0000", fontSize:"12px"}}>Please ensure your mobile number is up to date.<br /> You will receive important updates about your account and certificate status.</span>
                        </Box>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <MDButton onClick={handleCloseUpdateOP} variant="outlined" color="info">My Information Is Correct</MDButton>
                        <MDButton id="SendToSomewhere" href={"/profile"} variant="outlined" color="info" >Update Info</MDButton>
                    </DialogActions>
                </Dialog>
            </DashboardLayout>
        )
            ;
    }
}

export default Dashboard;
