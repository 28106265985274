import React, {useEffect, useState, useRef} from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';


// Material Dashboard 2 React
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import Footer from "components/Footer";

import SwipeToDelete from 'react-swipe-to-delete-component';
import 'react-swipe-to-delete-component/dist/swipe-to-delete.css';
import './styles/inbox.css';
import {useSnackbar} from "notistack";
import {apiUrl, chk} from "App";
import api from "utils/axiosConfig";
import PageLoading from "components/PageLoading";
import isMobile from "react-swipe-to-delete-component/src/js/utils/isMobile";
import {Icon} from "@mui/material";
import Menu from "@mui/material/Menu";
import NotificationItem from "components/Items/NotificationItem";
import CircularProgress from "@mui/material/CircularProgress";
import MDButton from "components/MDButton";


interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const {children, value, index, ...other} = props;


    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


function Inbox() {

    const [isDeviceMobile, setIsMobile] = useState(false);

    function handleWindowSizeChange() {
        if (window.innerWidth <= 768) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
    }

    useEffect(() => {
        handleWindowSizeChange();
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);


    const [inboxData, setInboxData] = useState(true);
    const [pinsData, setPinnedData] = useState(true);
    const [trashData, setTrashData] = useState(true);
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {

        const ck = chk();
        const session = ck.session;
        const cs = ck.cs;

        api.post(apiUrl() + "api/v2/inbox/", {cs: cs, session: session}).then(response => {
            if (response.data.status === 'ok') {
                setInboxData(response.data.inbox);
                setPinnedData(response.data.pinned);
                setTrashData(response.data.trash);
                setLoading(false);
            } else {
                setLoading(false);
            }

        }).then(response => {

        });
    }, []);


    // const [pinsData, setPinsData] = useState(true);
    // const [trashData, setTrashData] = useState(true);

    //
    // const inboxData = [
    //     {id: 1, subject: 'THIS', text: 'Inbox Item 1 -- Subject -- Short description', date: '1.03.2016'},
    //     {id: 2, subject: '', text: 'Inbox Item 2 -- Subject -- Short description', date: '23.01.2017'},
    //     {id: 3, subject: '', text: 'Inbox Item 3 -- Subject -- Short description', date: '23.01.2017'},
    //     {id: 4, subject: '', text: 'Inbox Item 4 -- Subject -- Short description', date: '23.01.2017'},
    //     {id: 5, subject: '', text: 'Inbox Item 5 -- Subject -- Short description', date: '23.01.2017'},
    //     {id: 6, subject: '', text: 'Inbox Item 6 -- Subject -- Short description', date: '23.01.2017'},
    //     {id: 7, subject: '', text: 'Inbox Item 7 -- Subject -- Short description', date: '23.01.2017'},
    //     {id: 8, subject: '', text: 'Inbox Item 8 -- Subject -- Short description', date: '23.01.2017'},
    //     {id: 9, subject: '', text: 'Inbox Item 9 -- Subject -- Short description', date: '23.01.2017'},
    //     {id: 10, subject: '', text: 'Inbox Item 10 -- Subject -- Short description', date: '23.01.2017'},
    //     {id: 11, subject: '', text: 'Inbox Item 11 -- Subject -- Short description', date: '23.01.2017'},
    // ];

    // const pinsData = [
    //     {id: 1, subject: '', text: 'Pinned Item 1 -- Subject -- Short description', date: '1.03.2016'},
    //     {id: 2, subject: '', text: 'Pinned Item 2 -- Subject -- Short description', date: '23.01.2017'},
    //     {id: 3, subject: '', text: 'Pinned Item 3 -- Subject -- Short description', date: '23.01.2017'},
    // ];
    //
    // const trashData = [
    //     {id: 1, subject: '', text: 'Trash Item 1 -- Subject -- Short description', date: '1.03.2016'},
    //     {id: 2, subject: '', text: 'Trash Item 2 -- Subject -- Short description', date: '23.01.2017'},
    //     {id: 3, subject: '', text: 'Trash Item 4 -- Subject -- Short description', date: '23.01.2017'},
    //     {id: 4, subject: '', text: 'Trash Item 5 -- Subject -- Short description', date: '23.01.2017'},
    //     {id: 5, subject: '', text: 'Trash Item 6 -- Subject -- Short description', date: '23.01.2017'},
    //     {id: 6, subject: '', text: 'Trash Item 7 -- Subject -- Short description', date: '23.01.2017'},
    // ];


    const {enqueueSnackbar} = useSnackbar();
    const inboxItem = useRef(null);

    function getMessage(id) {

        toggleDrawer(true);
        console.log("Message ID: " + id);
    }

    const [isRight, setRight] = useState(false);
    const [isLeft, setLeft] = useState(false);


    const onLeft = (...args) => {
        setLeft(true);
        setRight(false);
    }
    const onRight = (...args) => {
        setRight(true);
        setLeft(false);
    }
    const unPin = (...args) => {
        const message = "Message Unpinned";
        const variant = "info";
        enqueueSnackbar(message, {variant});
        console.log("Message Unpinned")
    }
    const unDelete = (customProps) => {
        const message = "Message Restored to Inbox";
        const variant = "info";
        enqueueSnackbar(message, {variant});
        console.log("Message Restored")
    }

    const onDelete = (customProps) => {
        // Axios delete item (args)
        if (isLeft) {

            const message = "Message Deleted";
            const variant = "error";
            enqueueSnackbar(message, {variant});
            console.log("Message Deleted")
        }
        if (isRight) {

            const message = 'Message Pinned';
            const variant = 'success';
            enqueueSnackbar(message, {variant});
            console.log('Message Pinned');
        }
    }
    const onCancel = (customProps) => {
        // Not needed now, but leaving just in case ...
        // console.info('onCancel', customProps);
    }

    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };


    const [open, setOpen] = React.useState(false);
    const [messageLoading, setMessageLoading] = React.useState(true);
    const [message, setMessage] = React.useState(<></>);
    const [messageId, setMessageId] = React.useState(<></>);
    const [toEmail, setToEmail] = React.useState('');
    const [fromEmail, setFromEmail] = React.useState('');
    const [website, setWebsite] = React.useState('');
    const [remoteAddress, setRemoteAddress] = React.useState('');
    const [mailSubject, setMailSubject] = React.useState('');
    const [unpinLoading, setUnpinLoading] = React.useState(false);
    const [untrashLoading, setUntrashLoading] = React.useState(false);
    const [indexLoading, setIndexLoading] = React.useState(false);
    const [unpinAllLoading, setUnpinAllLoading] = React.useState(false);
    const [deleteAllLoading, setDeleteAllLoading] = React.useState(false);

    const toggleDrawer = (toggle, id) => () => {
        setMessageLoading(true);
        setMessage("");
        const ck = chk();
        const session = ck.session;
        const cs = ck.cs;
        if (id) {
            api.post(apiUrl() + "api/v2/inbox/get_message/", {cs: cs, session: session, id: id}).then(response => {
                if (response.data.status === "ok") {
                    setMessage(response.data.message.body);
                    setMessageId(id);
                    setToEmail(response.data.message.to_email);
                    setFromEmail(response.data.message.from_email);
                    setWebsite(response.data.message.http_host);
                    setRemoteAddress(response.data.message.remote_address);
                    setMailSubject(response.data.message.subject);
                    setInboxData(response.data.inbox);
                    setPinnedData(response.data.pinned);
                    setTrashData(response.data.trash);
                } else {
                    setMessage(<Grid
                        container
                        spacing={0}
                        direction="column"
                        alignItems="center"
                        justifyContent="center"
                        style={{minHeight: '80vh'}}
                    >

                        <Grid item xs={3}>
                            <Icon>report_problem</Icon> Could not retrieve message.
                        </Grid>

                    </Grid>)
                }

            }).then(response => {
                setMessageLoading(false);
            });
        }
        // inboxItem.current.onblur;
        setOpen(toggle);
    };


    const [openMenu, setOpenMenu] = useState(false);
    const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
    const handleCloseMenu = () => setOpenMenu(false);

    function handleDelete() {
        setMessageLoading(true);
        const ck = chk();
        const session = ck.session;
        const cs = ck.cs;
        api.post(apiUrl() + "api/v2/inbox/delete/", {cs: cs, session: session, id: messageId}).then(response => {
            if (response.data.status === "ok") {
                // Set new items
                setInboxData(response.data.inbox);
                setPinnedData(response.data.pinned);
                setTrashData(response.data.trash);
                setLoading(false);
                const message = "Message successfully sent to trash";
                const variant = "info";
                enqueueSnackbar(message, {variant});
            } else {
                const message = "An unknown error has occurred";
                const variant = "error";
                enqueueSnackbar(message, {variant});
            }

        }).then(response => {
            setMessageLoading(false);
            setOpen(false);
            handleCloseMenu();
        });

    }
    function handleDeleteInterrupt(id) {
        setIndexLoading(true);
        setMessageId(id);
        const ck = chk();
        const session = ck.session;
        const cs = ck.cs;
        api.post(apiUrl() + "api/v2/inbox/delete/", {cs: cs, session: session, id: id}).then(response => {
            if (response.data.status === "ok") {
                // Set new items
                setInboxData(response.data.inbox);
                setPinnedData(response.data.pinned);
                setTrashData(response.data.trash);
                setLoading(false);
                const message = "Message successfully sent to trash";
                const variant = "info";
                enqueueSnackbar(message, {variant});
            } else {
                const message = "An unknown error has occurred";
                const variant = "error";
                enqueueSnackbar(message, {variant});
            }

        }).then(response => {
            setIndexLoading(false);
        });

    }

    function handlePinInterupt(id) {
        setIndexLoading(true);
        setMessageId(id);
        const ck = chk();
        const session = ck.session;
        const cs = ck.cs;
        api.post(apiUrl() + "api/v2/inbox/pin/", {cs: cs, session: session, id: id}).then(response => {
            if (response.data.status === "ok") {
                // Set new items
                setInboxData(response.data.inbox);
                setPinnedData(response.data.pinned);
                setTrashData(response.data.trash);
                setLoading(false);
                const message = "Message successfully pinned";
                const variant = "info";
                enqueueSnackbar(message, {variant});
            } else {
                const message = "An unknown error has occurred";
                const variant = "error";
                enqueueSnackbar(message, {variant});
            }

        }).then(response => {
            setIndexLoading(false);
        });
    }


    function handlePin() {
        setMessageLoading(true);
        const ck = chk();
        const session = ck.session;
        const cs = ck.cs;
        api.post(apiUrl() + "api/v2/inbox/pin/", {cs: cs, session: session, id: messageId}).then(response => {
            if (response.data.status === "ok") {
                // Set new items
                setInboxData(response.data.inbox);
                setPinnedData(response.data.pinned);
                setTrashData(response.data.trash);
                setLoading(false);
                const message = "Message successfully pinned";
                const variant = "info";
                enqueueSnackbar(message, {variant});
            } else {
                const message = "An unknown error has occurred";
                const variant = "error";
                enqueueSnackbar(message, {variant});
            }

        }).then(response => {
            setMessageLoading(false);
            setOpen(false);
            handleCloseMenu();
        });

    }

    function unPinItem(id) {
        setMessageId(id);
        setUnpinLoading(true);

        const ck = chk();
        const session = ck.session;
        const cs = ck.cs;
        api.post(apiUrl() + "api/v2/inbox/unpin/", {cs: cs, session: session, id: id}).then(response => {
            if (response.data.status === "ok") {
                // Set new items
                setInboxData(response.data.inbox);
                setPinnedData(response.data.pinned);
                setTrashData(response.data.trash);
                setLoading(false);
                const message = "Message unpinned";
                const variant = "info";
                enqueueSnackbar(message, {variant});
            } else {
                const message = "An unknown error has occurred";
                const variant = "error";
                enqueueSnackbar(message, {variant});
            }

        }).then(response => {
            setUnpinLoading(false);
        });


    }

    function unTrashItem(id) {
        setMessageId(id);
        setUntrashLoading(true);

        const ck = chk();
        const session = ck.session;
        const cs = ck.cs;
        api.post(apiUrl() + "api/v2/inbox/untrash/", {cs: cs, session: session, id: id}).then(response => {
            if (response.data.status === "ok") {
                // Set new items
                setInboxData(response.data.inbox);
                setPinnedData(response.data.pinned);
                setTrashData(response.data.trash);
                setLoading(false);
                const message = "Message removed from trash";
                const variant = "info";
                enqueueSnackbar(message, {variant});
            } else {
                const message = "An unknown error has occurred";
                const variant = "error";
                enqueueSnackbar(message, {variant});
            }

        }).then(response => {
            setUntrashLoading(false);
        });


    }

    function handleDeleteAll() {
        setDeleteAllLoading(true);
        const ck = chk();
        const session = ck.session;
        const cs = ck.cs;
        api.post(apiUrl() + "api/v2/inbox/delete/all/", {cs: cs, session: session}).then(response => {
            if (response.data.status === "ok") {
                // Set new items
                setInboxData(response.data.inbox);
                setPinnedData(response.data.pinned);
                setTrashData(response.data.trash);
                setLoading(false);
                const message = "All items permanently deleted";
                const variant = "info";
                enqueueSnackbar(message, {variant});
            } else {
                const message = "An unknown error has occurred";
                const variant = "error";
                enqueueSnackbar(message, {variant});
            }

        }).then(response => {
            setDeleteAllLoading(false);
        });
    }

    function handleUnpinAll() {
        setUnpinAllLoading(true);
        const ck = chk();
        const session = ck.session;
        const cs = ck.cs;
        api.post(apiUrl() + "api/v2/inbox/unpin/all/", {cs: cs, session: session}).then(response => {
            if (response.data.status === "ok") {
                // Set new items
                setInboxData(response.data.inbox);
                setPinnedData(response.data.pinned);
                setTrashData(response.data.trash);
                setLoading(false);
                const message = "All items unpinned";
                const variant = "info";
                enqueueSnackbar(message, {variant});
            } else {
                const message = "An unknown error has occurred";
                const variant = "error";
                enqueueSnackbar(message, {variant});
            }

        }).then(response => {
            setUnpinAllLoading(false);
        });
    }


    const renderMenu = () => (
        <Menu
            anchorEl={openMenu}
            anchorReference={null}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
            }}
            open={Boolean(openMenu)}
            onClose={handleCloseMenu}
            sx={{mt: 2}}
        >
            <a href={"mailto:" + fromEmail + "?subject=RE: " + mailSubject + " -- " + website}><NotificationItem icon={<Icon sx={{color: "#1D71BF"}}>reply</Icon>} title="Reply"/></a>
            <a href={"mailto:?subject=FWD: " + mailSubject + " -- " + website}><NotificationItem icon={<Icon sx={{color: "#1D71BF"}}>forward</Icon>} title="Forward"/></a>
            <NotificationItem onClick={handleDelete} icon={<Icon sx={{color: "#FF0000"}}>delete_forever</Icon>} title="Delete"/>
            <NotificationItem icon={<Icon sx={{color: "#FF0000"}}>emoji_flags</Icon>} title="Mark As Spam"/>
            <NotificationItem icon={<Icon sx={{color: "#FEB913"}}>report_problem</Icon>} title="Report Email"/>
            {/*<NotificationItem icon={<Icon sx={{color:"#1D71BF"}}>download</Icon>} title="Download As PDF"/>*/}
            <NotificationItem onClick={printMessage} icon={<Icon sx={{color: "#1D71BF"}}>print</Icon>} title="Print"/>
            <NotificationItem onClick={handlePin} icon={<Icon sx={{color: "#1D71BF"}}>push_pin</Icon>} title="Pin Message"/>
            {/*<NotificationItem icon={<Icon sx={{color:"#1D71BF"}}>schedule_send</Icon>} title="Send To Job Scheduler"/>*/}
        </Menu>
    );

    function printMessage() {
        const pri = document.getElementById("ifmcontentstoprint").contentWindow;
        pri.document.open();
        pri.document.write(message);
        pri.document.close();
        pri.focus();
        pri.print();
    }


    if (isLoading) {
        return (
            <PageLoading/>
        )
    }

    return (


        <DashboardLayout>
            <DashboardNavbar/>

            <Grid
                container
                spacing={3}
            >
                <Grid item md={3} xs={12}>

                    <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                            <Tab label="Inbox" {...a11yProps(0)} />
                            <Tab label="Pinned Items" {...a11yProps(1)} />
                            <Tab label="Trash" {...a11yProps(2)} />

                        </Tabs>
                    </Box>
                    <Box className="list-group">
                        <Box sx={{width: '100%'}}>
                            {(isDeviceMobile) ?
                                <TabPanel value={value} index={0}>
                                    {
                                        inboxData.map((item, index) => (
                                            <SwipeToDelete
                                                key={item.id}
                                                onLeft={onLeft}
                                                onRight={onRight}
                                                onDelete={onDelete}
                                                onCancel={onCancel}
                                                classNameTag={isRight ? "is-right" : isLeft ? "is-left" : ""}
                                            >
                                                <a className="inbox-item" onClick={toggleDrawer(true, item.id)} ref={inboxItem}>
                                                    {/*<a className="inbox-item" onClick={() => getMessage(item.id)}>*/}
                                                    <h4 className="list-group-item-heading">{item.text}</h4>
                                                    <div className="list-group-item-text">{item.date}</div>
                                                    <div className="list-group-item-text">{item.from}</div>
                                                </a>
                                            </SwipeToDelete>
                                        ))

                                    }
                                </TabPanel>
                                :
                                <TabPanel value={value} index={0}>
                                    {
                                        inboxData.map((item, index) => (
                                            <Box key={item.id}  className="inbox-item" sx={{borderLeft: (item.read == 0) ? "4px solid #1D71BF" : ""}}>
                                                <Grid container spacing={3}>
                                                    <Grid item lg={10}>
                                                        <Box key={item.id}>
                                                            <a style={{cursor: "pointer"}} onClick={toggleDrawer(true, item.id)} ref={inboxItem}>
                                                                {/*<a className="inbox-item" onClick={() => getMessage(item.id)}>*/}
                                                                <h4 className="list-group-item-heading">{item.text}</h4>
                                                                <div className="list-group-item-text">{item.date}</div>
                                                                <div className="list-group-item-text">{item.from}</div>
                                                            </a>
                                                        </Box>
                                                    </Grid>
                                                    <Grid item lg={2}>
                                                        {(indexLoading && messageId === item.id) ? <CircularProgress color="info" size={30} sx={{marginTop: "30px"}}/> :
                                                            <Box>
                                                                <Icon onClick={() => handlePinInterupt(item.id)}
                                                                      title="Pin Message"
                                                                      fontSize="medium"
                                                                      sx={{
                                                                          cursor: "pointer",
                                                                          float: "right",

                                                                          color: "#1D71BF"
                                                                }}>push_pin</Icon>
                                                                <Icon onClick={() => handleDeleteInterrupt(item.id)}
                                                                      title="Pin Message"
                                                                      fontSize="medium"
                                                                      sx={{
                                                                          cursor: "pointer",
                                                                          float: "right",
                                                                          marginTop: "5px",
                                                                          color: "#FF0000"
                                                                }}>delete</Icon>
                                                            </Box>
                                                        }
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        ))
                                    }
                                </TabPanel>
                            }
                            <TabPanel value={value} index={1}>
                                {(pinsData.length) ?
                                    <Box>
                                        {(!unpinAllLoading) ?
                                            <MDButton variant="outlined" style={{float: "right"}} color="info" size="small"
                                                      onClick={handleUnpinAll}>
                                                Unpin All Messages
                                            </MDButton> :
                                            <CircularProgress color="info" size={40} sx={{float: "right"}}/>
                                        }
                                    </Box>
                                    :
                                    <Box>
                                        No pinned items ...
                                    </Box>
                                }

                                {
                                    pinsData.map((item, index) => (
                                        // <SwipeToDelete
                                        //     key={item.id}
                                        //     onDelete={unPin}
                                        //     classNameTag="is-unpin"
                                        // >
                                        <Grid container spacing={3}>
                                            <Grid item lg={10}>
                                                <Box key={item.id} sx={{borderLeft: (item.read == 0) ? "4px solid #1D71BF" : ""}}>
                                                    <a style={{cursor: "pointer"}} className="inbox-item" onClick={toggleDrawer(true, item.id)} ref={inboxItem}>
                                                        {/*<a className="inbox-item" onClick={() => getMessage(item.id)}>*/}
                                                        <h4 className="list-group-item-heading">{item.text}</h4>
                                                        <div className="list-group-item-text">{item.date}</div>
                                                        <div className="list-group-item-text">{item.from}</div>
                                                    </a>
                                                </Box>
                                            </Grid>
                                            <Grid item lg={2}>
                                                {(unpinLoading && messageId === item.id) ? <CircularProgress color="info" size={30} sx={{marginTop: "30px"}}/> :
                                                    <Icon onClick={() => unPinItem(item.id)} title="Unpin Message" fontSize="large" sx={{cursor: "pointer", float: "right", marginTop: "30px", color: "#1D71BF"}}>settings_backup_restore</Icon>}
                                            </Grid>
                                        </Grid>
                                        // </SwipeToDelete>
                                    ))
                                }
                            </TabPanel>
                            <TabPanel value={value} index={2}>
                                {(trashData.length) ?
                                    <Box>
                                        {(!deleteAllLoading) ?
                                            <MDButton variant="outlined" style={{float: "right"}} color="error" size="small"
                                                      onClick={handleDeleteAll}>
                                                Empty Trash
                                            </MDButton>
                                            :
                                            <CircularProgress color="info" size={40} sx={{float: "right"}}/>
                                        }
                                    </Box>
                                    :
                                    <Box>
                                        Trash can is empty
                                    </Box>
                                }
                                {
                                    trashData.map((item, index) => (
                                        // <SwipeToDelete
                                        //     key={item.id}
                                        //     onDelete={unDelete}
                                        //     classNameTag="is-undelete"
                                        // >
                                        <Grid container spacing={3}>
                                            <Grid item lg={10}>
                                                <Box key={item.id} sx={{borderLeft: (item.read == 0) ? "4px solid #1D71BF" : ""}}>
                                                    <a style={{cursor: "pointer"}} className="inbox-item" onClick={toggleDrawer(true, item.id)} ref={inboxItem}>
                                                        {/*<a className="inbox-item" onClick={() => getMessage(item.id)}>*/}
                                                        <h4 className="list-group-item-heading">{item.text}</h4>
                                                        <div className="list-group-item-text">{item.date}</div>
                                                        <div className="list-group-item-text">{item.from}</div>
                                                    </a>
                                                </Box>
                                            </Grid>
                                            <Grid item lg={2}>
                                                {(untrashLoading && messageId === item.id) ? <CircularProgress color="info" size={30} sx={{marginTop: "30px"}}/> :
                                                    <Icon onClick={() => unTrashItem(item.id)} title="Undelete Message" fontSize="large" sx={{cursor: "pointer", float: "right", marginTop: "30px", color: "#1D71BF"}}>settings_backup_restore</Icon>}
                                            </Grid>
                                        </Grid>
                                        // </SwipeToDelete>
                                    ))
                                }
                            </TabPanel>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={9}>
                    <Drawer
                        PaperProps={{
                            sx: {width: "97%", marginRight: 1},
                        }}
                        anchor="right"
                        open={open}
                        onClose={toggleDrawer(false)}
                    >
                        <Box p={3} sx={{borderBottom: "1px solid #DDD"}}>
                            <Icon onClick={toggleDrawer(false, 0)} fontSize="large" sx={{cursor: "pointer", float: "left"}}>close</Icon>
                            <Icon onClick={handleOpenMenu} fontSize="large" sx={{cursor: "pointer", float: "right"}}>more_horiz</Icon>
                            {renderMenu()}
                        </Box>
                        {(messageLoading) ?
                            <Grid
                                container
                                spacing={0}
                                direction="column"
                                alignItems="center"
                                justifyContent="center"
                                style={{minHeight: '80vh'}}
                            >

                                <Grid item xs={3}>
                                    <CircularProgress color="info" size={120}/>
                                </Grid>

                            </Grid>
                            :
                            <Box p={5}>
                                <div dangerouslySetInnerHTML={{__html: message}}/>
                            </Box>
                        }


                    </Drawer>
                </Grid>
            </Grid>
            <iframe id="ifmcontentstoprint" style={{height: "0px", width: "0px", position: "absolute"}}></iframe>
            <Footer/>
        </DashboardLayout>

    );
}

export default Inbox;
