// @mui material components
import React, {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Rankings from "layouts/seo/components/rankings";
import PageViews from "layouts/dashboard/components/WebsiteMetrics/components/WebsiteTraffic/components/PageViews";
import Engagement from "layouts/dashboard/components/WebsiteMetrics/components/WebsiteTraffic/components/Engagement";
import ByLocale from "layouts/dashboard/components/WebsiteMetrics/components/WebsiteTraffic/components/ByLocale";
import SessionsByState
    from "layouts/dashboard/components/WebsiteMetrics/components/WebsiteTraffic/components/SessionsByState";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import IframeResizer from 'iframe-resizer-react'
import CircularProgress from "@mui/material/CircularProgress";
import {ComposableMap, Geographies, Geography, Marker, Annotation} from "react-simple-maps";
import {scaleQuantile} from "d3-scale";
import {geoCentroid} from "d3-geo";
import {csv} from "d3-fetch";

// const geoUrl = "/gadm.json";


interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const {children, value, index, ...other} = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function Report({report}) {
    const [tabValue, setTabValue] = React.useState(0);
    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };

    return (
        <Box>
            <Tabs value={tabValue} onChange={handleTabChange} aria-label="basic tabs example">
                <Tab label="Analytics" {...a11yProps(0)} />
                <Tab label="Rankings" {...a11yProps(1)} />
                <Tab label="AdWords & Past Reports" {...a11yProps(1)} />
            </Tabs>

            <TabPanel value={tabValue} index={0}>
                <Grid container sx={{marginTop: "25px;"}} spacing={3}>
                    <Grid item lg={6} md={12}>
                        <Typography sx={{fontWeight: "bold"}}>
                            How are site sessions/website visits trending?
                        </Typography>
                        <PageViews/>
                    </Grid>
                    <Grid item lg={6} md={12}>
                        <Typography sx={{fontWeight: "bold"}}>
                            What are the top States by sessions/visits?
                        </Typography>
                        <SessionsByState/>
                    </Grid>
                    <Grid item lg={6} md={12}>
                        <Typography sx={{fontWeight: "bold"}}>
                            Which channels are driving engagement?
                        </Typography>
                        <Engagement/>
                    </Grid>
                    <Grid item lg={6} md={12}>
                        <Typography sx={{fontWeight: "bold"}}>
                            What local area is my traffic coming from?
                        </Typography>
                        <ByLocale/>
                    </Grid>
                </Grid>
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
                <Rankings/>
            </TabPanel>
            <TabPanel value={tabValue} index={2}>
                <Box sx={{height: "80vh"}}>
                    <IframeResizer
                        src={report}
                        style={{width: '100%', height: '100%'}}
                    />
                </Box>
            </TabPanel>
        </Box>
    )
}

export default Report;