import axios from 'axios';
import React, {useEffect, useState} from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Dialog
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';

// Material Dashboard 2 React example components
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import Footer from "components/Footer";

// Dashboard components
import colors from "assets/theme/base/colors"
import Box from "@mui/material/Box";
import {Icon} from "@mui/material";
import {apiUrl, chk} from "App";
import api from "utils/axiosConfig";
import MDButton from "../../components/MDButton";
import CircularProgress from "@mui/material/CircularProgress";
import {useSnackbar} from "notistack";

const ywpBlue = colors.ywpBlue.main;
const text = colors.text.main;
const ywpRed = colors.ywpRed.main;
const ywpWhite = colors.ywpWhite.main;

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


//usage: {apiUrl()}
// http://localhost:3000/media/MapYourProjects-Template.xls

function MYP() {
    const [selectedFile, setSelectedFile] = useState(null);
    const [isFilePicked, setIsSelected] = useState(false);
    const [uploadLoader, setUploadLoader] = useState(false);
    const [uploadOpen, SetUploadOpen] = useState(false);
    const [importer, setImporter] = useState('open');
    const [importFiles, setImportFiles] = useState({});
    const [importing, setImporting] = useState('');
    const [importWidth, setImportWidth] = useState(0);
    const [report, setReport] = useState([]);
    const {enqueueSnackbar} = useSnackbar();
    function handleFileChange(e) {
        console.log(e.target.files[0]);
        setSelectedFile(e.target.files[0]);
        setIsSelected(true);
    }

    function handleViewReport() {
        setImporter('report');
    }

    function handleUploadClose() {
        setUploadLoader(false);
        SetUploadOpen(false);
        setImporter('open');
        setReport([]);
        setImporting('');
        setImportWidth(0);
    }

    async function importProjects() {
        setImporter('import');
        const ck = chk();
        const session = ck.session;
        const cs = ck.cs;
        const keys = Object.keys(importFiles);
        let length = Object.keys(importFiles).length;
        let importLen = 0;
        length = Math.round(100 / length);
        for (const key of keys) {
            setImporting(importFiles[key].project_name)
            const response = await api.post(apiUrl() + "api/v2/myp/bulkUpload/addPin/", {
                pin: importFiles[key],
                cs: cs,
                session: session
            });
            importLen = importLen + length;
            setImportWidth(importLen + '%')
            let tmpReport = report;
            tmpReport.push(response.data);
            setReport(tmpReport);
        }
        setImporter('complete')
    }

    const uploadXLS = () => {
        setUploadLoader(true);
        const ck = chk();
        const session = ck.session;
        const cs = ck.cs;
        const body = new FormData();
        body.append('cs', cs)
        body.append('session', session)
        body.append('File', selectedFile);

        api.post(apiUrl() + "api/v2/myp/bulkUpload/", body).then(response => {

            if(response.data.status === 'error'){
                setUploadLoader(false);
                const message = response.data.message;
                const variant = "error";
                enqueueSnackbar(message, {variant});
                return false;
            }

            console.log(response.data);
            setUploadLoader(false);
            SetUploadOpen(true);
            setImportFiles(response.data.excel_rows);
        });
    }

    return (

        <DashboardLayout>
            <DashboardNavbar/>


            <Box>
                Welcome to MapYourProject Bulk Import Process<br/><br/>
                You are going to need to use Microsoft Excel 97-2003 format to use the Bulk Importer<br/><br/>
                To get started, download the Excel file below.<br/>
                <Box sx={{fontSize: "60px"}}>
                    <a href={"/media/MapYourProjects-Template.xls"}><Icon sx={{color: "#529672"}}>save_alt</Icon></a>
                </Box>
            </Box>

            <hr/>
            <br/>
            <Box>
                Next, fill out the Excel file as completely as you can.
                <Box sx={{
                    fontSize: "12px",
                    padding: "15px"
                }}>
                    <ul>
                        <li>Please Note: All of the following applies to filling out the Excel form</li>
                        <li>If a project line in the Excel file is missing data, the project will not be saved.</li>
                        <li>Project Name must be unique. This is the identifier for the project</li>
                        <li>Client (homeowner) name is required</li>
                        <li>Client email is required -- To verify you have permission to display address information.
                        </li>
                        <li>Client phone is required -- To verify you have permission to display address information.
                        </li>
                        <li>Project description must be at least 55 characters in length.</li>
                        <li>Be as accurate as possible when filling out the address, this limits automation issues.</li>
                    </ul>
                </Box>
            </Box>
            <hr/>
            <br/>

            <Box>
                Upload your Excel File<br/>
                <input type="file" onChange={e => handleFileChange(e)}/>
            </Box>
            {(isFilePicked) ?
                (uploadLoader) ?
                    <CircularProgress color="info" size={60}/> :
                    <MDButton variant="outlined" color="info" size="small" sx={{marginTop: "10px"}} onClick={uploadXLS}>Upload
                        Projects
                    </MDButton>
                : <></>}
            <Footer/>
            <Dialog
                fullWidth
                open={uploadOpen}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleUploadClose}
                aria-describedby="alert-dialog-slide-description"
            >
                {(importer === 'open') ? <DialogTitle>{"Confirm Import of Uploaded Excel File"}</DialogTitle> : <></>}
                {(importer === 'import') ? <DialogTitle>{"Importing Projects"}</DialogTitle> : <></>}
                {(importer === 'complete') ? <DialogTitle>{"Import Finished"}</DialogTitle> : <></>}
                {(importer === 'report') ? <DialogTitle>{"Import Report"}</DialogTitle> : <></>}

                <DialogContent>
                    {(importer === 'open') ?
                        <DialogContentText id="alert-dialog-slide-description">
                            You are about to bulk import the projects within the Excel file you uploaded. Pins will
                            automatically be inserted onto your map and individual project pages created.<br/><br/>

                            You cannot "undo" an import. <br/><br/>

                            If there are errors in the Excel file, you will have the opportunity to<br/><br/>
                            <Box sx={{
                                paddingLeft: "20px"
                            }}>
                                <ul>
                                    <li>Edit the job manually.</li>
                                    <li>Delete the job entirely.</li>
                                    <li>Edit the Excel file and re-upload.</li>
                                </ul>
                            </Box>
                            <br/>
                            <b>HOME/BUSINESS OWNER PRIVACY NOTICE:</b> <br/>By clicking "Import Projects" I have
                            verified with
                            the
                            home/business owner that this project will be placed on a public map.<br/><br/>

                            As such, I certify by moving forward that I have the home/business owners express permission
                            to
                            move forward with displaying this project, which could include public address, images of the
                            project, customer name and other information pertinent to the project.
                        </DialogContentText> : <></>}
                    {(importer === 'import') ?
                        <DialogContentText id="alert-dialog-slide-description">
                            Importing:<br/>
                            <b>{importing}</b>
                            <Box sx={{
                                height: "30px",
                                backgroundColor: "#ffc8c8"
                            }}>

                                <Box sx={{
                                    height: "30px",
                                    width: importWidth,
                                    backgroundColor: "#acceff",
                                    textAlign: "center",
                                    color: "#424242"
                                }}>
                                </Box>
                            </Box>
                            <Box sx={{
                                textAlign: "center"
                            }}>
                                {importWidth}
                            </Box>

                        </DialogContentText> : <></>
                    }
                    {(importer === 'complete') ?
                        <DialogContentText id="alert-dialog-slide-description">
                            Your import has successfully completed!
                        </DialogContentText> : <></>
                    }
                    {(importer === 'report') ?
                        <DialogContentText id="alert-dialog-slide-description">
                            {report.map((item, key) =>
                                <Box>
                                    <Grid container spacing={3} key={key} p={1}>
                                        <Grid item xs={12} md={6}>
                                            {item.project_name}
                                        </Grid>

                                        <Grid item xs={12} md={6}>
                                            {(item.status === 'ok') ?
                                                <Box sx={{
                                                    textAlign:"center",
                                                    backgroundColor: "#93e893",
                                                    color:"#333"
                                                }}>
                                                    SUCCESS
                                                </Box> :
                                                <Box sx={{
                                                    textAlign:"center",
                                                    backgroundColor: "#fd8681",
                                                    color:"#333"
                                                }}>
                                                    {item.message}
                                                </Box>
                                            }
                                        </Grid>

                                    </Grid>
                                    <hr />
                                </Box>
                            )}


                        </DialogContentText> : <></>
                    }
                </DialogContent>
                {(importer === 'open') ?
                    <DialogActions>
                        <MDButton onClick={handleUploadClose} variant="outlined" color="info">Cancel</MDButton>
                        <MDButton variant="outlined" color="success" onClick={importProjects}>Import Projects</MDButton>
                    </DialogActions> : <></>}
                {(importer === 'complete') ?
                    <DialogActions>
                        <MDButton onClick={handleUploadClose} variant="outlined" color="info">Close</MDButton>
                        <MDButton variant="outlined" color="success" onClick={handleViewReport}>View Report</MDButton>
                    </DialogActions> : <></>}
                {(importer === 'report') ?
                    <DialogActions>
                        <MDButton onClick={handleUploadClose} variant="outlined" color="info">Close</MDButton>
                    </DialogActions> : <></>}
            </Dialog>
        </DashboardLayout>
    );
}

export default MYP;
