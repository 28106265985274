import React, {useState, useEffect} from 'react';
import Stepper from "react-stepper-horizontal";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import {apiUrl, chk} from "App";
import api from "utils/axiosConfig";
import {useSnackbar} from "notistack";
import Typography from "@mui/material/Typography";
import TextField from '@mui/material/TextField';
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import visaCardLogo from "assets/images/logos/visa.png";
import masterCardLogo from "assets/images/logos/mastercard.png";
import amexCardLogo from "assets/images/logos/amex.png";
import discoverCardLogo from "assets/images/logos/discover.png";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import FormField from "@mui/material/Input";
import {PaymentInputsWrapper, usePaymentInputs} from "react-payment-inputs";
import images from "react-payment-inputs/images";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import Button from "@mui/material/Button";
import MDButton from "../../../../components/MDButton";
import Invoice from "../../../billing/components/Invoice";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function Payment({setActiveStep, setIsGood}) {
    const {enqueueSnackbar} = useSnackbar();
    const [isLoading, setIsLoading] = useState(true)
    const [contractor, setContractor] = useState(true)
    const [card, setCard] = useState(true)
    const [balance, setBalance] = useState(true)
    const [addCardOpen, setAddCardOpen] = React.useState(false);
    const [cardNick, setCardNick] = React.useState('');
    const [cardNumber, setCardNumber] = React.useState('');
    const [cardExp, setCardExp] = React.useState('');
    const [cardCvn, setCardCvn] = React.useState('');
    const [cardOpen, setCardOpen] = React.useState(false);
    const [newCardLoading, setNewCardLoading] = useState(false);
    const [isPaymentLoading, setPaymentLoading] = useState(0);


    const handleAddCardOpen = (e) => {
        setCardNick('');
        setCardNumber('');
        setCardExp('');
        setCardCvn('');
        setAddCardOpen(true);
    };

    const {
        wrapperProps,
        getCardImageProps,
        getCardNumberProps,
        getExpiryDateProps,
        getCVCProps,
        meta
    } = usePaymentInputs();

    const handleCardClose = () => {
        setCardOpen(false);
    };

    const handleAddClose = () => {
        setAddCardOpen(false);
    };

    function handleNumChange(e) {
        setCardNumber(e.target.value);
    }

    function handleExpChange(e) {
        setCardExp(e.target.value);
    }

    function handleCvnChange(e) {
        setCardCvn(e.target.value);
    }

    function addNewCard() {
        if (!cardNick || !cardNumber || !cardExp || !cardCvn) {
            const message = "The card information you've entered is invalid.";
            const variant = "error";
            enqueueSnackbar(message, {variant});
            return false;
        }

        setNewCardLoading(true);
        const ck = chk();
        const session = ck.session;
        const cs = ck.cs;

        api.post(apiUrl() + "api/v2/billing/add_card/", {
            cs: cs,
            session: session,
            card_name: cardNick,
            card_cc: cardNumber,
            card_exp: cardExp,
            card_cvn: cardCvn,
            card_type: meta.cardType.displayName
        }).then(response => {
            if (response.data.status === "ok") {
                const message = "Card successfully added. Your card will be saved for monthly billing";
                const variant = "success";
                enqueueSnackbar(message, {variant});
                setCardNick('');
                setCardNumber('');
                setCardExp('');
                setCardCvn('');
                setCard(response.data.primary);
                handleAddClose();

                //  Set the card variable with the return

            } else {
                const message = "There was an error processing your card. Reason: " + response.data;
                const variant = "error";
                enqueueSnackbar(message, {variant});
            }
            setNewCardLoading(false);
        });
    }

    useEffect(() => {

        const ck = chk();
        const session = ck.session;
        const cs = ck.cs;

        api.post(apiUrl() + "api/v2/completeSetup/payment/", {cs: cs, session: session}).then(response => {
            if (response.data.status === 'ok') {
                setContractor(response.data.account.contractor);
                setCard(response.data.account.card);
                setBalance(response.data.account.balance);
                setIsLoading(false);

                setIsGood(false)
            } else {
                const message = "Could not retrieve payment info  --  Please contact sales or support";
                const variant = "error";
                enqueueSnackbar(message, {variant});
                setIsLoading(false);
                setActiveStep(-2);
            }

        }).then(response => {

        });
    }, []);

    function handlePayment(){
        const ck = chk();
        const session = ck.session;
        const cs = ck.cs;
        setPaymentLoading(1);

        api.post(apiUrl() + "api/v2/billing/ppmt/", {cs: cs, session: session}).then(response => {
            if (response.data.status === 'ok') {
                paymentThankyou();
                setPaymentLoading(2);
                setIsGood(true)
                setActiveStep(5);
            } else {
                setPaymentLoading(0);
                paymentFailed();
            }
        });
    }

    const paymentThankyou = (customProps) => {
        const message = "Your payment has been processed.  Thank you!";
        const variant = "success";
        enqueueSnackbar(message, {variant});
    }
    const paymentFailed = (customProps) => {
        const message = "We're sorry we could not process your payment at this time.";
        const variant = "error";
        enqueueSnackbar(message, {variant});
    }
    if (isLoading) {
        return (
            <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
            >

                <Grid item xs={3}>
                    <CircularProgress color="info" size={120}/>
                </Grid>

            </Grid>
        );
    }

    return (
        <div style={{fontFamily: "Arial"}}>
            <pre>
                 {/*{JSON.stringify(contractor, null, 2)}*/}
                {/*{JSON.stringify(card, null, 2)}*/}
                {/*{JSON.stringify(balance, null, 2)}*/}
            </pre>

            <Card style={{padding: 10}}>
                <Typography sx={{fontSize: "32px"}}>Payment Info</Typography>
                <hr/>
                {
                    (card.cc_number) ?
                        <Box>
                            <Grid item xs={12} md={6} id={"card_" + card.id}>

                                <Grid
                                    container
                                    borderRadius="lg"
                                    display="flex"
                                    p={2}
                                    sx={{}}
                                >
                                    <Grid item xs={12} fontWeight="bold">{card.cc_name}</Grid>

                                    {(card.cc_type.includes("isa"))
                                        ?
                                        <Box component="img" src={visaCardLogo} alt="master card" width="10%" mr={2}/>
                                        :
                                        <></>
                                    }
                                    {(card.cc_type.includes("aster"))
                                        ?
                                        <Box component="img" src={masterCardLogo} alt="master card" width="10%" mr={2}/>
                                        :
                                        <></>
                                    }
                                    {(card.cc_type.includes("Ame"))
                                        ?
                                        <Box component="img" src={amexCardLogo} alt="master card" width="10%" mr={2}/>
                                        :
                                        <></>
                                    }
                                    {(card.cc_type.includes("iscover"))
                                        ?
                                        <Box component="img" src={discoverCardLogo} alt="master card" width="10%"
                                             mr={2}/>
                                        :
                                        <></>
                                    }

                                    <Typography variant="h6" fontWeight="medium">
                                        ****&nbsp;&nbsp;****&nbsp;&nbsp;****&nbsp;&nbsp;{card.cc_number}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <hr/>
                            <Box>
                                <Typography>
                                    Use the above card or,<br/>
                                    <Button style={{backgroundColor: "#165dbf", color: "#FFF"}}
                                            onClick={handleAddCardOpen}>
                                        <Icon sx={{fontWeight: "bold"}}>add</Icon>
                                        &nbsp;add new card
                                    </Button>
                                </Typography>
                            </Box>
                        </Box>

                        :
                        <Box>
                            <Button style={{backgroundColor: "#165dbf", color: "#FFF"}}
                                    onClick={handleAddCardOpen}>
                                <Icon sx={{fontWeight: "bold"}}>add</Icon>
                                &nbsp;add new card
                            </Button>
                        </Box>
                }
                <hr/>
                <b>{balance}</b> is due immediately.  Click the button below to run the card on file.<br /><br />
                {(isPaymentLoading === 0) ?
                    <Button style={{backgroundColor: "#609f4e", color: "#FFF"}}
                            onClick={handlePayment}>
                        <Icon sx={{fontWeight: "bold"}}>money</Icon>
                        &nbsp;process payment
                    </Button> : <></> }
                {(isPaymentLoading === 1) ?
                    <Box sx={{fontSize: "16px"}}>
                        <br />
                        We're processing your payment, please wait ... <CircularProgress color="info" size={25}/>
                    </Box>
                    : <></>}
                {(isPaymentLoading === 2) ?
                    <Box sx={{fontSize: "16px"}}>
                        <br />
                        <Icon sx={{fontWeight: "bold", color:"#609f4e"}}>check</Icon> Payment Completed!
                    </Box> : <></> }

                <Dialog
                    open={addCardOpen}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={handleAddClose}
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle>{"Add Payment Card"}</DialogTitle>
                    <DialogContent>
                        <Typography>Nick Name Your Card</Typography>
                        <FormField
                            style={{width: '100%', marginTop: "5px", marginBottom: "5px"}}
                            placeholder="John's Debit Card"
                            onKeyUp={(e) => setCardNick(e.target.value)}
                        /><br/>
                        <PaymentInputsWrapper {...wrapperProps} >
                            <svg {...getCardImageProps({images})} />
                            <input {...getCardNumberProps({onChange: handleNumChange})} />
                            <input {...getExpiryDateProps({onChange: handleExpChange})}/>
                            <input {...getCVCProps({onChange: handleCvnChange})} />
                        </PaymentInputsWrapper><br/>
                        <span style={{
                            color: "#FF0000",
                            fontSize: "12px",
                            fontWeight: "bold"
                        }}>&nbsp;{(meta.error) ? meta.error : <></>}</span>
                    </DialogContent>
                    {(!meta.error && cardNumber && cardExp && cardCvn && cardNick) ?
                        <DialogActions>
                            {
                                (newCardLoading ?
                                        <CircularProgress color="info" size={40}/>
                                        :
                                        <Button color="success" onClick={addNewCard}>Add Card</Button>
                                )
                            }
                        </DialogActions>
                        :
                        <DialogActions>
                            {
                                (newCardLoading ?
                                        <CircularProgress color="info" size={40}/>
                                        :
                                        <Button variant="outlined" color="info" disabled>Add Card</Button>
                                )
                            }
                        </DialogActions>
                    }
                    {/*{cardNick}<br/>*/}
                    {/*{cardNumber}<br/>*/}
                    {/*{cardExp}<br/>*/}
                    {/*{cardCvn}<br/>*/}


                </Dialog>
            </Card>
        </div>
    );
}

export default Payment;