/**
 =================================
 YourWebPro Contractor Portal Application V2
 NodeJS  |  React  |  MaterialUI  |  Material Dash 2
 2022 / 2023
 =================================
 ==========================================================
 Main components such as charts, boxes, sidenav, dash found in /components directory

 Layouts such at Dash, Profile, MapYourProjects, Billing are located in /layouts

 Theme files for colors and base layout definitions found in /assets/theme
 ==========================================================
 */

import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import Footer from "components/Footer";
import PaymentMethod from "layouts/billing/components/PaymentMethod";
import Invoices from "layouts/billing/components/Invoices";
import BillingInformation from "layouts/billing/components/BillingInformation";
import CurrentPayment from "layouts/billing/components/CurrentPayment";
import PastDuePayment from "layouts/billing/components/PastDuePayment";
// Data
import {useSnackbar} from "notistack";
import React, {useEffect, useState} from "react";
import {apiUrl, chk} from "App";
import api from "utils/axiosConfig";
import PageLoading from "components/PageLoading";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import MDButton from "../../components/MDButton";
import Slide from "@mui/material/Slide";
import MDTypography from "components/MDTypography";
import CircularProgress from "@mui/material/CircularProgress";
import Invoice from "./components/Invoice";
import {PaymentInputsWrapper, usePaymentInputs} from 'react-payment-inputs';
import images from 'react-payment-inputs/images';
import FormField from "components/MDInput";
import masterCardLogo from "assets/images/logos/mastercard.png";
import visaCardLogo from "assets/images/logos/visa.png";
import amexCardLogo from "assets/images/logos/amex.png";
import discoverCardLogo from "assets/images/logos/discover.png";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


function Billing() {
    const {
        wrapperProps,
        getCardImageProps,
        getCardNumberProps,
        getExpiryDateProps,
        getCVCProps,
        meta
    } = usePaymentInputs();

    const [cardOpen, setCardOpen] = React.useState(false);
    const [addCardOpen, setAddCardOpen] = React.useState(false);
    const [cardPrimary, setPrimaryOpen] = React.useState(false);
    const [cardAlreadyPrimary, setAlreadyPrimaryOpen] = React.useState(false);
    const [cardId, setCardId] = React.useState('');
    const [cardNick, setCardNick] = React.useState('');
    const [cardNumber, setCardNumber] = React.useState('');
    const [cardExp, setCardExp] = React.useState('');
    const [cardCvn, setCardCvn] = React.useState('');
    const [allInvoices, setInvoices] = useState("Invoices Loading ...");
    const [isLoading, setLoading] = useState(true);
    const [isPaymentLoading, setPaymentLoading] = useState(false);
    const [newCardLoading, setNewCardLoading] = useState(false);

    const [billing, setBilling] = useState({});
    const [noBilling, setNoBilling] = useState();
    const [pif, setPif] = useState(true);
    const [billingCards, setBillingCards] = useState([]);
    const [ccLastFour, setCCLastFour] = useState([]);


    const handleCardOpen = (e) => {
        const card_id = e.currentTarget.getAttribute("data-card_id");
        const primary = e.currentTarget.getAttribute("data-card_primary");

        if (primary === "1") {
            setPrimaryOpen(true);
            return false;
        }

        setCardOpen(true);
        setCardId(card_id);
    };

    const handleSetPrimary = (e) => {
        const card_id = e.currentTarget.getAttribute("data-card_id");
        const primary = e.currentTarget.getAttribute("data-card_is_primary");
        if (primary === "1") {
            setAlreadyPrimaryOpen(true);
            return false;
        }
        // setCardOpen(true);
        setCardId(card_id);
        const ck = chk();
        const session = ck.session;
        const cs = ck.cs;
        //setLoading(true);
        // Make call to API to delete the item.
        api.post(apiUrl() + "api/v2/billing/primary_card/", {cs: cs, session: session, card_id: card_id}).then(response => {
            if (response.data.status === "ok") {
                setCards(response.data.cards)
            } else {
                cantDelete();
                setCardOpen(false);
            }
        });
    };


    const handleAddCardOpen = (e) => {
        setCardNick('');
        setCardNumber('');
        setCardExp('');
        setCardCvn('');
        setAddCardOpen(true);
    };

    const handleCardClose = () => {
        setCardOpen(false);
    };
    const handleAddClose = () => {
        setAddCardOpen(false);
    };
    const handlePrimaryClose = () => {
        setPrimaryOpen(false);
    };
    const handleAlreadyPrimaryClose = () => {
        setAlreadyPrimaryOpen(false);
    };

    const handleDeleteCard = (e) => {
        const ck = chk();
        const session = ck.session;
        const cs = ck.cs;
        //setLoading(true);
        // Make call to API to delete the item.
        api.post(apiUrl() + "api/v2/billing/remove_card/", {cs: cs, session: session, card_id: cardId}).then(response => {
            if (response.data.status === "ok") {
                //setLoading(false);
                document.getElementById("card_" + cardId).remove()
                setCardOpen(false);
                // setLoading(false);
            } else {
                cantDelete();
                setCardOpen(false);
            }

        });

    };


    const {enqueueSnackbar} = useSnackbar();
    const [paymentOpen, setPaymentOpen] = React.useState(false);

    // For demo only -- Should not include pure functions in this manner
    const billingBehind = (customProps) => {
        const message = "You have unpaid invoices, please check billing information";
        const variant = "error";
        enqueueSnackbar(message, {variant});
    }
    const paymentThankyou = (customProps) => {
        const message = "Your payment has been processed.  Thank you!";
        const variant = "success";
        enqueueSnackbar(message, {variant});
    }
    const noBillingInfo = (customProps) => {
        const message = "Your billing information appears incomplete. Please update";
        const variant = "warning";
        enqueueSnackbar(message, {variant});
    }
    const paymentFailed = (customProps) => {
        const message = "We're sorry we could not process your payment at this time.";
        const variant = "error";
        enqueueSnackbar(message, {variant});
    }
    const cantDelete = (customProps) => {
        const message = "There was a problem removing your card.  Please contact us if this problem persists.";
        const variant = "error";
        enqueueSnackbar(message, {variant});
    }

    function setCards(cardMap) {
        cardMap.map((item, i) =>
            (item.cc_primary) ? setCCLastFour(item.cc_number) : ''
        )
        setBillingCards(
            cardMap.map((item, i) =>

                <Grid item xs={12} md={6} key={item.id} id={"card_" + item.id}>

                    <Grid
                        container
                        borderRadius="lg"
                        display="flex"
                        p={2}
                        sx={{
                            border: ({borders: {borderWidth, borderColor}}) =>
                                `${borderWidth[1]} solid ${borderColor}`,
                        }}
                    >
                        <Grid item xs={12} fontWeight="bold">{item.cc_name}</Grid>

                        {(item.cc_type.includes("isa"))
                            ?
                            <MDBox component="img" src={visaCardLogo} alt="master card" width="10%" mr={2}/>
                            :
                            <></>
                        }
                        {(item.cc_type.includes("aster"))
                            ?
                            <MDBox component="img" src={masterCardLogo} alt="master card" width="10%" mr={2}/>
                            :
                            <></>
                        }
                        {(item.cc_type.includes("Ame"))
                            ?
                            <MDBox component="img" src={amexCardLogo} alt="master card" width="10%" mr={2}/>
                            :
                            <></>
                        }
                        {(item.cc_type.includes("iscover"))
                            ?
                            <MDBox component="img" src={discoverCardLogo} alt="master card" width="10%" mr={2}/>
                            :
                            <></>
                        }

                        <MDTypography variant="h6" fontWeight="medium">
                            ****&nbsp;&nbsp;****&nbsp;&nbsp;****&nbsp;&nbsp;{item.cc_number}
                        </MDTypography>
                        <MDBox ml="auto" lineHeight={0}>
                            <Tooltip title="Primary Card" placement="top">
                                <Icon style={{cursor: "pointer"}}
                                      fontSize="small"
                                      color={!item.cc_primary ? "disabled" : "success"}
                                      data-card_is_primary={item.cc_primary}
                                      data-card_id={item.id}
                                      onClick={handleSetPrimary}
                                      id={"primary_" + item.id}
                                >
                                    check_circle
                                </Icon>
                            </Tooltip>
                            {/*&nbsp;*/}
                            {/*&nbsp;*/}
                            {/*<Tooltip title="Edit Card" placement="top">*/}
                            {/*    <Icon sx={{cursor: "pointer"}} fontSize="small">*/}
                            {/*        edit*/}
                            {/*    </Icon>*/}
                            {/*</Tooltip>*/}
                            &nbsp;
                            &nbsp;
                            <Tooltip title="Delete Card" placement="top">
                                <Icon sx={{cursor: "pointer"}} fontSize="small" color="error" onClick={handleCardOpen} data-card_primary={item.cc_primary} data-card_id={item.id}>
                                    delete
                                </Icon>
                            </Tooltip>
                        </MDBox>
                    </Grid>
                </Grid>
            )
        )
    }

    useEffect(() => {
        // axios.defaults.withCredentials = true;
        const ck = chk();
        const session = ck.session;
        const cs = ck.cs;

        api.post(apiUrl() + "api/v2/billing/", {cs: cs, session: session}).then(response => {
            if (response.data.status === 'ok') {
                setBilling(response.data);
                setCards(response.data.cards);
                setLoading(false);
                if (response.data.pif === false) {
                    billingBehind();
                    setPif(false);
                }
                let mappedInvoices = response.data.all_invoices.map((item, i) =>
                    <Invoice
                        date={item.friendly_date}
                        id={item.id}
                        price={item.amount}
                        key={item.id}
                        paid={item.paid}
                    />
                )
                setInvoices(mappedInvoices)
            } else {
                setNoBilling(true);
                setLoading(false);
            }

        }).then(response => {

        });
    }, []);


    if (isLoading) {
        return (
            <PageLoading/>
        )
    }
    if (noBilling) {

        return (
            <DashboardLayout>
                <DashboardNavbar/>
                <Box>
                    <Grid
                        container
                        spacing={0}
                        direction="column"
                        alignItems="center"
                        justifyContent="center"
                        style={{minHeight: '80vh'}}
                    >

                        <Grid item xs={3}>
                            There appears to be a problem retrieving your billing information.<br/>
                            If this persists, please <a style={{
                            color: "#FF0000",
                        }} href={"mailto:support@yourwebpro.com"}>Contact Support</a> or call (847-884-7400).<br/>
                        </Grid>

                    </Grid>

                </Box>
            </DashboardLayout>
        )
    }
    const handleClickOpenPayment = (e) => {
        setPaymentOpen(true);
    };


    function handleMakePayment() {
        const ck = chk();
        const session = ck.session;
        const cs = ck.cs;
        setPaymentLoading(true);
        api.post(apiUrl() + "api/v2/billing/ppmt/", {cs: cs, session: session}).then(response => {
            if (response.data.status === 'ok') {
                paymentThankyou();
                setPaymentOpen(false);
                setPif(true);
                let mappedInvoices = billing.all_invoices.map((item, i) =>
                    <Invoice
                        date={item.friendly_date}
                        id={item.id}
                        price={item.amount}
                        key={item.id}
                        paid={true}
                    />
                )
                setInvoices(mappedInvoices)

            } else {
                setPaymentOpen(false);
                setPaymentLoading(false);
                paymentFailed();
            }
        });
    }

    const handleClose = () => {
        setPaymentOpen(false);
    };

    function handleNumChange(e) {
        setCardNumber(e.target.value);
    }

    function handleExpChange(e) {
        setCardExp(e.target.value);
    }

    function handleCvnChange(e) {
        setCardCvn(e.target.value);
    }

    function addNewCard() {
        if (!cardNick || !cardNumber || !cardExp || !cardCvn) {
            const message = "The card information you've entered is invalid.";
            const variant = "error";
            enqueueSnackbar(message, {variant});
            return false;
        }


        setNewCardLoading(true);
        const ck = chk();
        const session = ck.session;
        const cs = ck.cs;

        api.post(apiUrl() + "api/v2/billing/add_card/", {
            cs: cs,
            session: session,
            card_name: cardNick,
            card_cc: cardNumber,
            card_exp: cardExp,
            card_cvn: cardCvn,
            card_type: meta.cardType.displayName
        }).then(response => {
            if (response.data.status === "ok") {
                const message = "Card successfully added. Your card will be saved for monthly billing";
                const variant = "success";
                enqueueSnackbar(message, {variant});
                setCardNick('');
                setCardNumber('');
                setCardExp('');
                setCardCvn('');
                handleAddClose();
                setCards(response.data.cards);
            } else {
                const message = "There was an error processing your card. Reason: " + response.data;
                const variant = "error";
                enqueueSnackbar(message, {variant});
            }
            setNewCardLoading(false);
        });
    }

    return (
        <DashboardLayout>
            <DashboardNavbar/>
            <MDBox>
                <Grid
                    container
                    spacing={3}
                    style={{minHeight: '80vh'}}
                >

                    <Grid item lg={6} xs={12}>
                        <Grid
                            container
                            spacing={3}
                        >
                            <Grid item lg={6} md={12}>
                                <Card>
                                    <MDBox p={1}
                                           display="flex"
                                           justifyContent="center"
                                           mt={5}

                                    >
                                        {(pif ?
                                                <MDTypography fontWeight="bold" fontSize="24px;" color="success">Your account is up to date</MDTypography>
                                                :
                                                <MDTypography fontWeight="bold" fontSize="24px;" color="info">Your account is past due</MDTypography>
                                        )}
                                        <br/><br/><br/>
                                    </MDBox>
                                </Card>
                            </Grid>
                            <Grid item lg={6} md={12}>
                                {(pif) ?
                                    <CurrentPayment
                                        billing={billing}
                                    />
                                    :
                                    <PastDuePayment
                                        handleClickOpenPayment={handleClickOpenPayment}
                                        billing={billing}
                                    />
                                }
                                <Dialog
                                    open={paymentOpen}
                                    TransitionComponent={Transition}
                                    keepMounted
                                    onClose={handleClose}
                                    aria-describedby="alert-dialog-slide-description"
                                >
                                    <DialogTitle>{"Confirm Payment:"}</DialogTitle>
                                    <DialogContent>
                                        <DialogContentText id="alert-dialog-slide-description">
                                            YourWebPro will be charging the card ending in <MDTypography component="span" color={"success"} sx={{fontWeight: "bold", fontSize: "16px"}}>{ccLastFour}</MDTypography>.
                                            The card on file will be charged in the amount of <MDTypography component="span" color={"error"} sx={{fontWeight: "bold", fontSize: "16px"}}>{billing.due}</MDTypography>.
                                            Please "confirm" to process your payment.
                                        </DialogContentText>
                                    </DialogContent>
                                    <DialogActions>
                                        {(!isPaymentLoading) ?
                                            <Box>
                                                <MDButton onClick={handleClose} variant="outlined" color="info">Cancel</MDButton>
                                                <MDButton onClick={handleMakePayment} color="info" sx={{marginLeft: "10px"}}>Process Payment</MDButton>
                                            </Box>
                                            :
                                            <Box sx={{fontSize: "16px"}}>
                                                We're processing your payment, please wait ... <CircularProgress color="info" size={25}/>
                                            </Box>
                                        }
                                    </DialogActions>
                                </Dialog>
                            </Grid>

                            <Grid item xs={12}>
                                <Invoices
                                    allInvoices={allInvoices}
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item lg={6} xs={12}>
                        <Grid
                            container
                            spacing={3}
                            // direction="column"
                            // alignItems="center"
                            // justifyContent="center"

                        >
                            <Grid item xs={12}>
                                <PaymentMethod
                                    billingCards={billingCards}
                                    handleAddCardOpen={handleAddCardOpen}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <BillingInformation billing={billing}/>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </MDBox>
            <Footer/>
            <Dialog
                open={cardOpen}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleCardClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{"Are you sure you want to remove this card?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        This will permanently remove the card from YourWebPro.<br/>
                        This action cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <MDButton onClick={handleCardClose} variant="outlined" color="info">Cancel</MDButton>
                    <MDButton id="deleteCardSubmit" onClick={handleDeleteCard} variant="outlined" color="error" data-card_id={cardId}>Delete Card</MDButton>

                </DialogActions>
            </Dialog>
            <Dialog
                open={cardPrimary}
                TransitionComponent={Transition}
                keepMounted
                onClose={handlePrimaryClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{"Primary Card?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        You cannot remove your primary billing card.<br/>
                        Please select, or enter another card as your "primary".<br/>
                        You may then remove this card.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <MDButton onClick={handlePrimaryClose} variant="outlined" color="info">OK</MDButton>
                </DialogActions>
            </Dialog>
            <Dialog
                open={cardAlreadyPrimary}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleAlreadyPrimaryClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{"Primary Card?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        This card is already set as your primary billing card.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <MDButton onClick={handleAlreadyPrimaryClose} variant="outlined" color="info">OK</MDButton>
                </DialogActions>
            </Dialog>
            <Dialog
                open={addCardOpen}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleAddClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{"Add Payment Card"}</DialogTitle>
                <DialogContent>

                    <FormField
                        style={{width: '100%', marginTop: "5px", marginBottom: "5px"}}
                        label="Nick Name Your Card"
                        placeholder="John's Debit Card"
                        onKeyUp={(e) => setCardNick(e.target.value)}
                    /><br/>
                    <PaymentInputsWrapper {...wrapperProps} >
                        <svg {...getCardImageProps({images})} />
                        <input {...getCardNumberProps({onChange: handleNumChange})} />
                        <input {...getExpiryDateProps({onChange: handleExpChange})}/>
                        <input {...getCVCProps({onChange: handleCvnChange})} />
                    </PaymentInputsWrapper><br/>
                    <span style={{
                        color: "#FF0000",
                        fontSize: "12px",
                        fontWeight: "bold"
                    }}>&nbsp;{(meta.error) ? meta.error : <></>}</span>
                </DialogContent>
                {(!meta.error && cardNumber && cardExp && cardCvn && cardNick) ?
                    <DialogActions>
                        {
                            (newCardLoading ?
                                    <CircularProgress color="info" size={40}/>
                                    :
                                    <MDButton color="success" onClick={addNewCard}>Add Card</MDButton>
                            )
                        }
                    </DialogActions>
                    :
                    <DialogActions>
                        {
                            (newCardLoading ?
                                    <CircularProgress color="info" size={40}/>
                                    :
                                    <MDButton variant="outlined" color="info" disabled>Add Card</MDButton>
                            )
                        }
                    </DialogActions>
                }
                {/*{cardNick}<br/>*/}
                {/*{cardNumber}<br/>*/}
                {/*{cardExp}<br/>*/}
                {/*{cardCvn}<br/>*/}


            </Dialog>
        </DashboardLayout>
    );
}

export default Billing;

