import React, {useState, useEffect} from 'react';
import Stepper from "react-stepper-horizontal";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import {apiUrl, chk} from "App";
import api from "utils/axiosConfig";
import {useSnackbar} from "notistack";
import Typography from "@mui/material/Typography";
import TextField from '@mui/material/TextField';
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Icon from "@mui/material/Icon";
import Cookies from 'universal-cookie';
const cookies = new Cookies();

function Finished({setActiveStep, emailAddr, password}) {

    const [isLoading, setIsLoading] = useState(true)
    const [finishLoading, setFinishLoading] = useState(false)


    useEffect(() => {

        const ck = chk();
        const session = ck.session;
        const cs = ck.cs;

        api.post(apiUrl() + "api/v2/completeSetup/finished/", {cs: cs, session: session, emailAddr: emailAddr, password: password}).then(response => {
            if (response.data.status === 'ok') {
                setIsLoading(false);

            } else {
                const message = "Could not complete setup.  --  Please contact sales or support";
                const variant = "error";
                enqueueSnackbar(message, {variant});
                setIsLoading(false);
                setActiveStep(-2);
            }

        }).then(response => {

        });
    }, []);

    function handleFinish(){
        setFinishLoading(true)
        cookies.remove('sa', {path: '/'});
        window.location.reload(false);
    }

    if (isLoading) {
        return (
            <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
            >

                <Grid item xs={3}>
                    <CircularProgress color="info" size={120}/>
                </Grid>

            </Grid>
        );
    }

    return (
        <div style={{fontFamily: "Arial"}}>
            {/*<pre>*/}
            {/*     {JSON.stringify(contacts, null, 2)}*/}
            {/*</pre>*/}

            <br/>
            <Card style={{padding:10}}>
                <Box sx={{width:"100%", textAlign:"center"}}>
                <Typography sx={{fontSize:"22px"}}> Welcome to YourWebPro! </Typography>
                </Box>
                <br />
                <Typography sx={{fontSize:"12px;"}}>
                    <i>Exciting News!</i> Your brand-new website is currently in the process of being set up. Please allow approximately 20 minutes for the system to install your security certification (SSL).
<br />
<br />
                    While you wait, we've sent a couple of emails to <b>zak@yourwebpro.com</b> for you to explore.
                    <br />
                    <br />
                    Make sure to thoroughly review our <b>Welcome Letter</b>. It contains crucial information about YourWebPro and your account with us.
                    <br />
                    <br />
                    Wondering what steps to take now that you have your website? Dive into the <b>What's Next</b> email, which provides valuable tips on what to do next and how to effectively manage your new website.
                </Typography>
                <br />
                <Box>
                    {
                        (finishLoading ?
                                <CircularProgress color="info" size={40}/>
                                :
                                <Button style={{backgroundColor: "#165dbf", color: "#FFF"}}
                                        onClick={handleFinish}>
                                    <Icon sx={{fontWeight: "bold"}}>check</Icon>
                                    &nbsp;continue to contractor account
                                </Button>
                        )
                    }

                </Box>
            </Card>
        </div>
    );
}

export default Finished;