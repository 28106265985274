/**
 =================================
 YourWebPro Contractor Portal Application V2
 NodeJS  |  React  |  MaterialUI  |  Material Dash 2
 2022 / 2023
 =================================
 ==========================================================
 Main components such as charts, boxes, sidenav, dash found in /components directory

 Layouts such at Dash, Profile, MapYourProjects, Billing are located in /layouts

 Theme files for colors and base layout definitions found in /assets/theme
 ==========================================================
 */

// @material-ui core components


// Material Dashboard 2 PRO React components

import colors from "assets/theme/base/colors"

const ywpBlue = colors.ywpBlue.main;
const ywpWhite = colors.ywpWhite.main;

// Settings page components

// Data

import MDButton from "components/MDButton";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import React, {useState} from "react";
import MDBox from "components/MDBox";
import Grid from "@mui/material/Grid";
import MDInput from "components/MDInput";
import Card from "@mui/material/Card";
import Autocomplete from "react-google-autocomplete";
import {geocodeByPlaceId, getLatLng} from 'react-google-places-autocomplete';

// AIzaSyAm6IvgoLeMzCHtTpH_IV6tU1eSv7Wuvnk


function Address({
                     handleNext,
                     handleBack,
                     GAroute,
                     setGAroute,
                     GAstreet,
                     setGAstreet,
                     GAcity,
                     setGAcity,
                     GAstate,
                     setGAstate,
                     GAzip,
                     setGAzip,
                     GAcountry,
                     setGAcountry,
                     GALat,
                     setGALat,
                     GALon,
                     setGALon,
                 }) {

    const [GAFocus, setGAFocus] = useState('1px solid #DDD');


    function setLatLon(ll) {
        const lat = ll['lat'];
        const lng = ll['lng'];
        setGALat(lat);
        setGALon(lng);
    }


    function handlePlace(place) {
        console.log(place);
        geocodeByPlaceId(place.place_id)
            .then(results => getLatLng(results[0]))
            .then(({lat, lng}) =>
                setLatLon({lat, lng})
            );

        console.log(place.address_components);
        for (let i = 0; i < place.address_components.length; i++) {
            const type = place.address_components[i].types[0];


            switch (type) {
                case 'street_number':
                    // console.log(place.address_components[i]['long_name']);
                    setGAstreet(place.address_components[i]['long_name'])
                    break;
                case 'route':
                    // console.log(place.address_components[i]);
                    setGAroute(place.address_components[i]['long_name'])
                    break;
                case 'locality':
                    // console.log(place.address_components[i]);
                    setGAcity(place.address_components[i]['long_name'])
                    break;
                case 'administrative_area_level_1':
                    // console.log(place.address_components[i]);
                    // setGAstate('WA')
                    setGAstate(place.address_components[i]['short_name'])
                    break;
                case 'country':
                    // console.log(place.address_components[i]);
                    setGAcountry(place.address_components[i]['long_name'])
                    break;
                case 'postal_code':
                    // console.log(place.address_components[i]);
                    setGAzip(place.address_components[i]['long_name'])
                    break;
                default:
                // code block
            }


        }


    }


    return (
        <Paper square elevation={0} sx={{p: 3}}>
            <Typography m={2}>Address</Typography>
            <Card id="project-info" sx={{overflow: "visible"}}>

                <MDBox component="form" pb={3} px={3}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12}>
                            <Autocomplete
                                // My Attempt to turn the background color on focus ..
                                // It "works" But Google's API overwrites it ..  I am
                                // leaving it here in case we figure out a work around.
                                onBlur={() => setGAFocus('1px solid #DDD')}
                                onFocus={() => setGAFocus('1px solid #FF0000')}
                                style={{
                                    width: "100%",
                                    height: "45px",
                                    borderRadius: "5px",
                                    border: GAFocus,
                                    paddingLeft: "15px",

                                }}
                                apiKey='AIzaSyAm6IvgoLeMzCHtTpH_IV6tU1eSv7Wuvnk'
                                onPlaceSelected={(place) => handlePlace(place)}
                                options={{
                                    types: ["geocode", "establishment"],
                                }}
                            />

                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <MDInput
                                fullWidth
                                label="Street Number"
                                inputProps={{type: "text", autoComplete: ""}}
                                value={GAstreet}
                                disabled={true}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <MDInput
                                fullWidth
                                label="Route"
                                inputProps={{type: "text", autoComplete: ""}}
                                value={GAroute}
                                disabled={true}
                            />
                        </Grid>

                        <Grid item xs={12} sm={3}>
                            <MDInput
                                fullWidth
                                label="City"
                                inputProps={{type: "text", autoComplete: ""}}
                                value={GAcity}
                                disabled={true}
                            />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <MDInput
                                fullWidth
                                label="State"
                                inputProps={{type: "text", autoComplete: ""}}
                                value={GAstate}
                                disabled={true}
                            />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <MDInput
                                fullWidth
                                label="Zip"
                                inputProps={{type: "text", autoComplete: ""}}
                                value={GAzip}
                                disabled={true}
                            />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <MDInput
                                fullWidth
                                label="Country"
                                inputProps={{type: "text", autoComplete: ""}}
                                value={GAcountry}
                                disabled={true}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <MDInput
                                fullWidth
                                label="Latitude"
                                value={GALat}
                                inputProps={{type: "text", autoComplete: ""}}
                                disabled={true}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <MDInput
                                fullWidth
                                label="Longitude"
                                value={GALon}
                                inputProps={{type: "text", autoComplete: ""}}
                                disabled={true}
                            />
                        </Grid>

                    </Grid>
                </MDBox>
            </Card>


            <Box p={2} mt={2} sx={{
                borderTop: "1px solid #DDD",
                height: "60px"
            }}>
                <MDButton variant="outlined" color="info" size="medium" sx={{float: "left"}} onClick={handleBack}>
                    PREV
                </MDButton>
                <MDButton variant="outlined" color="info" size="medium" sx={{float: "right"}} onClick={handleNext}>
                    NEXT
                </MDButton>
            </Box>

        </Paper>
    );
}

export default Address;
