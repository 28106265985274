import React, {useEffect, useState} from "react";
import Box from "@mui/material/Box";

import colors from "assets/theme/base/colors";
import {TrafficSource} from "../../../TrafficSource";

const ywpBlue = colors.ywpBlue.main;
const ywpDarkBlue = colors.ywpBlue.focus;

const Local = () => {

    return (
        <Box mt={4} p={2} sx={{flexGrow: 1}}>
            Local
            <TrafficSource/>
        </Box>
    );
};

export default Local;
