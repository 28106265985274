import React from 'react'
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";


function VisitorTools() {

  const [range, setRange] = React.useState('');

  const handleChange = (event) => {
    setRange(event.target.value);
  };


  return (
    <div>VisitorTools
      <FormControl fullWidth sx={{marginTop: "10px"}}>
        <InputLabel  id="date-range-label">Browser Type</InputLabel>
        <Select
          labelId="date-range-label"
          id="date-range"
          value={range}
          label="Date Range"
          onChange={handleChange}
        >
          <MenuItem value={10}>Ten</MenuItem>
          <MenuItem value={20}>Twenty</MenuItem>
          <MenuItem value={30}>Thirty</MenuItem>
        </Select>
      </FormControl>
    </div>
  )
}

export default VisitorTools