/**
 =================================
 YourWebPro Contractor Portal Application V2
 NodeJS  |  React  |  MaterialUI  |  Material Dash 2
 2022 / 2023
 =================================
 ==========================================================
 Main components such as charts, boxes, sidenav, dash found in /components directory

 Layouts such at Dash, Profile, MapYourProjects, Billing are located in /layouts

 Theme files for colors and base layout definitions found in /assets/theme
 ==========================================================
 */

import Grid from "@mui/material/Grid";
import React, {useEffect, useState} from "react";
import {apiUrl, chk} from "App";
import api from "utils/axiosConfig";
import {useSnackbar} from "notistack";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import DataTable from "components/Tables/DataTable";
import Link from "@mui/material/Link";
import Checkbox from "@mui/material/Checkbox";
import Icon from "@mui/material/Icon";
import colors from "assets/theme/base/colors";
import FormField from "components/FormField";
import Autocomplete from "react-google-autocomplete";
import {geocodeByPlaceId, getLatLng} from 'react-google-places-autocomplete';
import MDInput from "components/MDInput";
import HoursSelect from "layouts/gmb/components/Hours"
import InputMask from "react-input-mask";
import TextField from "@mui/material/TextField";
import Posts from "layouts/gmb/components/Posts";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import Select from 'react-select';
import Dropzone from 'react-dropzone-uploader'

const text = colors.text.main;
const ywpBlue = colors.ywpBlue.main;

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function TabPanel(props: TabPanelProps) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </div>
    );
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}


export default function ManageGoogle({info, posts, media}) {
    if (!info || !posts || !media) {
        return (
            // Cursory check .. React may not be finished loading "info"
            <>Nothing To See Here</>
        )
    }
    const [value, setValue] = React.useState(0);

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };


    const google_name = info.google_stored.name;
    const google_email = info.google_stored.email;
    const google_picture = info.google_stored.google_picture;

    const title = info.default_location.title;
    const phone = info.default_location.phoneNumbers.primaryPhone;
    const primary_category = info.default_location.categories.primaryCategory.displayName;
    const addl_categories = info.default_location.categories.additionalCategories;
    const service_types = info.default_location.categories.primaryCategory.serviceTypes;

    const addr = info.default_location.storefrontAddress.addressLines[0];
    const city = info.default_location.storefrontAddress.locality;
    const state = info.default_location.storefrontAddress.administrativeArea;
    const zip = info.default_location.storefrontAddress.postalCode;
    const placeId = info.default_location.metadata.placeId;
    let new_posts_arr = [];

    if (posts.posts) {
        let posts_array = posts.posts.localPosts;
        for (let i = 0; i < posts_array.length; i++) {

            let temp_obj = {};
            temp_obj.title = (posts_array[i].summary) ? posts_array[i].summary.substring(0,55) + ' ... ' : <>N/A</>;
            temp_obj.posted = posts_array[i].createTime;
            temp_obj.link = <Link href={posts_array[i].searchUrl} target="_blank"> <Icon fontSize="large" style={{cursor: "pointer"}} color="success">visibility</Icon></Link>
            temp_obj.type = (posts_array[i].callToAction) ? posts_array[i].callToAction.actionType : 'NA';
            temp_obj.status = posts_array[i].state;
            temp_obj.topic = posts_array[i].topicType;
            temp_obj.actions = <div><Icon fontSize="large" style={{cursor: "pointer"}} color="info">edit</Icon><Icon fontSize="large" style={{cursor: "pointer"}} color="error" onClick={(e) => setDeletePost(e, posts_array[i]['name'])}>delete</Icon></div>;
            new_posts_arr.push(temp_obj);
        }

    } else {
// Something if no posts
    }

    const [tableHeaderData, setTableHeaderData] = useState([
        {Header: "Title Summary", accessor: "title"},
        {Header: "date posted", accessor: "posted"},
        {Header: "direct link", accessor: "link"},
        {Header: "type", accessor: "type"},
        {Header: "status", accessor: "status"},
        {Header: "topic", accessor: "topic"},
        {Header: "actions", accessor: "actions"},
    ]);

    const [tableRowData, setTableRowData] = useState(new_posts_arr);


    // const dataTableData = {
    //     columns: [
    //         {Header: "Title Summary", accessor: "title"},
    //         {Header: "date posted", accessor: "posted"},
    //         {Header: "direct link", accessor: "link"},
    //         {Header: "type", accessor: "type"},
    //         {Header: "status", accessor: "status"},
    //         {Header: "topic", accessor: "topic"},
    //         {Header: "actions", accessor: "actions"},
    //     ],
    //
    //     rows: new_posts_arr,
    // };


    const setDeletePost = (e, name) => {

        setDeletePostName(name);
        setOpenDeletePost(true);
    };
    const setDeletePhoto = (e, name) => {
        setDeletePhotoName(name);
        setOpenDeletePhoto(true);
    };

    function handleDeletePhoto() {
        setDeletePhotoLoader(true);
        const ck = chk();
        const session = ck.session;
        const cs = ck.cs;
        api.post(apiUrl() + "api/v2/gmb/delete/media/", {cs: cs, session: session, delete: deletePhotoName}).then(response => {
            if (response.data.status === 'ok') {
                const message = "Successfully Removed Media Item";
                const variant = "success";
                enqueueSnackbar(message, {variant});
                const mediaResponse = buildMedia(response.data);
                setMediaData(mediaResponse);
            } else {
                const message = "There was an error removing your media item";
                const variant = "error";
                enqueueSnackbar(message, {variant});
            }
        }).then(response => {
            setOpenDeletePhoto(false);
            setDeletePhotoLoader(false);
        });
    }


    function handleDeletePost() {
        setDeletePostLoader(true);
        const ck = chk();
        const session = ck.session;
        const cs = ck.cs;
        api.post(apiUrl() + "api/v2/gmb/delete/post/", {cs: cs, session: session, delete: deletePostName}).then(response => {
            if (response.data.status === 'ok') {
                const message = "Successfully Removed Post";
                const variant = "success";
                enqueueSnackbar(message, {variant});

                let new_posts_arr = [];
                let posts_array = response.data.posts.localPosts;
                for (let i = 0; i < posts_array.length; i++) {

                    let temp_obj = {};
                    temp_obj.title = posts_array[i].summary.substring(0,55) + ' ... ';
                    temp_obj.posted = posts_array[i].createTime;
                    temp_obj.link = <Link href={posts_array[i].searchUrl} target="_blank"> <Icon fontSize="large" style={{cursor: "pointer"}} color="success">visibility</Icon></Link>
                    temp_obj.type = posts_array[i].callToAction.actionType;
                    temp_obj.status = posts_array[i].state;
                    temp_obj.topic = posts_array[i].topicType;
                    temp_obj.actions = <div><Icon fontSize="large" style={{cursor: "pointer"}} color="info">edit</Icon><Icon fontSize="large" style={{cursor: "pointer"}} color="error" onClick={(e) => setDeletePost(e, posts_array[i]['name'])}>delete</Icon></div>;
                    new_posts_arr.push(temp_obj);
                }
                setTableRowData(new_posts_arr);


            } else {
                const message = "There was an error removing your post";
                const variant = "error";
                enqueueSnackbar(message, {variant});
            }
        }).then(response => {
            setOpenDeletePost(false);
            setDeletePostLoader(false);
        });
    }


    let mediaBuild = "";

    if (media.media) {
        mediaBuild = buildMedia(media);
    }

    function buildMedia(media) {
        console.log (media)


        return media.media.mediaItems.map((item, index) =>
            <Grid item sx={{
                width: {xs: 400, md: 250},
                border: "1px solid #BBB",
                marginBottom: 4,
                padding: 2,
                marginLeft: 4
            }}
                  key={index}
                  id={item.name}>

                <Link href={"https://www.google.com/maps/search/?api=1&query=Google&query_place_id=" + placeId} target={'_blank'}>
                <Box
                    component="div"
                    sx={{
                        width: {xs: 350, md: 200},
                        height: {xs: 350, md: 200},
                        background: "url('" + item.googleUrl + "')",
                        backgroundSize: "contain",
                        backgroundRepeat: 'no-repeat',
                        fontSize: "40px",
                        border: "2px solid #DDD"
                    }}
                />
                </Link>

                <Box>
                    <Typography variant="h5"
                                gutterBottom
                                sx={{
                                    color: {ywpBlue},
                                }}
                    >
                        {item.locationAssociation.category}
                    </Typography>
                </Box>
                <Box>
                    <Typography variant="h6" gutterBottom sx={{color: text}}>
                        {item.createTime}
                    </Typography>
                </Box>
                <Box>
                    <Typography gutterBottom sx={{color: text, fontSize: "14px"}}>
                        W: {item.dimensions.widthPixels}px&nbsp;&nbsp;&nbsp;&nbsp;H: {item.dimensions.heightPixels}px
                    </Typography>
                </Box>
                <Box>
                    <Typography gutterBottom sx={{color: text, fontSize: "14px"}}>
                        Viewed {(item.insights && item.insights.viewCount) ? item.insights.viewCount : "0"} times

                    </Typography>
                </Box>


                <Box>
                    <MDButton variant="outlined" style={{float: "right"}} color="error" size="small" onClick={(e) => setDeletePhoto(e, item.name)}>
                        Delete
                    </MDButton>
                </Box>
            </Grid>
        );
    }
    const [mediaData, setMediaData] = useState(mediaBuild);

    const [GAroute, setGAroute] = useState('');
    const [GAstreet, setGAstreet] = useState('');
    const [GAcity, setGAcity] = useState('');
    const [GAstate, setGAstate] = useState('');
    const [GAzip, setGAzip] = useState('');
    const [GAzipSuffix, setGAzipSuffix] = useState('');
    const [GAcountry, setGAcountry] = useState('');
    const [GALat, setGALat] = useState('');
    const [GALon, setGALon] = useState('');
    const [GAFocus, setGAFocus] = useState('1px solid #DDD');
    const [deletePostName, setDeletePostName] = useState('');
    const [deletePhotoName, setDeletePhotoName] = useState('');
    const [mediaLoading, setMediaLoading] = useState(false);

    function setLatLon(ll) {
        const lat = ll['lat'];
        const lng = ll['lng'];
        setGALat(lat);
        setGALon(lng);
    }

    function updateTitle() {
        const ck = chk();
        const session = ck.session;
        const cs = ck.cs;
        setBuildNameLoading(true);
        api.post(apiUrl() + "api/v2/gmb/update/title/", {cs: cs, session: session, title: businessName}).then(response => {
            if (response.data.status === 'ok') {
                const message = "Successfully Updated Business Name";
                const variant = "success";
                enqueueSnackbar(message, {variant});
            } else {
                const message = "There was an error updating Business Name";
                const variant = "error";
                enqueueSnackbar(message, {variant});
            }
        }).then(response => {
            setBuildNameLoading(false);
        });
    }

    function updatePhone() {
        const ck = chk();
        const session = ck.session;
        const cs = ck.cs;
        setBuildPhoneLoading(true);
        api.post(apiUrl() + "api/v2/gmb/update/phone/", {cs: cs, session: session, phone: businessPhone}).then(response => {
            if (response.data.status === 'ok') {
                const message = "Successfully Updated Business Phone";
                const variant = "success";
                enqueueSnackbar(message, {variant});
            } else {
                const message = "There was an error updating Business Phone";
                const variant = "error";
                enqueueSnackbar(message, {variant});
            }
        }).then(response => {
            setBuildPhoneLoading(false);
        });
    }

    function updateHours() {
        const ck = chk();
        const session = ck.session;
        const cs = ck.cs;
        setBuildHoursLoading(true);
        api.post(apiUrl() + "api/v2/gmb/update/hours/", {cs: cs, session: session, hours: buildHours}).then(response => {
            if (response.data.status === 'ok') {
                const message = "Successfully Updated Business Hours";
                const variant = "success";
                enqueueSnackbar(message, {variant});
            } else {
                const message = "There was an error updating Business Hours";
                const variant = "error";
                enqueueSnackbar(message, {variant});
            }
        }).then(response => {
            setBuildHoursLoading(false);
        });
    }


    function updateAddress() {
        const ck = chk();
        const session = ck.session;
        const cs = ck.cs;
        setBuildAddressLoading(true);


        const address = {};
        address.route = GAroute;
        address.street = GAstreet;
        address.city = GAcity;
        address.state = GAstate;
        address.zip = GAzip + '-' + GAzipSuffix;
        address.country = GAcountry;


        api.post(apiUrl() + "api/v2/gmb/update/address/", {cs: cs, session: session, address: address}).then(response => {
            if (response.data.status === 'ok') {
                const message = "Successfully Updated Business Address";
                const variant = "success";
                enqueueSnackbar(message, {variant});
            } else {
                const message = "There was an error updating Business Address";
                const variant = "error";
                enqueueSnackbar(message, {variant});
            }
        }).then(response => {
            setBuildAddressLoading(false);
        });
    }

    function submitMedia() {
        setMediaLoading(true);
        const ck = chk();
        const session = ck.session;
        const cs = ck.cs;
        setBuildHoursLoading(true);
        api.post(apiUrl() + "api/v2/gmb/set/media/photo/", {cs: cs, session: session, url: fileUrl, category: photoType}).then(response => {
            if (response.data.status === 'ok') {
                const message = "Successfully Added Photo";
                const variant = "success";
                enqueueSnackbar(message, {variant});
                const mediaResponse = buildMedia(response.data);
                setMediaData(mediaResponse);
            } else {
                const message = "There was an error adding your photo";
                const variant = "error";
                enqueueSnackbar(message, {variant});
            }
        }).then(response => {
            handleAddMediaClose();
        });
    }



    function handlePlace(place) {
        geocodeByPlaceId(place.place_id)
            .then(results => getLatLng(results[0]))
            .then(({lat, lng}) =>
                setLatLon({lat, lng})
            );


        for (let i = 0; i < place.address_components.length; i++) {
            const type = place.address_components[i].types[0];


            switch (type) {
                case 'street_number':
                    setGAstreet(place.address_components[i]['long_name'])
                    break;
                case 'route':
                    setGAroute(place.address_components[i]['long_name'])
                    break;
                case 'locality':
                    setGAcity(place.address_components[i]['long_name'])
                    break;
                case 'administrative_area_level_1':
                    setGAstate(place.address_components[i]['long_name'])
                    break;
                case 'country':
                    setGAcountry(place.address_components[i]['long_name'])
                    break;
                case 'postal_code':
                    setGAzip(place.address_components[i]['long_name'])
                    break;
                case 'postal_code_suffix':
                    setGAzipSuffix(place.address_components[i]['long_name'])
                    break;
                default:

            }
        }
    }

    const [buildHours, setBuildHours] = useState(info.default_location.regularHours.periods);
    const [buildHoursLoading, setBuildHoursLoading] = useState(false);
    const [buildNameLoading, setBuildNameLoading] = useState(false);
    const [buildAddressLoading, setBuildAddressLoading] = useState(false);
    const [buildPhoneLoading, setBuildPhoneLoading] = useState(false);
    const {enqueueSnackbar} = useSnackbar();
    const [businessName, setBusinessName] = useState('');
    const [businessPhone, setBusinessPhone] = useState(phone);
    const [openDeletePost, setOpenDeletePost] = useState(false);
    const [openDeletePhoto, setOpenDeletePhoto] = useState(false);
    const [addMediaOpen, setAddMediaOpen] = useState(false);
    const [deletePostLoader, setDeletePostLoader] = React.useState(false);
    const [deletePhotoLoader, setDeletePhotoLoader] = React.useState(false);

    function setTitle(value) {
        setBusinessName(value);
    }

    const setPrimaryPhone = (event) => {
        setBusinessPhone(event.target.value);
    }

    const SUNDAY = info.default_location.regularHours.periods.filter(item => item.openDay.includes('SUNDAY'))[0];
    const MONDAY = info.default_location.regularHours.periods.filter(item => item.openDay.includes('MONDAY'))[0];
    const TUESDAY = info.default_location.regularHours.periods.filter(item => item.openDay.includes('TUESDAY'))[0];
    const WEDNESDAY = info.default_location.regularHours.periods.filter(item => item.openDay.includes('WEDNESDAY'))[0];
    const THURSDAY = info.default_location.regularHours.periods.filter(item => item.openDay.includes('THURSDAY'))[0];
    const FRIDAY = info.default_location.regularHours.periods.filter(item => item.openDay.includes('FRIDAY'))[0];
    const SATURDAY = info.default_location.regularHours.periods.filter(item => item.openDay.includes('SATURDAY'))[0];

    const handleCloseDeletePost = () => {
        setOpenDeletePost(false);
    };
    const handleCloseDeletePhoto = () => {
        setOpenDeletePhoto(false);
    };
    const handleAddMediaClose = () => {
        setAddMediaOpen(false);
        setFileUrl('');
        setMediaLoading(false);
    };
    const handleAddMediaOpen = () => {
        setAddMediaOpen(true);
    };

    const getUploadParams = ({file, meta}) => {
        const ck = chk();
        const session = ck.session;
        const cs = ck.cs;
        const url = apiUrl() + 'api/v2/gmb/upload/photo/'
        // return {
        //     url, meta: {
        //         fileUrl:  `${url}/${encodeURIComponent(meta.name)}`,
        //
        //     },cs, session
        // }
        const body = new FormData()
        body.append('fileField', file)
        body.append('cs', cs)
        body.append('session', session)
        return {url: url, body}
    }
    const [fileName, setFileName] = React.useState(false);
    const [fileUrl, setFileUrl] = React.useState(false);

    const handlePhotoChangeStatus = ({meta, file, xhr}, status) => {
        if (status === 'done') {
            const file = JSON.parse(xhr.response)

            setFileName(file.file.name);
            setFileUrl(file.file.url);
        }
    }

    const PhotosLayout = ({input, previews, submitButton, dropzoneProps, files, extra: {maxFiles}}) => {
        return (
            <div>
                {previews}

                <div {...dropzoneProps} style={{border: "5px dashed #DDD", overflow: "auto", marginTop: "20px"}}>
                    {files.length < maxFiles && input}
                </div>

                {files.length > 0 && submitButton}
            </div>
        )
    }

    const photosPreview = ({meta, files}) => {
        const {name, percent, status, previewUrl, size} = meta
        let fileSize = Math.round(size / 1024);

        return (

            <Box className={"preview-images-small"}>
                <div>
                    <img style={{height: "120px", maxWidth: "180px"}} src={previewUrl} alt={name + ", " + size + "kb"} title={name + ", " + size + "kb"}/>
                </div>
                <div>
                    <span style={{fontSize: "14px"}}>{name}</span>
                    <progress max="100" value={percent} style={{width: "120px", marginRight: "20px", marginBottom: "5px"}}></progress>
                    <Icon sx={{color: "#FF0000", cursor: "pointer"}} title="Delete Selected">cancel</Icon>
                </div>
            </Box>

        )
    }

    const types = [
        {value: 'ADDITIONAL', label: 'Uncategorized'},
        {value: 'COVER', label: 'Cover (Will replace current Cover)'},
        {value: 'PROFILE', label: 'Profile (Will replace current Cover) '},
        {value: 'LOGO', label: 'Logo'},
        {value: 'EXTERIOR', label: 'Exteriors'},
        {value: 'INTERIOR', label: 'Interiors'},
        {value: 'PRODUCT', label: 'Products'},
        {value: 'AT_WORK', label: 'At Work'},
        {value: 'FOOD_AND_DRINK', label: 'Food And Drink'},
        {value: 'MENU', label: 'Food Menu'},
        {value: 'COMMON_AREA', label: 'Common Area'},
        {value: 'ROOMS', label: 'Rooms'},
        {value: 'TEAMS', label: 'Team Photos'},

    ];
    const [photoType, setPhotoType] = React.useState('ADDITIONAL');

    function handleChangeLinkType(e) {
        setPhotoType(e.value);
    }


    return (

        <MDBox>
            <Tabs value={value} onChange={handleTabChange} aria-label="gmb tabs">
                <Tab label="Basic info" {...a11yProps(0)} />
                <Tab label="Posts / Updates" {...a11yProps(1)} />
                <Tab label="Google Photos" {...a11yProps(2)} />

            </Tabs>
            <TabPanel value={value} index={0}>
                <Grid
                    container
                    direction="row"
                    // justifyContent="space-between"
                    // alignItems="center"
                    spacing={3}
                    style={{marginTop: "20px"}}
                >

                    <Grid item sx={{
                        // width: {xs: 400, md: 250},
                        backgroundColor: "#FFF",
                        marginBottom: 4,
                        padding: 2,
                        marginLeft: 4,
                        borderRadius: "10px"
                    }}
                          sm={4}
                    >
                        <Box style={{textAlign: "center", marginBottom: "20px", borderBottom: "1px solid #DDD"}}>
                            <Typography variant="h5"
                                        gutterBottom
                                        sx={{
                                            color: {ywpBlue},
                                        }}
                            >
                                OVERVIEW
                            </Typography>
                        </Box>

                        <Box sx={{fontSize: "16px"}}>
                            <img alt="Google Image" src={google_picture}/><br/>
                            <b>Account:</b> {google_name}<br/>
                            <b>Email:</b> {google_email}<br/>
                            <b>Business:</b> {title}<br/>
                            <b>Phone:</b> {phone}<br/>
                            <b>Primary Category:</b> {primary_category}<br/>
                            <b>Address:</b> <br/>
                            {addr}<br/>
                            {city}<br/>
                            {state}<br/>
                            {zip}<br/>

                            <br/>
                            <hr/>
                            <br/>
                            <b>Addl Categories:</b><br/>
                            {(addl_categories) ? addl_categories.map((item, index) => (
                                <div key={index}>&nbsp;&nbsp;&nbsp;* <b>Category:</b> {item.displayName}</div>
                            )) : <></>}
                            <br/>
                            <hr/>
                            <br/>
                            <b>Services:</b><br/>
                            {(service_types) ? service_types.map((item, index) => (
                                <div key={index}>&nbsp;&nbsp;&nbsp;* <b>Service:</b> {item.displayName}</div>
                            )) : <></>}


                            <pre>
            {/*{JSON.stringify(info, null, 2)}*/}
             </pre>
                        </Box>


                    </Grid>


                    <Grid item sx={{
                        // width: {xs: 400, md: 250},
                        backgroundColor: "#FFF",
                        marginBottom: 4,
                        padding: 2,
                        marginLeft: 4,
                        borderRadius: "10px"
                    }}
                          sm={4}
                    >
                        <Box style={{textAlign: "center", marginBottom: "20px", borderBottom: "1px solid #DDD"}}>
                            <Typography variant="h5"
                                        gutterBottom
                                        sx={{
                                            color: {ywpBlue},
                                        }}
                            >
                                MANAGE
                            </Typography>
                        </Box>
                        <Box style={{marginBottom: "20px"}}>
                            <Grid
                                container
                                direction="row"
                                // justifyContent="space-between"
                                // alignItems="center"
                                spacing={3}
                            >
                                <Grid item xs={12} sm={10}>
                                    <FormField
                                        label="Business"
                                        placeholder="Business Name"
                                        defaultValue={title}
                                        onKeyUp={(e) => setTitle(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={2}>

                                    {(buildNameLoading) ?
                                        <CircularProgress sx={{float: "right", marginRight: "20px"}} color="info" size={40}/>
                                        :
                                        <Icon
                                            fontSize="large"
                                            style={{cursor: "pointer"}}
                                            color="success"
                                            onClick={updateTitle}>save</Icon>
                                    }


                                </Grid>
                            </Grid>
                        </Box>

                        <Box style={{marginBottom: "20px"}}>
                            <Grid
                                container
                                direction="row"
                                // justifyContent="space-between"
                                // alignItems="center"
                                spacing={3}
                            >
                                <Grid item xs={12} sm={10}>
                                    <InputMask
                                        label="Business Phone"
                                        placeholder="(555) 555-5555"
                                        mask="(999) 999-9999"
                                        value={businessPhone}
                                        onChange={setPrimaryPhone}
                                    >
                                        {(inputProps) =>
                                            <FormField {...inputProps}/>
                                        }
                                    </InputMask>


                                </Grid>
                                <Grid item xs={12} sm={2}>
                                    {(buildPhoneLoading) ?
                                        <CircularProgress sx={{float: "right", marginRight: "20px"}} color="info" size={40}/>
                                        :
                                        <Icon fontSize="large"
                                              onClick={updatePhone}
                                              style={{cursor: "pointer"}}
                                              color="success">save</Icon>
                                    }
                                </Grid>
                            </Grid>
                        </Box>
                        <Box style={{textAlign: "center"}}>
                            Manage Business Address
                        </Box>
                        <Autocomplete
                            // My Attempt to turn the background color on focus ..
                            // It "works" But Google's API overwrites it ..  I am
                            // leaving it here in case we figure out a work around.
                            onBlur={() => setGAFocus('1px solid #DDD')}
                            onFocus={() => setGAFocus('1px solid #FF0000')}
                            style={{
                                width: "100%",
                                height: "45px",
                                borderRadius: "5px",
                                border: {GAFocus},
                                paddingLeft: "15px",

                            }}
                            apiKey='AIzaSyAm6IvgoLeMzCHtTpH_IV6tU1eSv7Wuvnk'
                            onPlaceSelected={(place) => handlePlace(place)}
                            options={{
                                types: ["geocode", "establishment"],
                            }}
                        />
                        <Grid
                            container
                            direction="row"
                            // justifyContent="space-between"
                            // alignItems="center"
                            spacing={3}
                            style={{marginTop: "20px"}}
                        >
                            <Grid item xs={12} sm={6}>
                                <FormField
                                    label="Street Number"
                                    inputProps={{type: "text", autoComplete: ""}}
                                    value={GAstreet}
                                    disabled={true}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormField

                                    label="Route"
                                    inputProps={{type: "text", autoComplete: ""}}
                                    value={GAroute}
                                    disabled={true}
                                />
                            </Grid>

                            <Grid item xs={12} sm={3}>
                                <FormField

                                    label="City"
                                    inputProps={{type: "text", autoComplete: ""}}
                                    value={GAcity}
                                    disabled={true}
                                />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <FormField

                                    label="State"
                                    inputProps={{type: "text", autoComplete: ""}}
                                    value={GAstate}
                                    disabled={true}
                                />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <FormField
                                    label="Zip"
                                    inputProps={{type: "text", autoComplete: ""}}
                                    value={GAzip + "-" + GAzipSuffix}
                                    disabled={true}
                                />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <FormField

                                    label="Country"
                                    inputProps={{type: "text", autoComplete: ""}}
                                    value={GAcountry}
                                    disabled={true}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormField

                                    label="Latitude"
                                    value={GALat}
                                    inputProps={{type: "text", autoComplete: ""}}
                                    disabled={true}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormField

                                    label="Longitude"
                                    value={GALon}
                                    inputProps={{type: "text", autoComplete: ""}}
                                    disabled={true}
                                />
                            </Grid>
                        </Grid>
                        <Box sx={{height: "80px", borderBottom: "1px solid #DDD"}}>

                            {(buildAddressLoading) ?
                                <CircularProgress sx={{float: "right", marginTop: "20px"}} color="info" size={40}/>
                                :
                                <MDButton
                                    size="small"
                                    color="success"
                                    onClick={updateAddress}
                                    style={{
                                        float: "right",
                                        marginTop: "20px"
                                    }}>Update Address</MDButton>
                            }


                        </Box>
                        <Box>
                            <Box sx={{borderBottom: "1px solid #EEE"}} p={2}>
                                <Typography>SUNDAY</Typography>
                                <HoursSelect day={SUNDAY} pass={"SUNDAY"}
                                             buildHours={buildHours}
                                             setBuildHours={setBuildHours}
                                />
                            </Box>
                            <Box sx={{borderBottom: "1px solid #EEE"}} p={2}>
                                <Typography>MONDAY</Typography>
                                <HoursSelect day={MONDAY} pass={"MONDAY"}
                                             buildHours={buildHours}
                                             setBuildHours={setBuildHours}
                                />
                            </Box>
                            <Box sx={{borderBottom: "1px solid #EEE"}} p={2}>
                                <Typography>TUESDAY</Typography>
                                <HoursSelect day={TUESDAY} pass={"TUESDAY"}
                                             buildHours={buildHours}
                                             setBuildHours={setBuildHours}
                                />
                            </Box>
                            <Box sx={{borderBottom: "1px solid #EEE"}} p={2}>
                                <Typography>WEDNESDAY</Typography>
                                <HoursSelect day={WEDNESDAY} pass={"WEDNESDAY"}
                                             buildHours={buildHours}
                                             setBuildHours={setBuildHours}
                                />
                            </Box>
                            <Box sx={{borderBottom: "1px solid #EEE"}} p={2}>
                                <Typography>THURSDAY</Typography>
                                <HoursSelect day={THURSDAY} pass={"THURSDAY"}
                                             buildHours={buildHours}
                                             setBuildHours={setBuildHours}
                                />
                            </Box>
                            <Box sx={{borderBottom: "1px solid #EEE"}} p={2}>
                                <Typography>FRIDAY</Typography>
                                <HoursSelect day={FRIDAY} pass={"FRIDAY"}
                                             buildHours={buildHours}
                                             setBuildHours={setBuildHours}
                                />
                            </Box>
                            <Box sx={{borderBottom: "1px solid #EEE"}} p={2}>
                                <Typography>SATURDAY</Typography>
                                <HoursSelect day={SATURDAY} pass={"SATURDAY"}
                                             buildHours={buildHours}
                                             setBuildHours={setBuildHours}
                                />
                            </Box>
                            {/*{JSON.stringify(SUNDAY, null, 2)}*/}
                            <Box sx={{height: "80px", borderBottom: "1px solid #DDD"}}>
                                {(buildHoursLoading) ?
                                    <CircularProgress sx={{float: "right", marginTop: "20px"}} color="info" size={40}/>
                                    :
                                    <MDButton size="small" color="success" style={{float: "right", marginTop: "20px"}} onClick={updateHours}>Update Hours</MDButton>
                                }
                            </Box>
                            {/* <pre>*/}
                            {/*    {JSON.stringify(info.default_location.regularHours.periods, null, 2)}*/}
                            {/*</pre>*/}
                            {/* <pre>*/}
                            {/*    {JSON.stringify(buildHours, null, 2)}*/}
                            {/*</pre>*/}
                        </Box>


                    </Grid>


                </Grid>


            </TabPanel>
            <TabPanel value={value} index={1}>
                <Posts
                    tableHeaderData={tableHeaderData}
                    tableRowData={tableRowData}
                    setTableRowData={setTableRowData}
                    setDeletePost={setDeletePost}
                    phone={phone}/>
            </TabPanel>
            <TabPanel value={value} index={2}>
                <Grid
                    container
                    direction="row"
                    // justifyContent="space-between"
                    alignItems="center"
                    spacing={3}
                    style={{marginTop: "20px"}}
                >
                    {(mediaData) ?
                        mediaData
                        :
                        <Box sx={{margin: "30px"}}>
                            No Photos Uploaded<br/>
                        </Box>
                    }

                </Grid>
                <MDButton size="small" color="success" style={{float: "right", marginTop: "20px"}} onClick={handleAddMediaOpen}>+ Add Photo</MDButton>
                <pre>
            </pre>
            </TabPanel>
            <Dialog
                open={openDeletePost}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleCloseDeletePost}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{"Are you sure you want to delete this post?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        This will permanently remove the post from your Google Profile.
                        This action cannot be undone.
                    </DialogContentText>
                </DialogContent>
                {(deletePostLoader) ?
                    <DialogActions>
                        <CircularProgress sx={{float: "right", marginTop: "20px"}} color="info" size={40}/>
                    </DialogActions>
                    :
                    <DialogActions>
                        <MDButton onClick={handleCloseDeletePost} variant="outlined" color="info">Cancel</MDButton>
                        <MDButton id="deletePinSubmit" onClick={handleDeletePost} variant="outlined" color="error">Delete Post</MDButton>
                    </DialogActions>
                }

            </Dialog>
            <Dialog
                open={openDeletePhoto}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleCloseDeletePost}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{"Are you sure you want to delete this post?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        This will permanently remove the post from your Google Profile.
                        This action cannot be undone.
                    </DialogContentText>
                </DialogContent>
                {(deletePhotoLoader) ?
                    <DialogActions>
                        <CircularProgress sx={{float: "right", marginTop: "20px"}} color="info" size={40}/>
                    </DialogActions>
                    :
                    <DialogActions>
                        <MDButton onClick={handleCloseDeletePhoto} variant="outlined" color="info">Cancel</MDButton>
                        <MDButton id="deletePinSubmit" onClick={handleDeletePhoto} variant="outlined" color="error">Delete Photo</MDButton>
                    </DialogActions>
                }
            </Dialog>
            <Dialog
                fullWidth
                open={addMediaOpen}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleAddMediaClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{"Add Photo"}</DialogTitle>
                <DialogContent>
                    <Box sx={{height: "300px"}}>
                        <Select
                            placeholder={"Category"}
                            onChange={e => handleChangeLinkType(e)}
                            options={types}
                            style={{height: "150px"}}
                            maxMenuHeight={150}
                        />
                        {(!fileUrl) ?
                            <Dropzone
                                getUploadParams={getUploadParams}
                                onChangeStatus={handlePhotoChangeStatus}
                                LayoutComponent={PhotosLayout}
                                PreviewComponent={photosPreview}
                                maxFiles={1}
                                accept="image/*,video/*"
                                inputContent={(files, extra) => (extra.reject ? 'Image and video files only accepted.' : '+ Click here or Drag photo')}
                                sx={{border: "1px solid #FF0000"}}
                                styles={{
                                    dropzoneReject: {borderColor: 'red', backgroundColor: '#DAA'},
                                    inputLabel: (files, extra) => (extra.reject ? {color: 'red'} : {}),
                                }}
                                initialFiles={[]}
                            />
                            : <Box sx={{textAlign: "center", marginTop: "20px"}}>
                                <img alt="" src={fileUrl} style={{height: "150px"}}/>
                            </Box>
                        }
                    </Box>


                </DialogContent>

                {(mediaLoading) ?
                    <Box p={5}>
                        <CircularProgress sx={{float: "right", magrin: "10px"}} color="info" size={40}/>
                    </Box>
                    :
                    <DialogActions>
                        <MDButton onClick={handleAddMediaClose} variant="outlined" color="info">Cancel</MDButton>
                        <MDButton onClick={submitMedia} id="deletePinSubmit" variant="outlined" color="success">Add Photo</MDButton>
                    </DialogActions>
                }
            </Dialog>
        </MDBox>
    )
}