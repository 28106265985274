/**
 =================================
 YourWebPro Contractor Portal Application V2
 NodeJS  |  React  |  MaterialUI  |  Material Dash 2
 2022 / 2023
 =================================
 ==========================================================
 Main components such as charts, boxes, sidenav, dash found in /components directory

 Layouts such at Dash, Profile, MapYourProjects, Billing are located in /layouts

 Theme files for colors and base layout definitions found in /assets/theme
 ==========================================================
 */

import Dashboard from "layouts/dashboard";
import Billing from "layouts/billing";
import Inbox from "layouts/inbox";
import Notifications from "layouts/notifications";
import Profile from "layouts/profile";
import MYP from "layouts/myp";
import Reviews from "layouts/reviews";
import Seo from "layouts/seo";
import JobScheduler from "layouts/jobscheduler";
import PageTitles from "layouts/pagetitles";
import Robots from "layouts/robots";
import SiteMap from "layouts/sitemap";
import Header from "layouts/header";
// import Dialogs from "layouts/dialogs";
import Meta from "layouts/metatags";
// import App from "layouts/app";
import AddPin from "layouts/myp/add";
import EditPin from "layouts/myp/edit";
import BulkUploadPin from "layouts/myp/bulkUpload";
import GMBManage from "layouts/gmb";
import AdWords from "layouts/adwords";
import Custom from "layouts/customContent";
import Icon from "@mui/material/Icon";
import Cookies from 'universal-cookie';

const cookies = new Cookies();
const bp = cookies.get('bp'); // Billing Problem
const ib = cookies.get('ib'); // Inbox Indicator
const ibc = ''; //cookies.get('ibc'); // Inbox Count (unread)
const nt = cookies.get('nt'); // Inbox Indicator
const ntc = cookies.get('ntc'); // Inbox Count (unread)


const billing_obj = (bp == 'true') ? {
        type: "nav",
        name: "Billing",
        key: "billing",
        icon: <Icon fontSize="small">receipt_long</Icon>,
        route: "billing",
        bcroute: "billing",
        component: <Billing/>,
        notifications: '!',
        badgeColor: "ywpRed",
        badgeFont: "ywpWhite",
    }
    : {
        type: "nav",
        name: "Billing",
        key: "billing",
        icon: <Icon fontSize="small">receipt_long</Icon>,
        route: "billing",
        bcroute: "billing",
        component: <Billing/>,
    }


const inbox_obj = (ib == 'true') ? {
        type: "nav",
        name: "Inbox",
        key: "inbox",
        icon: <Icon fontSize="small">email</Icon>,
        route: "inbox",
        bcroute: "inbox",
        component: <Inbox/>,
        notifications: ibc,
        badgeColor: "ywpGreen",
        badgeFont: "ywpWhite",
    }
    : {
        type: "nav",
        name: "Inbox",
        key: "inbox",
        icon: <Icon fontSize="small">email</Icon>,
        route: "inbox",
        bcroute: "inbox",
        component: <Inbox/>,
    }


const notifications_obj = (nt == 'true') ? {
        type: "nav",
        name: "Notifications",
        key: "notifications",
        icon: <Icon fontSize="small">email</Icon>,
        route: "/notifications",
        bcroute: "/notifications",
        component: <Notifications/>,
        notifications: ntc,
        badgeColor: "ywpGreen",
        badgeFont: "ywpWhite",
    }
    : {
        type: "nav",
        name: "Notifications",
        key: "notifications",
        icon: <Icon fontSize="small">email</Icon>,
        route: "/notifications",
        bcroute: "/notifications",
        component: <Notifications/>,
    }


const routes = [
    {
        type: "nav",
        name: "Dashboard",
        key: "dashboard",
        icon: <Icon fontSize="small">dashboard</Icon>,
        route: "dashboard",
        bcroute: "dashboard",
        component: <Dashboard/>,
    },

    {
        type: "nav",
        name: "Profile",
        key: "profile",
        icon: <Icon fontSize="small">person</Icon>,
        route: "profile",
        bcroute: "profile",
        component: <Profile/>,
    },

    billing_obj,
    inbox_obj,
    {
        type: "divider",
        key: "divider1"
    },
    {
        type: "title",
        title: "Premium Services",
        key: "premiumservices"
    },

    {
        type: "nav",
        name: "MapYourProjects",
        key: "MapYourProjects",
        icon: <Icon fontSize="small">add_location</Icon>,
        route: "MapYourProjects",
        bcroute: "MapYourProjects",
        component: <MYP/>,
    },
    {
        type: "nav",
        name: "Reviews",
        key: "reviews",
        icon: <Icon fontSize="small">reviews</Icon>,
        route: "reviews",
        bcroute: "reviews",
        component: <Reviews/>,
    },
    {
        type: "nav",
        name: "SEO & Marketing",
        key: "marketing",
        icon: <Icon fontSize="small">query_stats</Icon>,
        route: "marketing",
        bcroute: "marketing",
        component: <Seo/>,
    },
    // {
    //     type: "nav",
    //     name: "Job Scheduler",
    //     key: "jobscheduler",
    //     icon: <Icon fontSize="small">calendar_month</Icon>,
    //     route: "jobscheduler",
    //     bcroute: "jobscheduler",
    //     component: <JobScheduler/>,
    // },

    {
        type: "divider",
        key: "divider2"
    },
    {
        type: "title",
        title: "Website Settings",
        key: "websitesettings"
    },
    {
        type: "nav",
        name: "Custom Content",
        key: "custom",
        icon: <Icon fontSize="small">code</Icon>,
        route: "CustomContent",
        bcroute: "CustomContent",
        component: <Custom/>,
    },
    {
        type: "nav",
        name: "Website Header",
        key: "header",
        icon: <Icon fontSize="small">article</Icon>,
        route: "header",
        bcroute: "header",
        component: <Header/>,
    },
    {
        type: "nav",
        name: "Page Titles",
        key: "pagetitles",
        icon: <Icon fontSize="small">title</Icon>,
        route: "pagetitles",
        bcroute: "pagetitles",
        component: <PageTitles/>,
    },
    // {
    //     type: "blind",
    //     name: "Install On Device",
    //     key: "app",
    //     icon: <Icon fontSize="small">menu_book</Icon>,
    //     route: "app",
    //     bcroute: "app",
    //     component: <App/>,
    // },
    {
        type: "nav",
        name: "Robots",
        key: "robots",
        icon: <Icon fontSize="small">smart_toy</Icon>,
        route: "robots",
        bcroute: "robots",
        component: <Robots/>,
    },
    {
        type: "nav",
        name: "Sitemap",
        key: "sitemap",
        icon: <Icon fontSize="small">travel_explore</Icon>,
        route: "sitemap",
        bcroute: "sitemap",
        component: <SiteMap/>,
    },
    {
        type: "nav",
        name: "Meta Tags",
        key: "metatags",
        icon: <Icon fontSize="small">style</Icon>,
        route: "metatags",
        bcroute: "metatags",
        component: <Meta/>,
    },
    // {
    //     type: "nav",
    //     name: "Dialog Boxes",
    //     key: "dialogs",
    //     icon: <Icon fontSize="small">widgets</Icon>,
    //     route: "dialogs",
    //     bcroute: "dialogs",
    //     component: <Dialogs/>,
    // },

    {
        type: "divider",
        key: "divider3"
    },
    // notifications_obj,
    {
        type: "blind",
        name: "Add Pin",
        key: "myp_add",
        icon: <Icon fontSize="small">dashboard</Icon>,
        route: "/MapYourProjects/AddPin",
        bcroute: "AddPin",
        component: <AddPin/>,
    },
    {
        type: "blind",
        name: "Edit Pin",
        key: "myp_edit",
        icon: <Icon fontSize="small">dashboard</Icon>,
        route: "/MapYourProjects/EditPin",
        bcroute: "EditPin",
        component: <EditPin/>,
    },
    // Temporary ##### REMOVE ######
    {
        type: "blind",
        name: "Notifications",
        key: "notifications",
        icon: <Icon fontSize="small">email</Icon>,
        route: "/notifications",
        bcroute: "notifications",
        component: <Notifications/>,
    },
    // Temporary ##### REMOVE ######
    {
        type: "blind",
        name: "Bulk Upload",
        key: "myp_upload",
        icon: <Icon fontSize="small">dashboard</Icon>,
        route: "/MapYourProjects/BulkUploadPin",
        bcroute: "BulkUploadPin",
        component: <BulkUploadPin/>,
    },
    {
        type: "blind",
        name: "GoogleMyBusiness",
        key: "gmb_manage",
        icon: <Icon fontSize="small">dashboard</Icon>,
        route: "GoogleMyBusiness",
        bcroute: "GoogleMyBusiness",
        component: <GMBManage/>,
    },
    {
        type: "blind",
        name: "AdWords",
        key: "adwords",
        icon: <Icon fontSize="small">dashboard</Icon>,
        route: "AdWords",
        bcroute: "AdWords",
        component: <AdWords/>,
    },

    // {
    //     type: "nav",
    //     name: "View Website",
    //     key: "notifications",
    //     icon: <Icon fontSize="small">email</Icon>,
    //     href: "https://yourwebpro.com",
    //     component: <Notifications/>,
    // },

];

export default routes;
