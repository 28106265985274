import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import React, {useEffect} from "react";
import {apiUrl, chk} from "App";
import api from "utils/axiosConfig";
import PageLoading from "components/PageLoading";
import Box from "@mui/material/Box";
import MDButton from "../../components/MDButton";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Slide from "@mui/material/Slide";
import CircularProgress from "@mui/material/CircularProgress";
import {useSnackbar} from "notistack";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const Header = () => {

    const {enqueueSnackbar} = useSnackbar();
    const [loading, setLoading] = React.useState(true);
    const [header, setHeader] = React.useState({});
    const [customHeader, setCustomHeader] = React.useState('');
    const [updateHeaderOpen, setUpdateHeaderOpen] = React.useState(false);
    const [updateLoader, setUpdateLoader] = React.useState(false);

    useEffect(() => {
        // axios.defaults.withCredentials = true;
        const ck = chk();
        const session = ck.session;
        const cs = ck.cs;

        api.post(apiUrl() + "api/v2/Website/Header/get/", {cs: cs, session: session}).then(response => {
            if (response.data.status === 'ok') {
                setHeader(response.data.head);
                setCustomHeader(response.data.custom_head);
            }
        }).then(response => {
            setLoading(false);
        });
    }, []);

    function handleUpdateHeader() {
        setUpdateLoader(true);
        const ck = chk();
        const session = ck.session;
        const cs = ck.cs;

        api.post(apiUrl() + "api/v2/Website/Header/save/", {cs: cs, session: session, header: customHeader}).then(response => {
            if (response.data.status === 'ok') {
                const message = "Your Header was successfully saved.";
                const variant = "success";
                enqueueSnackbar(message, {variant});
            }else if(response.data.status === 'error'){
                const message = "There was a problem saving your data." + response.data.message;
                const variant = "error";
                enqueueSnackbar(message, {variant});
            }else{
                const message = "There was a problem saving your data.  Please validate that it is formatted correctly and does not contain errors.";
                const variant = "error";
                enqueueSnackbar(message, {variant});
            }
        }).then(response => {
            setUpdateHeaderOpen(false);
            setUpdateLoader(false);
        });

    }

    function handleUpdateOpen() {
        setUpdateHeaderOpen(true)
    }

    function handleUpdateClose() {
        setUpdateHeaderOpen(false)
    }


    if (loading) {
        return (
            <PageLoading/>
        )
    }
    return (
        <DashboardLayout>
            <DashboardNavbar/>

            <Box style={{
                fontSize: "12px",
                width: "600px",
                border: "1px solid #DDD"
            }}
                 dangerouslySetInnerHTML={{__html: header}}
            />
            <Box style={{
                fontSize: "12px",
                width: "600px",
            }}>
                <textarea
                    style={{
                        width: "100%",
                        height: "200px",
                        border: "1px solid #DDD",
                        backgroundColor: "#F0F2F5",
                        padding: "10px 0px 0px 30px",
                        color: "#344767",
                    }}
                    onChange={e => setCustomHeader(e.target.value)}
                >
                    {customHeader}
                </textarea>
            </Box>
            <MDButton onClick={handleUpdateOpen} variant="outlined" color="info">Update Header</MDButton>
            <Dialog
                open={updateHeaderOpen}
                TransitionComponent={Transition}
                keepMounted
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{"You are about to publish content LIVE to your website."}</DialogTitle>
                <DialogContent>
                    <Box p={3}>

                        This action can have unforeseen consequences should<br/>
                        the content in your header be malformed or improperly formatted.<br/><br/>

                        You are agreeing to take all liability for the custom content in your header. Please make sure the following:<br/><br/>
                        <ul>
                            <li>You have not inserted any JavaScript from unknown sources.</li>
                            <li>You possess the knowledge to know what the tags do and why.</li>
                            <li>You understand what any JavaScript does, and it's functionality.</li>
                            <li>You trust your content and understand the risks.</li>
                            <li>Changes can be undone by deleting the custom content and saving</li>
                            <li>Depending on site size, this may take up to a couple minutes</li>
                        </ul>

                    </Box>
                </DialogContent>
                <DialogActions>


                    {(updateLoader) ?
                        <CircularProgress color="info" size={40} sx={{
                            marginLeft: "30px"
                        }}/>
                        :
                        <>
                            <MDButton onClick={handleUpdateClose} variant="outlined" color="info">Cancel</MDButton>
                            <MDButton id="deleteSelectedPinSubmit" onClick={handleUpdateHeader} variant="outlined" color="error">Update Header</MDButton>
                        </>
                    }
                </DialogActions>
            </Dialog>
        </DashboardLayout>
    );
};

export default Header;