// @mui material components
import React, {useEffect} from "react";
import Grid from "@mui/material/Grid";

import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import Footer from "components/Footer";
import PageLoading from "components/PageLoading"
import {apiUrl, chk} from "App";
import api from "utils/axiosConfig";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import MDButton from "components/MDButton";

function SiteMap() {

    const [loading, setLoading] = React.useState(true);
    const [sitemap, setSitemap] = React.useState({});
    const [customSitemap, setCustomSitemap] = React.useState({});

    useEffect(() => {
        // axios.defaults.withCredentials = true;
        const ck = chk();
        const session = ck.session;
        const cs = ck.cs;

        api.post(apiUrl() + "api/v2/Website/Sitemap/get/", {cs: cs, session: session}).then(response => {
            if (response.data.status === 'ok') {
                setSitemap(response.data.sitemap);
                setCustomSitemap(response.data.custom);
            }
        }).then(response => {
            setLoading(false);
        });
    }, []);


    function saveSitemap(){
        // axios.defaults.withCredentials = true;
        setLoading(true);
        const ck = chk();
        const session = ck.session;
        const cs = ck.cs;

        api.post(apiUrl() + "api/v2/Website/Sitemap/save/", {cs: cs, session: session, sm: customSitemap}).then(response => {
            if (response.data.status === 'ok') {
                setSitemap(response.data.sitemap);
                setCustomSitemap(response.data.custom);
            }
        }).then(response => {
            setLoading(false);
        });
    }


    if (loading) {
        return (
            <PageLoading/>
        )
    }

    return (
        <DashboardLayout>
            <DashboardNavbar/>

            <Box sx={{
                marginBottom: "10px",
                backgroundColor: "#3779AB",
                fontWeight: "bold",
                color: "#FFF",
                height: "30px"
            }}>

            </Box>
            <Paper sx={{padding: "30px"}}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6} sx={{borderRight:"1px solid #DDD"}}>
                        <Typography sx={{fontWeight: "bold"}}>
                            Sitemap XML file
                        </Typography>
                        <Box sx={{fontSize: "12px", height: "400px", everflow: "hidden", overflowY: "scroll"}}>
                            <pre>
                                  {sitemap}
                            </pre>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth sx={{m: 1}}>
                            <TextField
                                onKeyUp={(e) => setCustomSitemap(e.target.value)}
                                id="standard-multiline-flexible"
                                label="Custom Sitemap XML"
                                multiline
                                variant="standard"
                                rows="20"
                                defaultValue={customSitemap}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                <Box sx={{height:"50px", marginTop:"20px"}}>
                    <MDButton color="info" size="medium" sx={{float: "right"}} onClick={saveSitemap}>
                        Save Sitemap XML
                    </MDButton>
                </Box>
            </Paper>
            <Footer/>
        </DashboardLayout>
    );
}

export default SiteMap;
