// @mui material components
import React, {useEffect, useState} from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import {apiUrl, chk} from "App";
import api from "utils/axiosConfig";
import Grid from "@mui/material/Grid";
import Drawer from '@mui/material/Drawer';
import InfoIcon from '@mui/icons-material/Info';
import {DataGrid} from '@mui/x-data-grid';

function Rankings() {
    const [loading, setLoading] = useState(true);
    const [rankings, setRankings] = useState();
    const [drawerState, setDrawerState] = useState(false);
    const [anchor, setAnchor] = useState("bottom");
    const [keyPhraseId, setKeyphraseId] = useState(null);
    const [rows, setRows] = useState([]);

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setDrawerState({...drawerState, [anchor]: open});
    };

    const handleOnCellClick = (params) => {
        if (params.field === 'keyphrase') {
            const keyphrase = params.value;
            const filtered = rankings.filter(entry =>
                Object.values(entry).some(val =>
                    typeof val === "string" && val.includes(keyphrase)
                )
            );
            setKeyphraseId(filtered[0].id);
            alert("View info for: " + filtered[0].id)
        }
    };

    useEffect(() => {
        // axios.defaults.withCredentials = true;
        const ck = chk();
        const session = ck.session;
        const cs = ck.cs;

        api.post(apiUrl() + "api/v2/analyticsV2/Ant/get_rankings/", {cs: cs, session: session}).then(response => {
            if (response.data.status === 'ok') {
                setRankings(response.data.rankings)
                let temp_arr = [];
                // for (item of response.data.rankings) {
                response.data.rankings.forEach((element, index, array) => {
                    let tmpObj = {};
                    tmpObj.id = element.row;
                    tmpObj.keyphrase = element.name;
                    tmpObj.avgRank = element.avgRank;
                    tmpObj.rank = (element.rank != 101) ? element.rank : 'N/A';
                    tmpObj.URL = element.url;
                    temp_arr.push(tmpObj);
                })
                setRows(temp_arr);

            }
        }).then(response => {
            setLoading(false);
        });
    }, []);

    const columns = [
        {
            field: 'id',
            headerName: ' ',
            width: 5,
        },

        {
            field: 'keyphrase',
            headerName: 'Key Phrase',
            width: 250,
            renderCell: (params) => (
                <span style={{cursor: "pointer", fontWeight: "bold", color: "#4157de"}}>{params.value}</span>
            )
        },

        {
            field: 'avgRank',
            headerName: 'Avg Rank',
            type: 'number',
            align: 'center',
            width: 100,
        },

        {
            field: 'rank',
            headerName: 'Current Rank',
            type: 'number',
            align: 'center',
            width: 125,
        },

        {
            field: 'URL',
            headerName: 'URL',
            sortable: false,
            width: 400,
            renderCell: (params) => (
                <a href={params.value} target={"_blank"}>{params.value}</a>
            )
        },
    ];


    if (loading) {
        return (
            <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
                style={{minHeight: '60vh'}}
            >
                <Grid item xs={3}>
                    <CircularProgress color="info" size={120}/>
                </Grid>
            </Grid>
        )
    }

    return (
        <Box>
            {(rankings) ?
                <Box>
                    <DataGrid
                        rows={rows}
                        columns={columns}
                        initialState={{
                            pagination: {
                                paginationModel: {page: 0, pageSize: 10},
                            },
                        }}
                        pageSizeOptions={[10]}
                        onCellClick={handleOnCellClick}
                        disableRowSelectionOnClick
                        // checkboxSelection
                    />

                    <Drawer
                        anchor={anchor}
                        open={drawerState[anchor]}
                        onClose={toggleDrawer(anchor, false)}
                        PaperProps={{style: {width: '98%', height: "300px"}}}
                    >
                        <Box
                            sx={{width: 'auto', fontSize: "16px", margin: "20px"}}
                            role="presentation"
                            onClick={toggleDrawer(anchor, false)}
                            onKeyUp={toggleDrawer(anchor, false)}
                        >
                            <Box>
                                <Grid container spacing={3}>
                                    <Grid item lg={.5}>
                                        <InfoIcon sx={{
                                            fontSize: "45px",
                                            color: "#4157de"
                                        }}/>
                                    </Grid>
                                    <Grid item lg={2}>
                                        <span style={{fontSize: "24px"}}>Rankings Information Center</span>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box sx={{marginLeft: "50px"}}>
                                ➡ This report lists your current and historical keyword(s) positions<br/>
                                ➡ Keywords are proximity specific and may change without notice<br/>
                                The number in the column labeled Google indicated the <b>actual keyword rank</b> -- Not the <i>page number</i><br/>
                                <hr style={{margin: "5px"}}/>
                                EXAMPLE<br/>
                                The number <b>1</b> indicates a ranking of <b>number 1, page 1</b><br/>
                                The number <b>17</b> indicates a ranking of <b>number 7, page 2</b><br/>
                                There are roughly 10 organic rankings on each page, not including "pay-per-click" (Sponsored Ads)<br/>
                            </Box>
                        </Box>
                    </Drawer>
                    <Box
                        sx={{position: "fixed", right: "5px", bottom: "5px", cursor: "pointer"}}
                        onClick={toggleDrawer(anchor, true)}>
                        <InfoIcon sx={{
                            fontSize: "45px",
                            color: "#4157de"
                        }}/>
                    </Box>
                </Box>
                :
                <></>
            }
        </Box>
    )
}


export default Rankings;