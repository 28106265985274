// @mui material components
import React, {useState, useEffect} from "react";
import Grid from "@mui/material/Grid";
import Cookies from 'universal-cookie';
import Container from '@mui/material/Container';
import CircularProgress from "@mui/material/CircularProgress";
import {apiUrl, chk} from "App";
import api from "utils/axiosConfig";
import {browserName, deviceType, fullBrowserVersion, mobileModel, mobileVendor, osName, osVersion} from "react-device-detect";


const {timeZone} = Intl.DateTimeFormat().resolvedOptions();

const OS = osName + " " + osVersion;
const browser = browserName + " " + fullBrowserVersion;
let mobile = 'NA';
if (mobileVendor !== 'none' && mobileModel !== 'none') {
    mobile = mobileVendor + " " + mobileModel;
}
const formFactor = deviceType;
const cookies = new Cookies();

function OTL() {

    const [isLoading, setLoading] = useState(true);
    const queryParameters = new URLSearchParams(window.location.search)
    const otl = queryParameters.get("otl")

    useEffect(() => {
        // axios.defaults.withCredentials = true;
        api.post(apiUrl() + "api/v2/login/otl/", {
            otl: otl,
            OS: OS,
            browser: browser,
            mobile: mobile,
            formFactor: formFactor,
            timeZone: timeZone
        }).then(response => {
            if (response.data.status === true) {
                cookies.set('session', response.data.token, {path: '/'});
                cookies.set('count', response.data.cid, {path: '/'});
                cookies.set('contractor', response.data.contractor, {path: '/'});
                cookies.set('adm', response.data.adm, {path: '/'});
                window.location.reload(false);
            } else {
                const windowUrl = window.location.href;
                const { origin, pathname } = new URL(windowUrl);
                const urlWithoutQueryOrHash = `${origin}${pathname}`;
                window.location=urlWithoutQueryOrHash
            }
        });
    }, []);



    return (
        <div>
            <Container component="main" maxWidth="xs">
                <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    style={{minHeight: '80vh'}}
                >

                    <Grid item xs={3}>
                        <CircularProgress color="info" size={120}/>
                    </Grid>
                </Grid>
            </Container>

        </div>
    );

}

export default OTL;