/**
 =================================
 YourWebPro Contractor Portal Application V2
 NodeJS  |  React  |  MaterialUI  |  Material Dash 2
 2022 / 2023
 =================================
 ==========================================================
 Main components such as charts, boxes, sidenav, dash found in /components directory

 Layouts such at Dash, Profile, MapYourProjects, Billing are located in /layouts

 Theme files for colors and base layout definitions found in /assets/theme
 ==========================================================
 */


import Card from "@mui/material/Card";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import Switch from "@mui/material/Switch";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import TableCell from "layouts/profile/components/TableCell";
import api from "utils/axiosConfig";
import {apiUrl} from "App";
import Cookies from 'universal-cookie';
import DashboardNavbar from "../../components/Navbars/DashboardNavbar";
import Grid from "@mui/material/Grid";
import Footer from "../../components/Footer";
import DashboardLayout from "../../components/LayoutContainers/DashboardLayout";

const cookies = new Cookies();

function Notifications() {


    function updateNotify(e) {
        const type = e.name;
        const val = e.checked;
        console.log(cookies.get('session'))
        api.post(apiUrl() + "api/v2/profile/update/notifications/", {
            contractor_id: cookies.get('count'),
            session: cookies.get('session'),
            type: type,
            val: val,
        });


    }


    return (
        <DashboardLayout>
            <DashboardNavbar/>
            <Card id="notifications">
                <MDBox p={3} lineHeight={1}>
                    <MDBox mb={1}>
                        <MDTypography variant="h5">Notifications</MDTypography>
                    </MDBox>
                    <MDTypography variant="button" color="text">
                        Choose how you receive notifications. These notification settings apply to the things
                        you’re watching.
                    </MDTypography>
                </MDBox>
                <MDBox pb={3} px={3}>
                    <MDBox minWidth="auto" sx={{overflow: "scroll"}}>
                        <Table sx={{minWidth: "36rem"}}>
                            <MDBox component="thead">
                                <TableRow>
                                    <TableCell width="100%" padding={[1.5, 3, 1.5, 0.5]}>
                                        Activity
                                    </TableCell>
                                    <TableCell align="center" padding={[1.5, 6, 1.5, 6]}>
                                        Email
                                    </TableCell>
                                    <TableCell align="center" padding={[1.5, 6, 1.5, 6]}>
                                        Push
                                    </TableCell>
                                    <TableCell align="center" padding={[1.5, 6, 1.5, 6]}>
                                        SMS
                                    </TableCell>
                                </TableRow>
                            </MDBox>
                            <TableBody>
                                <TableRow>
                                    <TableCell padding={[1, 1, 1, 0.5]}>
                                        <MDBox lineHeight={1.4}>
                                            <MDTypography display="block" variant="button" fontWeight="regular">
                                                Billing
                                            </MDTypography>
                                            <MDTypography variant="caption" color="text" fontWeight="regular">
                                                Notify me when YourWebPro bills my account
                                            </MDTypography>
                                        </MDBox>
                                    </TableCell>
                                    <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                                        <Switch
                                            defaultChecked
                                            name={"billingEmail"}
                                            onChange={(e) => updateNotify(e.target)}/>
                                    </TableCell>
                                    <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                                        <Switch
                                            name={"billingPush"}
                                            onChange={(e) => updateNotify(e.target)}/>
                                    </TableCell>
                                    <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                                        <Switch
                                            name={"billingSms"}
                                            onChange={(e) => updateNotify(e.target)}/>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell padding={[1, 1, 1, 0.5]}>
                                        <MDBox lineHeight={1.4}>
                                            <MDTypography display="block" variant="button" fontWeight="regular">
                                                Website Forms
                                            </MDTypography>
                                            <MDTypography variant="caption" color="text" fontWeight="regular">
                                                Notify me when a user fills out my website form(s). Website responses are stored in your Inbox.
                                            </MDTypography>
                                        </MDBox>
                                    </TableCell>
                                    <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                                        <Switch defaultChecked
                                                name={"formsEmail"}
                                                onChange={(e) => updateNotify(e.target)}/>
                                    </TableCell>
                                    <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                                        <Switch defaultChecked
                                                name={"formsPush"}
                                                onChange={(e) => updateNotify(e.target)}/>
                                    </TableCell>
                                    <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                                        <Switch
                                            name={"formsSMS"}
                                            onChange={(e) => updateNotify(e.target)}/>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell padding={[1, 1, 1, 0.5]}>
                                        <MDBox lineHeight={1.4}>
                                            <MDTypography display="block" variant="button" fontWeight="regular">
                                                Important Notices
                                            </MDTypography>
                                            <MDTypography variant="caption" color="text" fontWeight="regular">
                                                YourWebPro will send you notices specific to your business and business needs. These are not promotions.
                                            </MDTypography>
                                        </MDBox>
                                    </TableCell>
                                    <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                                        <Switch
                                            name={"noticesEmail"}
                                            onChange={(e) => updateNotify(e.target)}/>
                                    </TableCell>
                                    <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                                        <Switch defaultChecked
                                                name={"noticesPush"}
                                                onChange={(e) => updateNotify(e.target)}/>
                                    </TableCell>
                                    <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                                        <Switch
                                            name={"noticesSMS"}
                                            onChange={(e) => updateNotify(e.target)}/>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell padding={[1, 1, 1, 0.5]}>
                                        <MDBox lineHeight={1.4}>
                                            <MDTypography display="block" variant="button" fontWeight="regular">
                                                Promotions
                                            </MDTypography>
                                            <MDTypography variant="caption" color="text" fontWeight="regular">
                                                Notify me with YourWebPro specific promotions and offers.
                                            </MDTypography>
                                        </MDBox>
                                    </TableCell>
                                    <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                                        <Switch
                                            name={"promotionsEmail"}
                                            onChange={(e) => updateNotify(e.target)}/>
                                    </TableCell>
                                    <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                                        <Switch defaultChecked
                                                name={"promotionsPush"}
                                                onChange={(e) => updateNotify(e.target)}/>
                                    </TableCell>
                                    <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                                        <Switch
                                            name={"promotionsSMS"}
                                            onChange={(e) => updateNotify(e.target)}/>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell padding={[1, 1, 1, 0.5]} noBorder>
                                        <MDTypography display="block" variant="button" color="text">
                                            Log in from a new device
                                        </MDTypography>
                                    </TableCell>
                                    <TableCell align="center" padding={[1, 1, 1, 0.5]} noBorder>
                                        <Switch defaultChecked
                                                name={"sessionsEmail"}
                                                onChange={(e) => updateNotify(e.target)}/>
                                    </TableCell>
                                    <TableCell align="center" padding={[1, 1, 1, 0.5]} noBorder>
                                        <Switch defaultChecked
                                                name={"sessionsPush"}
                                                onChange={(e) => updateNotify(e.target)}/>
                                    </TableCell>
                                    <TableCell align="center" padding={[1, 1, 1, 0.5]} noBorder>
                                        <Switch defaultChecked
                                                name={"sessionsSMS"}
                                                onChange={(e) => updateNotify(e.target)}/>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </MDBox>
                </MDBox>
            </Card>
        </DashboardLayout>
    );
}

export default Notifications;
