/**
 =================================
 YourWebPro Contractor Portal Application V2
 NodeJS  |  React  |  MaterialUI  |  Material Dash 2
 2022 / 2023
 =================================
 ==========================================================
 Main components such as charts, boxes, sidenav, dash found in /components directory

 Layouts such at Dash, Profile, MapYourProjects, Billing are located in /layouts

 Theme files for colors and base layout definitions found in /assets/theme
 ==========================================================
 */

// @mui material components
import Icon from "@mui/material/Icon";
import Link from "@mui/material/Link";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Grid from "@mui/material/Grid";
import React, {useEffect, useState} from "react";
import FormField from "components/FormField";
import MDInput from "components/MDInput";
import colors from "assets/theme/base/colors"
import Box from "@mui/material/Box";

const ywpBlue = colors.ywpBlue.main;
const text = colors.text.main;
const ywpRed = colors.ywpRed.main;
const ywpWhite = colors.ywpWhite.main;
const ywpGreen = colors.ywpGreen.main;


function Header({
                    myp,
                    handleMYPCheckAll,
                    handleClickOpenAll,
                    handleSetDisplayType,
                    isDeviceMobile,
                    searchTerm,
                    handleSetSearchTerm
                }) {

    const domain = 'http://' + myp.domain + '/' + myp.project_directory;


    return (
        <MDBox p={2} style={{width: "100%", backgroundColor: "#FFF", borderRadius: "10px"}}>

            {(isDeviceMobile) ?
                <Box>
                    <Box>
                        <MDInput
                            fullWidth
                            label="Search"
                        />
                    </Box>
                    <Box style={{marginTop: "15px"}}>
                        <Grid
                            container
                            direction="row"
                            spacing={1}
                        >
                            <Grid item s={2}>
                                <Icon onClick={(e) => handleSetDisplayType('grid', e)} fontSize="large" sx={{marginRight: "10px", color: ywpBlue, cursor: "pointer"}} title="Grid View">grid_view</Icon>
                            </Grid>
                            <Grid item s={2}>
                                <Icon onClick={(e) => handleSetDisplayType('list', e)} fontSize="large" sx={{marginRight: "10px", color: ywpBlue, cursor: "pointer"}} title="List View">format_list_bulleted_icon</Icon>
                            </Grid>
                            <Grid item s={2}>
                                <a href={domain} target="_blank"><Icon fontSize="large" sx={{color: ywpGreen, cursor: "pointer"}} title="View MYP Page">visibility</Icon></a>
                            </Grid>
                            <Grid item s={2}>
                                <Icon fontSize="large" sx={{marginRight: "10px", color: ywpBlue, cursor: "pointer"}} title="Select All" onClick={handleMYPCheckAll}>done_all</Icon>
                            </Grid>
                            <Grid item s={2}>
                                <a href="/MapYourProjects/AddPin"><Icon fontSize="large" sx={{marginRight: "10px", color: ywpGreen, cursor: "pointer"}} title="Add New Pin">add_circle</Icon></a>
                            </Grid>
                            <Grid item s={2}>
                                <Icon fontSize="large" sx={{color: ywpRed, cursor: "pointer"}} title="Delete Selected" onClick={handleClickOpenAll}>delete_sweep</Icon>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
                :
                <Grid
                    container
                    direction="row"

                    spacing={4}
                >
                    <Grid item xs={12} sm={4}>
                        <MDInput
                            fullWidth
                            label="Search"
                            onKeyUp={(e) => handleSetSearchTerm(e.target.value)}
                            defaultValue={searchTerm}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} sx={{fontSize: "35px", marginTop: "0px"}}>
                        <Icon onClick={(e) => handleSetDisplayType('grid', e)} sx={{marginRight: "10px", color: ywpBlue, cursor: "pointer"}} title="Grid View">grid_view</Icon>
                        <Icon onClick={(e) => handleSetDisplayType('list', e)} sx={{marginRight: "10px", color: ywpBlue, cursor: "pointer"}} title="List View">format_list_bulleted_icon</Icon>
                        <a href={domain} target="_blank"><Icon sx={{color: ywpGreen, cursor: "pointer"}} title="View MYP Page">visibility</Icon></a>
                    </Grid>
                    <Grid item xs={12} sm={4} align="right" sx={{fontSize: "35px", marginTop: "0px"}}>
                        <Icon sx={{marginRight: "10px", color: ywpBlue, cursor: "pointer"}} title="Select All" onClick={handleMYPCheckAll}>done_all</Icon>
                        <a href="/MapYourProjects/AddPin"><Icon sx={{marginRight: "10px", color: ywpGreen, cursor: "pointer"}} title="Add New Pin">add_circle</Icon></a>
                        <a href="/MapYourProjects/BulkUploadPin"><Icon sx={{marginRight: "10px", color: ywpBlue, cursor: "pointer"}} title="Bulk Upload">drive_folder_upload_icon</Icon></a>
                        <Icon sx={{color: ywpRed, cursor: "pointer"}} title="Delete Selected" onClick={handleClickOpenAll}>delete_sweep</Icon>
                    </Grid>
                </Grid>
            }
        </MDBox>
    )
}

export default Header;