/**
 =================================
 YourWebPro Contractor Portal Application V2
 NodeJS  |  React  |  MaterialUI  |  Material Dash 2
 2022 / 2023
 =================================
 ==========================================================
 Main components such as charts, boxes, sidenav, dash found in /components directory

 Layouts such at Dash, Profile, MapYourProjects, Billing are located in /layouts

 Theme files for colors and base layout definitions found in /assets/theme
 ==========================================================
 */

import React, {useEffect, useState} from "react";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import Footer from "components/Footer";
import BasicInfo from "layouts/profile/components/BasicInfo";
import Header from "layouts/profile/components/Header";
import Sidenav from "layouts/profile/components/Sidenav";
import ChangePassword from "layouts/profile/components/ChangePassword";
import Accounts from "layouts/profile/components/Accounts";
import Box from "@mui/material/Box";
import api from 'utils/axiosConfig';
import PageLoading from "components/PageLoading";
import {apiUrl, chk} from "App";


function Profile() {

    const [isLoading, setLoading] = useState(true);

    const [profile, setProfile] = useState();
    const [noProfile, setNoProfile] = useState();


    useEffect(() => {
        // axios.defaults.withCredentials = true;
        const ck = chk();
        const session = ck.session;
        const cs = ck.cs;

        api.post(apiUrl() + "api/v2/profile/", {cs: cs, session: session}).then(response => {
            if (response.data.status === 'ok') {
                setProfile(response.data);
                setLoading(false);
            } else {
                setNoProfile(true);
                setLoading(false);
            }

        });
    }, []);


    if (isLoading) {
        return (
            <PageLoading/>
        )
    }
    if (noProfile) {
        return (
            <Box>
                <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    style={{minHeight: '80vh'}}
                >

                    <Grid item xs={3}>
                        There appears to be a problem retrieving your profile information.<br/>
                        If this persists, please <a style={{
                        color: "#FF0000",
                    }} href={"mailto:support@yourwebpro.com"}>Contact Support</a> or call (847-884-7400).<br/>
                    </Grid>

                </Grid>

            </Box>
        )
    }

    return (
        <DashboardLayout>
            <DashboardNavbar/>
            <MDBox mt={4}>
                <Grid container spacing={3}>
                    <Grid item xs={12} lg={3}>
                        <Sidenav/>
                    </Grid>
                    <Grid item xs={12} lg={9}>
                        <MDBox mb={3}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Header profile={profile}/>
                                </Grid>
                                <Grid item xs={12}>
                                    <BasicInfo
                                        profile={profile}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <ChangePassword/>
                                </Grid>
                                {/*<Grid item xs={12}>*/}
                                {/*  <Authentication />*/}
                                {/*</Grid>*/}
                                <Grid item xs={12}>
                                    <Accounts/>
                                </Grid>
                                {/*<Grid item xs={12}>*/}
                                {/*    <Notifications/>*/}
                                {/*</Grid>*/}
                                {/*<Grid item xs={12}>*/}
                                {/*    <Sessions/>*/}
                                {/*</Grid>*/}
                                {/*<Grid item xs={12}>*/}
                                {/*  <DeleteAccount />*/}
                                {/*</Grid>*/}
                            </Grid>
                        </MDBox>
                    </Grid>
                </Grid>
            </MDBox>
            <Footer/>
        </DashboardLayout>
    );
}

export default Profile;
