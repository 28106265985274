// @mui material components
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "./components/Navbar";
import LandingPages from "./components/landingPages";
import Footer from "components/Footer";
import Box from "@mui/material/Box";
import {useEffect} from "react";
import {apiUrl, chk} from "App";
import api from "utils/axiosConfig";
import {useState} from "react";
import PageLoading from "components/PageLoading";
import React from "react";
import Typography from "@mui/material/Typography";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Grid from "@mui/material/Grid";
import MDButton from "components/MDButton";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import {useSnackbar} from "notistack";
import MDInput from "components/MDInput";
import CircularProgress from "@mui/material/CircularProgress";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


function TabPanel(props: TabPanelProps) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


function Dashboard() {
    const [value, setValue] = React.useState(0);
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return (
        <DashboardLayout>
            <DashboardNavbar/>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                <Tab label="Landing Pages" {...a11yProps(0)} />
                {/*<Tab label="Forms" {...a11yProps(1)} />*/}
                {/*<Tab label="Thing 2" {...a11yProps(2)} />*/}
                {/*<Tab label="Thing 3" {...a11yProps(3)} />*/}
                {/*<Tab label="TBD" {...a11yProps(4)} />*/}

            </Tabs>
            <TabPanel value={value} index={0}>
                <LandingPages />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <Box>
                    Forms
                </Box>
            </TabPanel>
            <TabPanel value={value} index={2}>
                <Box>
                    Thing 2
                </Box>
            </TabPanel>
            <TabPanel value={value} index={3}>
                <Box>
                    Thing 3
                </Box>
            </TabPanel>
            <TabPanel value={value} index={4}>
                <Box>
                    TBD
                </Box>
            </TabPanel>


            <Footer/>
        </DashboardLayout>
    );
}

export default Dashboard;