/**
 =================================
 YourWebPro Contractor Portal Application V2
 NodeJS  |  React  |  MaterialUI  |  Material Dash 2
 2022 / 2023
 =================================
 ==========================================================
 Main components such as charts, boxes, sidenav, dash found in /components directory

 Layouts such at Dash, Profile, MapYourProjects, Billing are located in /layouts

 Theme files for colors and base layout definitions found in /assets/theme
 ==========================================================
 */

// @material-ui core components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Chip from "@mui/material/Chip";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import FormControl from '@mui/material/FormControl';

import TextField from '@mui/material/TextField';

import MDInput from "components/MDInput";

function Advanced({
                      pageTitle,
                      setPageTitle,
                      metaDescription,
                      setMetaDescription,
                  }) {
    return (
        <Card id="basic-info" sx={{overflow: "visible"}}>
            <MDBox p={3}>
                <MDTypography variant="h5">Advanced Page Settings</MDTypography>
            </MDBox>
            <MDBox component="form" pb={3} px={3}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <MDInput
                            defaultValue={pageTitle}
                            onKeyUp={(e) => setPageTitle(e.target.value)}
                            fullWidth
                            label="Page Title"
                            inputProps={{type: "text", autoComplete: ""}}
                        />
                    </Grid>

                    <Grid item xs={12} sm={12}>
                        <FormControl fullWidth sx={{m: 1}}>
                            <TextField
                                defaultValue={metaDescription}
                                onKeyUp={(e) => setMetaDescription(e.target.value)}
                                id="standard-multiline-flexible"
                                label="Meta Description"
                                multiline
                                maxRows={4}
                                // onChange={}
                                variant="standard"
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </MDBox>
        </Card>
    );
}

export default Advanced;
