/**
 =================================
 YourWebPro Contractor Portal Application V2
 NodeJS  |  React  |  MaterialUI  |  Material Dash 2
 2022 / 2023
 =================================
 ==========================================================
 Main components such as charts, boxes, sidenav, dash found in /components directory

 Layouts such at Dash, Profile, MapYourProjects, Billing are located in /layouts

 Theme files for colors and base layout definitions found in /assets/theme
 ==========================================================
 */

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";


// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Images
import masterCardLogo from "assets/images/logos/mastercard.png";
import visaLogo from "assets/images/logos/visa.png";

// Material Dashboard 2 React context
import {useMaterialUIController} from "context";
import React, {useState} from "react";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import api from "utils/axiosConfig";
import {apiUrl} from "App";

function PaymentMethod({handleAddCardOpen, billingCards}) {
    const [controller] = useMaterialUIController();
    const {darkMode} = controller;


    return (
        <Card id="payment-method">
            <MDBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">
                <MDTypography variant="h6" fontWeight="medium">
                    Payment Method
                </MDTypography>
                <MDButton variant="gradient" color="info" onClick={handleAddCardOpen}>
                    <Icon sx={{fontWeight: "bold"}}>add</Icon>
                    &nbsp;add new card
                </MDButton>
            </MDBox>
            <MDBox p={2}>
                <Grid container spacing={3}>
                    {billingCards}
                </Grid>
            </MDBox>

        </Card>

    );
}

export default PaymentMethod;
