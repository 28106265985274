import React, {useEffect, useState} from 'react';
import api from 'utils/axiosConfig';
import {apiUrl, chk} from "App.js";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Tooltip,
} from 'chart.js';
import {Line} from 'react-chartjs-2';
import Box from "@mui/material/Box";
import Select from 'react-select';
import CircularProgress from "@mui/material/CircularProgress";


ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,

    Tooltip,
);

const options = {
    responsive: true,
    plugins: {
        title: {
            display: false,
            text: 'Source of Web Traffic',
        },
        legend: {
            display: false,
        },
    },

};


// const labels = ['Oct 1', 'Oct 2', 'Oct 3', 'Oct 4', 'Oct 5', 'Oct 6', 'Oct 7',];

// console.log(labels.map(() => Math.random({ min: 0, max: 10 })));


export default function Visits() {

    const durationSelect = [
        {value: '6daysAgo', label: '7 Days'},
        {value: '13daysAgo', label: '2 Weeks'},
        {value: '29daysAgo', label: '1 Month'},
        {value: '59daysAgo', label: '2 Months'},
        {value: '89daysAgo', label: '3 Months'},
        {value: '179daysAgo', label: '6 Months'},
        {value: '364daysAgo', label: '1 Year'},
    ];


    const [labels, setLables] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState([]);


    const dataState = {
        labels,
        datasets: [
            {
                label: '',
                data,
                borderColor: 'rgb(215,12,12)',
                backgroundColor: 'rgb(196,115,115)',
            },
        ],
    };


    function handleDurationChange(e) {
        setIsLoading(true);
        const ck = chk();
        const session = ck.session;
        const cs = ck.cs;

        api.post(apiUrl() + "api/v2/analyticsV2/websiteTraffic/BounceRate/", {cs: cs, session: session, duration: e.value}).then(response => {
            console.log(response.data)
            setLables(response.data.metrics.labels)
            setData(response.data.metrics.data)
        }).then(response => {
            setIsLoading(false);
        });
    }

    useEffect(() => {
        handleDurationChange({value: "29daysAgo"})
    }, []);

    return (
        <Box>
            <Select
                isSearchable="false"
                placeholder={"Duration"}
                defaultValue={"29daysAgo"}
                onChange={e => handleDurationChange(e)}
                options={durationSelect}
            />
            <Box sx={{padding:"20px", fontSize:"12px"}}>
                The percentage of sessions that were not engaged in percentage %.
            </Box>
            {(isLoading) ?
                <Box sx={{textAlign:"center"}}>
                    <CircularProgress color="info" size={100}/>
                </Box>
                :
                <Line sx={{border: "1px solid #FF0000"}} options={options} data={dataState}/>
            }
        </Box>
    );
}
