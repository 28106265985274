import React, {useState, useEffect} from 'react';
import Stepper from "react-stepper-horizontal";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import {apiUrl, chk} from "App";
import api from "utils/axiosConfig";
import {useSnackbar} from "notistack";
import Typography from "@mui/material/Typography";
import TextField from '@mui/material/TextField';
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";


function Contact({setActiveStep, setEmailAddr, setIsGood}) {
    const {enqueueSnackbar} = useSnackbar();
    const [isLoading, setIsLoading] = useState(true)
    const [contacts, setContacts] = useState(true)
    const [invalidEmail, setInvalidEmail] = useState(true)

    useEffect(() => {

        const ck = chk();
        const session = ck.session;
        const cs = ck.cs;

        api.post(apiUrl() + "api/v2/completeSetup/contacts/", {cs: cs, session: session}).then(response => {
            if (response.data.status === 'ok') {
                let contactList = [];
                response.data.contacts.map(item => {
                    let temp = {email: item}
                    contactList.push(temp);
                })
                setContacts(contactList);
                setIsLoading(false);
                setIsGood(false)
            } else {
                const message = "Could not retrieve contact info  --  Please contact sales or support";
                const variant = "error";
                enqueueSnackbar(message, {variant});
                setIsLoading(false);
                setActiveStep(-2);
            }

        }).then(response => {

        });
    }, []);

    function handleSetEmail(e){
        setInvalidEmail(false)
        if (! e.match(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        ) ){
            setInvalidEmail(true)
        }else{
            setEmailAddr(e)
            setIsGood(true)
        }

    }

    if (isLoading) {
        return (
            <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
            >

                <Grid item xs={3}>
                    <CircularProgress color="info" size={120}/>
                </Grid>

            </Grid>
        );
    }

    return (
        <div style={{fontFamily: "Arial"}}>
            {/*<pre>*/}
            {/*     {JSON.stringify(contacts, null, 2)}*/}
            {/*</pre>*/}
            {
                (contacts) ?
                    <div>
                        Email address(s) we have on file:
                    </div>
                    :
                    <div>
                        No email address(s) on file.
                    </div>
            }
            {
                (contacts) ?
                    contacts.map(function (item, i) {
                        console.log('test');
                        return <div key={i}>{item.email}</div>
                    })
                    :
                    <></>
            }
            <br/>
            <Card style={{padding:10}}>
                <Typography>
                    As the signer, we need a way to get a hold of you if there are issues with your account.
                    Please provide us with an email address that you check often and is a valid way to get you personally.
                    Please do not provide an address that is a "catch all" as correspondence pertaining to the health
                    of your website and/or important notices might get missed.<br /><br />
                    <b>We value your privacy --  This email is for notices and updates, and will not be used for marketing purposes</b>
                </Typography>
                <TextField
                    style={{backgroundColor: "#FFF", width: "300px", marginTop: "20px"}}
                    id="outlined-helperText"
                    label="Email Address"
                    onKeyUp={function (e) {
                        handleSetEmail(e.target.value);
                    }}
                />
                {(invalidEmail) ?
                    <Box><p style={{color: "#FF0000", fontSize: "12px"}}>Email address appears to be invalid:</p>
                    </Box> : <Box><p style={{ fontSize: "12px"}}>&nbsp;</p>
                    </Box>
                }
            </Card>
        </div>
    );
}

export default Contact;