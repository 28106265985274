/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Billing page components
import Newsletter from "../Newsletter";
import React, {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import {apiUrl, chk} from "App";
import api from "utils/axiosConfig";



function Banner() {
    const [loading, setLoading] = useState(false);

    const [image, setImage] = useState({
        imageSrc: '/imageserver/YWPGallery/banner.png',
        imageHash: Date.now()
    });


    // useEffect(() => {
    //     // axios.defaults.withCredentials = true;
    //     const ck = chk();
    //     const session = ck.session;
    //     const cs = ck.cs;
    //
    //     api.post(apiUrl() + "api/v2/Newsletters/", {cs: cs, session: session}).then(response => {
    //         if (response.data.status === 'ok') {
    //             setNewsLetters(response.data.newletters);
    //             setNewsLettersError(false);
    //         } else {
    //             setNewsLettersError(true);
    //         }
    //         setLoading(false);
    //     }).then(response => {
    //
    //     });
    // }, []);

    if (loading) {
        return (
            <Box sx={{height: "400px"}}>
                <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    style={{height: '400px'}}
                >

                    <Grid item xs={3}>
                        <CircularProgress color="info" size={120}/>
                    </Grid>

                </Grid>
            </Box>
        )
    } else {

        return (
            <Box>
                <Card sx={{textAlign:"center"}}>
                        <MDBox p={2}>
                            <img style={{maxWidth:"95%"}} key={Date.now()} src={`${image.imageSrc}?${image.imageHash}`} />
                        </MDBox>
                </Card>
            </Box>
        );
    }
}

export default Banner;
