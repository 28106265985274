import React, {useEffect, useState} from 'react';
import api from 'utils/axiosConfig';
import {apiUrl, chk} from "App.js";

import {Line} from 'react-chartjs-2';
import Box from "@mui/material/Box";
import Select from 'react-select';
import CircularProgress from "@mui/material/CircularProgress";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);



export default function Engagement() {

    const durationSelect = [
        {value: '6daysAgo', label: '7 Days'},
        {value: '13daysAgo', label: '2 Weeks'},
        {value: '29daysAgo', label: '1 Month'},
        {value: '59daysAgo', label: '2 Months'},
        {value: '89daysAgo', label: '3 Months'},
        {value: '179daysAgo', label: '6 Months'},
        {value: '364daysAgo', label: '1 Year'},
    ];

    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);
    const [submissions, setSubmissions] = useState(0);

    function handleDurationChange(e) {
        setIsLoading(true);
        const ck = chk();
        const session = ck.session;
        const cs = ck.cs;

        api.post(apiUrl() + "api/v2/analyticsV2/trafficByChannel/", {cs: cs, session: session, duration: e.value}).then(response => {
            console.log(response.data)
            setData(response.data.data)
            setSubmissions(response.data.submissions)
        }).then(response => {
            setIsLoading(false);
        });
    }

    useEffect(() => {
        handleDurationChange({value: "29daysAgo"})
    }, []);


    const options = {
        responsive: true,
        plugins: {
            title: {
                display: false,
                text: 'Source of Web Traffic',
            },
            legend: {
                display: false,
            },
        },

    };


    return (
        <Box>
            <Select
                isSearchable="false"
                placeholder={"Duration"}
                defaultValue={"29daysAgo"}
                onChange={e => handleDurationChange(e)}
                options={durationSelect}
            />
            {(isLoading) ?
                <Box sx={{textAlign:"center"}}>
                    <CircularProgress color="info" size={100}/>
                </Box>
                :

                <Bar options={options} data={data} />

            }
<br /><hr /><br />
            <MDBox bgColor="#154676">
                <MDBox p={1} mx={3} display="flex" alignItems="center" justifyContent="center">
                    <MDTypography color="white" fontSize="2rem">
                        {submissions}
                    </MDTypography>
                </MDBox>
                <MDBox pb={2} textAlign="center">
                    <MDTypography color="white" variant="h6" fontWeight="medium" textTransform="capitalize">
                        Form Submissions Last 30 Days
                    </MDTypography>
                </MDBox>
            </MDBox>
        </Box>
    );
}
