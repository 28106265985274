/**
 =================================
 YourWebPro Contractor Portal Application V2
 NodeJS  |  React  |  MaterialUI  |  Material Dash 2
 2022 / 2023
 =================================
 ==========================================================
 Main components such as charts, boxes, sidenav, dash found in /components directory

 Layouts such at Dash, Profile, MapYourProjects, Billing are located in /layouts

 Theme files for colors and base layout definitions found in /assets/theme
 ==========================================================
 */

import React, {useState, useEffect} from "react";
import {useLocation, Link} from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";
import GoogleIcon from '@mui/icons-material/Google';
import MDBox from "components/MDBox";
import Breadcrumbs from "components/Breadcrumbs";
import NotificationItem from "components/Items/NotificationItem";
import {
    navbar,
    navbarContainer,
    navbarRow,
    navbarIconButton,
    navbarMobileMenu,
} from "components/Navbars/DashboardNavbar/styles";
import {
    useMaterialUIController,
    setTransparentNavbar,
    setMiniSidenav,
} from "context";
import Cookies from 'universal-cookie';
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import {apiUrl, chk} from "App.js";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import MDButton from "components/MDButton";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import api from "utils/axiosConfig";
import Box from "@mui/material/Box";
import Select from 'react-select'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
const cookies = new Cookies();

function DashboardNavbar({absolute, light, isMini}) {
    const [navbarType, setNavbarType] = useState();
    const [controller, dispatch] = useMaterialUIController();
    const {miniSidenav, transparentNavbar, fixedNavbar, openConfigurator, darkMode} = controller;
    const [openMenu, setOpenMenu] = useState(false);
    const route = useLocation().pathname.split("/").slice(1);
    const [isLoading, setLoading] = useState(false);
    const [adm, setAdm] = useState(false);
    const [admOpen, setAdmOpen] = useState(false);
    const [admContractor, setAdmContractor] = useState([]);
    const [admLoading, setAdmLoading] = useState(false);

    useEffect(() => {
        const ck = chk();
        const adm = ck.adm;
        setAdm(adm);
    });

    useEffect(() => {
        // Setting the navbar type
        if (fixedNavbar) {
            setNavbarType("sticky");
        } else {
            setNavbarType("static");
        }

        // A function that sets the transparent state of the navbar.
        function handleTransparentNavbar() {
            setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
        }

        /**
         The event listener that's calling the handleTransparentNavbar function when
         scrolling the window.
         */
        window.addEventListener("scroll", handleTransparentNavbar);

        // Call the handleTransparentNavbar function to set the state with the initial value.
        handleTransparentNavbar();

        // Remove event listener on cleanup
        return () => window.removeEventListener("scroll", handleTransparentNavbar);
    }, [dispatch, fixedNavbar]);

    const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
    const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
    const handleCloseMenu = () => setOpenMenu(false);

    function logOut() {
        // setLoading(true);

        // Make axios call to remove session
        cookies.remove('count', {path: '/'});
        cookies.remove('session', {path: '/'});
        cookies.remove('sa', {path: '/'});
        window.location.reload(false);
    }


    // Render the notifications menu
    const renderMenu = () => (
        <Menu
            anchorEl={openMenu}
            anchorReference={null}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
            }}
            open={Boolean(openMenu)}
            onClose={handleCloseMenu}
            sx={{mt: 2}}
        >
            <NotificationItem icon={<Icon>person</Icon>} title="Profile" badge="" goto="/profile"/>
            {/*<NotificationItem icon={<Icon>email</Icon>} title="Notifications" badge="5" goto="/notifications"/>*/}
            <NotificationItem icon={<Icon>email</Icon>} title="Notifications" goto="/notifications"/>
            <NotificationItem icon={<Icon>logout</Icon>} title="Log Out" badge="" onClick={logOut}/>
            {(adm === 'true') ? <NotificationItem icon={<Icon>login</Icon>} title="Log In As" badge="" onClick={handleAdmOpen}/> : <></>}
        </Menu>
    );

    // Styles for the navbar icons
    const iconsStyle = ({palette: {dark, white, text}, functions: {rgba}}) => ({
        color: () => {
            let colorValue = light || darkMode ? white.main : dark.main;

            if (transparentNavbar && !light) {
                colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
            }

            return colorValue;
        }
    });

    const badgeStyle = ({palette: {success, ywpWhite, ywpYellow}}) => ({
        background: () => {
            return ywpYellow.main
        },
        color: ywpWhite.main,
        position: "absolute",
        top: '10px',
        right: '7px',
        width: "15px",
        height: "15px",
        fontSize: "12px",
        fontWeight: "bold",
        borderRadius: "50%"
    });

    if (isLoading) {
        return (
            <div className="App" style={{
                position: "fixed",
                top: "0",
                left: "0",
                zIndex: "9999",
                width: "100%",
                height: "100vh",
                backgroundColor: "#FFF",
            }}>
                <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    style={{minHeight: '80vh'}}
                >
                    <Grid item xs={3}>
                        <CircularProgress color="info" size={120}/>
                    </Grid>
                </Grid>
            </div>
        )
    }
    const [isDeviceMobile, setIsMobile] = useState(false);

    function handleWindowSizeChange() {
        if (window.innerWidth <= 768) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
    }

    useEffect(() => {
        handleWindowSizeChange();
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);
    const [contractorList, setContractorList] = useState([]);
    const [contractorListLoading, setContractorListLoading] = useState(false);
    const handleAdmOpen = (e) => {
        setOpenMenu(false);
        const ck = chk();
        const session = ck.session;
        const cs = ck.cs;
        setAdmOpen(true);
        setContractorListLoading(true);
        api.post(apiUrl() + "api/v2/login/as/getAll/", {cs: cs, session: session}).then(response => {
            console.log(response.data);
            if (response.data.status === "ok") {
                setContractorList(response.data);

                const list = [
                    {label: 'foo', value: 1},
                    {label: 'bar', value: 2},
                    {label: 'bin', value: 3},
                ]

                let contractorList = [];
                response.data.contractors.map(item => {
                    let temp = {label: item.contractor, value: item.id}
                    contractorList.push(temp);
                })
                setContractorList(contractorList);

            }
            setContractorListLoading(false);
        });
    };
    const handleAdmClose = (e) => {
        setAdmOpen(false);
    };

    function handleAdmChange(e) {
        const contractor_id = e.value;
        setAdmContractor(contractor_id);
    }


    function handleAdmLogin() {
        //alert(admContractor);
        const ck = chk();
        const session = ck.session;
        const cs = ck.cs;
        setAdmLoading(true)
        api.post(apiUrl() + "api/v2/login/as/", {
            cs: cs,
            session: session,
            cid: admContractor
        }).then(response => {
            // alert(response.data);
            cookies.set('session', session, {path: '/'});
            cookies.set('count', admContractor, {path: '/'});
            cookies.set('contractor', response.data.contractor, {path: '/'});
            cookies.set('adm', 0, {path: '/'});
            window.location.reload(false);
        });

    }

    return (
        <AppBar
            position={absolute ? "absolute" : navbarType}
            color="inherit"
            sx={(theme) => navbar(theme, {transparentNavbar, absolute, light, darkMode})}
        >
            <Toolbar sx={(theme) => navbarContainer(theme)} style={{
                width: (isDeviceMobile) ? "95vw" : '',
                overflow: "hidden"
            }}>
                <MDBox color="inherit" mb={{xs: 1, md: 0}} sx={(theme) => navbarRow(theme, {isMini})}>
                    <Breadcrumbs icon="home" title={route[route.length - 1]} route={route} light={light}/>
                </MDBox>
                {isMini ? null : (
                    <MDBox sx={(theme) => navbarRow(theme, {isMini})}>
                        {/*<MDBox pr={1}>*/}
                        {/*<MDInput label="Search here" />*/}
                        {/*</MDBox>*/}
                        <MDBox color={light ? "white" : "inherit"} style={{width: "100%"}}>

                            <IconButton
                                disableRipple
                                color="inherit"
                                sx={navbarMobileMenu}
                                onClick={handleMiniSidenav}
                            >
                                <Icon sx={iconsStyle} fontSize="large">
                                    {miniSidenav ? "menu_open" : "menu"}
                                </Icon>
                            </IconButton>
                            {/*<IconButton*/}
                            {/*size="small"*/}
                            {/*disableRipple*/}
                            {/*color="inherit"*/}
                            {/*sx={navbarIconButton}*/}
                            {/*onClick={handleConfiguratorOpen}*/}
                            {/*>*/}
                            {/*<Icon sx={iconsStyle}>settings</Icon>*/}
                            {/*</IconButton>*/}

                            <IconButton
                                sx={navbarIconButton}
                                size="large"
                                disableRipple
                                onClick={handleOpenMenu}>
                                <Icon sx={iconsStyle}>account_circle</Icon>
                                {/*<MDBox sx={badgeStyle}>!</MDBox>*/}
                            </IconButton>

                            <IconButton
                                size="large"
                                disableRipple
                                color="inherit"
                                sx={navbarIconButton}
                                aria-controls="notification-menu"
                                aria-haspopup="true"
                                variant="contained"
                            >
                                <Icon sx={iconsStyle}>notifications</Icon>
                                {/*<MDBox sx={badgeStyle}>2</MDBox>*/}
                            </IconButton>
                            {renderMenu()}
                        </MDBox>
                    </MDBox>
                )}
            </Toolbar>
            <Dialog
                open={admOpen}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleAdmClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{"Log In As Contractor"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        {
                            (contractorListLoading) ?
                                <Box sx={{textAlign: "center"}}>
                                    <CircularProgress color="info" size={75}/>
                                </Box> :
                                <Box sx={{height: "300px"}}>
                                    Search for Contractor to log in as<br/>
                                    You will need log back in as administrator to switch accounts.
                                    <br/><br/>

                                    <br/>
                                    <Select options={contractorList} onChange={e => handleAdmChange(e)}/>
                                    {/*<pre>*/}
                                    {/*    {JSON.stringify(contractorList, null, 2)}*/}
                                    {/*</pre>*/}
                                </Box>
                        }
                    </DialogContentText>
                </DialogContent>

                <DialogActions>
                    {(admLoading) ? <CircularProgress color="info" size={40}/> :
                        <Box>
                            <MDButton onClick={handleAdmClose} variant="outlined" color="info">Cancel</MDButton>
                            <MDButton onClick={handleAdmLogin} variant="outlined" color="success">Log In As</MDButton>
                        </Box>
                    }
                </DialogActions>
            </Dialog>

        </AppBar>
    );
}

export default DashboardNavbar;
