/**
 =================================
 YourWebPro Contractor Portal Application V2
 NodeJS  |  React  |  MaterialUI  |  Material Dash 2
 2022 / 2023
 =================================
 ==========================================================
 Main components such as charts, boxes, sidenav, dash found in /components directory

 Layouts such at Dash, Profile, MapYourProjects, Billing are located in /layouts

 Theme files for colors and base layout definitions found in /assets/theme
 ==========================================================
 */

// @material-ui core components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import colors from "assets/theme/base/colors"

const ywpBlue = colors.ywpBlue.main;
const ywpWhite = colors.ywpWhite.main;

// Settings page components
import FormField from "components/FormField";

import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import React, {useEffect} from "react";
import Icon from "@mui/material/Icon";
import {apiUrl, chk} from "App";
import api from "utils/axiosConfig";
import {useSnackbar} from "notistack";





function FilterTags({
                        filterTags,
                        setFilterTags
                    }) {
    const [newTag, setNewTag] = React.useState('');

    const [selectData, setSelectData] = React.useState({
        filters: ["GAF", "Certainteed", "Black"],
    });

    useEffect(() => {
        // axios.defaults.withCredentials = true;
        const ck = chk();
        const session = ck.session;
        const cs = ck.cs;

        api.post(apiUrl() + "api/v2/myp/getTags/", {cs: cs, session: session}).then(response => {
            if (response.data.status === 'ok') {
            console.log(response.data.tags)
                setSelectData({filters: response.data.tags})
            }

        })
    }, []);

    const {enqueueSnackbar} = useSnackbar();

    function handleAddTag() {
        let tmpData = selectData.filters;
        tmpData.push(newTag);
        setSelectData({ filters: tmpData } );
        tmpData = filterTags;
        tmpData.push(newTag);
        setFilterTags(tmpData);


        setNewTag('');
    }


    return (
        <Card id="basic-info" sx={{overflow: "visible"}}>
            <MDBox p={3}>
                <MDTypography variant="h5">Filter Tags</MDTypography>
            </MDBox>
            <MDBox component="form" pb={3} px={3}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Autocomplete
                            multiple
                            options={selectData.filters}
                            filterSelectedOptions
                            sx={{
                                width: "100%"
                            }}
                            ChipProps={{
                                style: {
                                    backgroundColor: ywpBlue,
                                    borderRadius: "5px",
                                    color: ywpWhite,
                                    fontFamily: "Source Sans Pro",
                                },
                            }}
                            value={filterTags}
                            onChange={(event, value) => setFilterTags(value)}
                            renderInput={(params) => <FormField {...params} InputLabelProps={{shrink: true}}/>}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={3} mt={3} ml={3} p={3}>
                    <Grid item xs={3}>
                        <MDInput
                            fullWidth
                            label="Add Tag"
                            inputProps={{type: "text", autoComplete: ""}}
                            onChange={(e) => setNewTag(e.target.value)}
                            value={newTag}
                        />
                    </Grid>
                    <Grid item xs={3} mt={.5}>
                        <MDButton
                            variant="outlined"
                            color="success"
                            size="small"
                            sx={{fontSize: "16px"}}
                            onClick={handleAddTag}
                        >
                            <Icon sx={{color: ywpBlue}} title="Grid View">add</Icon> Add New Tag To List
                        </MDButton>
                    </Grid>
                </Grid>
            </MDBox>
        </Card>
    )
        ;
}

export default FilterTags;
