import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: true,
      text: 'Source of Web Traffic',
    },
  },
};

const labels = ['Oct 1', 'Oct 2', 'Oct 3', 'Oct 4', 'Oct 5', 'Oct 6',];

export const data = {
  labels,
  datasets: [
    {
      label: 'Direct',
      data: labels.map(() => Math.random({ min: 0, max: 10 })),
      borderColor: 'rgb(255, 99, 132)',
      backgroundColor: 'rgba(255, 99, 132, 0.5)',
    },
    {
      label: 'Organic',
      data: labels.map(() => Math.random({ min: 0, max: 10 })),
      borderColor: 'rgb(0,0,0)',
      backgroundColor: '#999999',
    },
    {
      label: 'Referral',
      data: labels.map(() => Math.random({ min: 0, max: 10 })),
      borderColor: '#268026',
      backgroundColor: '#00CC00',
    },
    {
      label: 'Other',
      data: labels.map(() => Math.random({ min: 0, max: 10 })),
      borderColor: '#FFA500',
      backgroundColor: '#806026',
    },
  ],
};

export function TrafficSource() {
  return <Line options={options} data={data} />;
}
