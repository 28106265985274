// @mui material components
import React, {useEffect} from "react";
import Grid from "@mui/material/Grid";

import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import Footer from "components/Footer";
import PageLoading from "components/PageLoading"
import {apiUrl, chk} from "App";
import api from "utils/axiosConfig";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import MDInput from "components/MDInput";
import {Icon} from "@mui/material";
import {useSnackbar} from "notistack";
import Slide from "@mui/material/Slide";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import MDButton from "../../components/MDButton";


function AdWords() {


    return (
        <DashboardLayout>
            <DashboardNavbar/>


            <Paper sx={{padding: "30px"}}>


                <div style={{padding: " 100px 15px", backgroundColor: " #124676", backgroundImage: "linear-gradient(155deg, rgba(18, 70, 118, .5) 12%, rgba(18, 70, 118, .9) 12%, rgba(18, 70, 118, .9) 88%, rgba(18, 70, 118, .5) 88%), url('https:yourwebpro.com/imageserver/UserMedia/yourwebproflagship/Google/hero_bg.jpg')", backgroundSize: "cover", backgroundPosition: "center 15%"}}>
                    <div style={{position: " relative", boxSizing: " border-box", textTransform: " uppercase", margin: " 0 auto", maxWidth: " 1450px", width: " 100%", textAlign: " center"}}>
                        <p style={{fontSize: " 24px", margin: " 5px auto 15px", color: " #feba12", fontWeight: " 600", lineHeight: " 30px"}}>Google not working? Don't guess your Google positioning</p>

                        <p style={{fontSize: " 36px", color: " #fff", fontWeight: " 700", margin: " 0", letterSpacing: " 2px", lineHeight: " 42px"}}>Get On Page One Today!</p>
                    </div>
                </div>


                <div style={{textAlign: " center", padding: " 50px 15px"}}>
                    <div style={{boxSizing: " border-box", maxWidth: " 1450px", width: " 100%", margin: " 0 auto"}}>
                        <img style={{maxWidth: " 160px", width: " 100%", height: " auto", margin: " 25px auto 40px"}}
                             alt="Google Ads logo"
                             width="400"
                             height="500"
                             src={"https://yourwebpro.com/imageserver/UserMedia/yourwebproflagship/Google/google_ads_logo.png"}/>
                        <p style={{fontSize: "36px", color: "#124676", fontWeight: "700", margin: " 0", letterSpacing: " 2px", lineHeight: " 42px:"}}>What Works? AdWords!</p>
                        <hr style={{border: " none", borderTop: " 2px solid #feba12", width: " 100px", margin: " 25px auto"}}/>
                        <p>Let us manage a campaign that works. We will show you monthly. Meet with your representative and know your Return on Investment. Stop anytime.</p>
                    </div>
                </div>

                <div style={{padding: " 80px 15px", boxShadow: " 0px 0px 15px 0px rgba(0, 0, 0, .16)", backgroundColor: " #f8f8f8"}}>

                    <p style={{fontSize: " 36px", margin: " 0 auto", color: " #124676", fontWeight: " 700", letterSpacing: " 2px", lineHeight: " 42px", textAlign: " center"}}>Marketing That Proves Its Worth</p>

                    <hr style={{border: " none", borderTop: " 2px solid #feba12", width: " 100px", margin: " 25px auto"}}/>

                    <div style={{display: " flex", flexWrap: " wrap", justifyContent: " center", boxSizing: " border-box", alignItems: " center", maxWidth: " 1450px", width: " 100%", margin: " 40px auto 0"}}>

                        <div style={{position: " relative", maxWidth: " 520px", width: " 100%"}}>
                            <span style={{display: " block", position: " absolute", width: " 100%", height: " 2px", backgroundColor: " #feba12", top: " 50%", left: " 0", marginTop: " -1px"}}></span>

                            <div style={{position: " relative", backgroundColor: " #124676", color: " #fff", borderRadius: " 50%", border: " 3px solid #feba12", width: " 275px", height: " 275px", display: " flex", alignItems: " center", justifyContent: " center", margin: " 25px auto"}}>
                                <span style={{position: " absolute", width: " 20px", height: " 20px", backgroundColor: " #124676", top: " 0", left: " 15px", borderRadius: " 50%", border: " 2px solid #feba12"}}></span>
                                <span style={{position: " absolute", width: " 40px", height: " 40px", backgroundColor: " #124676", top: " 15px", left: " -30px", borderRadius: " 50%", border: " 2px solid #feba12"}}></span>
                                <span style={{position: " absolute", width: " 30px", height: " 30px", backgroundColor: " #124676", bottom: " 0", right: " 0", borderRadius: " 50%", border: " 2px solid #feba12"}}></span>

                                <p style={{fontSize: " 70px", fontWeight: " 600", textAlign: " center", maxWidth: " 185px", width: " 100%", margin: " 0 auto", lineHeight: " 42px", letterSpacing: " 2px"}}>
                                    <span style={{display: " block", textAlign: " left", fontSize: " .33em", color: " #feba12", marginBottom: " 15px"}}>only</span> $150 <span style={{display: " block", textAlign: " right", fontSize: " .33em", color: " #feba12", marginTop: " 8px"}}>a month</span>
                                </p>
                            </div>
                        </div>


                        <div style={{maxWidth: " 900px", width: " 100%"}}>
                            <div style={{boxShadow: " 3px 3px 6px #bcbcbc", padding: " 15px 15px 35px", backgroundColor: " #fff", borderRadius: " 12px"}}>
                                <ul style={{paddingLeft: " 35px"}}>
                                    <li style={{marginBottom: " 12px"}}>Research and develop a keyword plan for a successful campaign</li>
                                    <li style={{marginBottom: " 12px"}}>Set up or optimize your Google My Business</li>
                                    <li style={{marginBottom: " 12px"}}>We will report what works and what's not working</li>
                                    <li style={{marginBottom: " 12px"}}>Calculate your ROI and your average lead cost</li>
                                    <li style={{marginBottom: " 12px"}}>Portal usage to analyze stats</li>
                                </ul>

                                <p style={{color: " #888", fontSize: " 15px", fontStyle: " italic", margin: " 25px 0 0 35px"}}>Note:" Google Management fee does not include Google Budget. A Google Advisor will determine your budget based off your competition.</p>
                            </div>
                        </div>

                    </div>
                    <div style={{padding: " 80px 15px 50px"}}>
                        <div style={{boxSizing:" border-box", maxWidth:" 1450px", width:" 100%", margin:" 0 auto"}}>
                            <div style={{position: " relative", textTransform:" uppercase", padding:" 25px 0"}}>
                                <span style={{position: " absolute", display: " block", width: " 65px", height: " 65px", borderTop:" 8px solid #feba12", borderLeft:" 8px solid #feba12", margin:" 0", top:" 0", left:" -25px"}}></span>

                                <p style={{fontSize:" 24px", margin:" 5px auto 15px", color:" #feba12", fontWeight:" 600", lineHeight:" 30px"}}>Here's a protip</p>

                                <p style={{fontSize:" 36px", color:" #124676", fontWeight:" 700", margin:" 0", letterSpacing:" 2px", lineHeight:" 42px"}}>Super Charge Your Conversions With Professional Call Outs</p>
                            </div>

                            <p style={{margin: " 15px 0"}}>Leave something interesting to find for new perspective customers. Add callouts and announcements like:"</p>

                            <div style={{display: " flex", flexWrap:" wrap", justifyContent:" center", margin:" 50px auto"}}>
                                <img style={{maxWidth:" 300px", width:" 100%", height:" auto", margin:" 8px"}} width="800" height="600" src={"https://yourwebpro.com/imageserver/UserMedia/yourwebproflagship/2023/LandingPages/24-7_emergency_services_sample_modal.jpg"}/>
                                <img style={{maxWidth:" 300px", width:" 100%", height:" auto", margin:" 8px"}} width="800" height="600" src={"https://yourwebpro.com/imageserver/UserMedia/yourwebproflagship/2023/LandingPages/financing_available_sample_modal.jpg"}/>
                                <img style={{maxWidth:" 300px", width:" 100%", height:" auto", margin:" 8px"}} width="800" height="600" src={"https://yourwebpro.com/imageserver/UserMedia/yourwebproflagship/2023/LandingPages/free_gutters_with_a_new_roof_sample_modal.jpg"}/>
                                <img style={{maxWidth:" 300px", width:" 100%", height:" auto", margin:" 8px"}} width="800" height="600" src={"https://yourwebpro.com/imageserver/UserMedia/yourwebproflagship/2023/LandingPages/HomeShow.jpg"}/>
                                <img style={{maxWidth:" 300px", width:" 100%", height:" auto", margin:" 8px"}} width="800" height="600" src={"https://yourwebpro.com/imageserver/UserMedia/yourwebproflagship/2023/LandingPages/military-discounts.jpg"}/>
                                <img style={{maxWidth:" 300px", width:" 100%", height:" auto", margin:" 8px"}} width="800" height="600" src={"https://yourwebpro.com/imageserver/UserMedia/yourwebproflagship/2023/LandingPages/military-discounts2.jpg"}/>
                                <img style={{maxWidth:" 300px", width:" 100%", height:" auto", margin:" 8px"}} width="800" height="600" src={"https://yourwebpro.com/imageserver/UserMedia/yourwebproflagship/2023/LandingPages/senior_discount.jpg"}/>
                                <img style={{maxWidth:" 300px", width:" 100%", height:" auto", margin:" 8px"}} width="800" height="600" src={"https://yourwebpro.com/imageserver/UserMedia/yourwebproflagship/2023/LandingPages/storm_damage_sample_modal.jpg"}/>
                            </div>
                        </div>
                    </div>

                    <div style={{padding:" 70px 15px", backgroundColor:" #124676"}}>
                        <div style={{boxSizing:" border-box", margin:" 0 auto", textTransform:" uppercase", maxWidth:" 1450px", width:" 100%", textAlign:" center"}}>
                            <p style={{fontSize:" 24px", margin:" 0 auto", color:" #fff", fontWeight:" 600", letterSpacing:" 1px"}}><span style={{color:" #feba12"}}>Be online within 24 hours.</span>  <a style={{color:"#FFF" }} href={"https://calendly.com/ywp-marketing/15min-google"} target={"_blank"}>Schedule an appointment.</a></p>
                        </div>
                    </div>
                    

                </div>
                

            </Paper>
            <Footer/>

        </DashboardLayout>
    );
}

export default AdWords;
