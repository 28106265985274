import React, {useEffect, useState} from "react";
import Box from "@mui/material/Box";

import colors from "assets/theme/base/colors";
import Slide from "@mui/material/Slide";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

const ywpBlue = colors.ywpBlue.main;
const ywpDarkBlue = colors.ywpBlue.focus;
const ywpYellow = colors.ywpYellow.main;
const ywpDarkYellow = colors.ywpYellow.focus;

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


function TabPanel(props: TabPanelProps) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </div>
    );
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}


const Visitors = () => {
    const [value, setValue] = React.useState(0);
    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };
    return (
        <Box mt={4} p={2} sx={{flexGrow: 1}}>
            <Tabs
                value={value}
                onChange={handleTabChange}
                aria-label="metrics tabs"
                TabIndicatorProps={{
                    style: {
                        backgroundColor: ywpDarkBlue,
                    }
                }}
                sx={{
                    color:"#FF0000",
                    "& button:hover": { background: ywpBlue, color: "#FFF" },
                    "& button.Mui-selected": { background: ywpYellow,color:ywpDarkBlue },

                }}

                textColor="secondary"
            >
                <Tab label="By Page" {...a11yProps(0)} />
                <Tab label="By Time" {...a11yProps(1)} />
                <Tab label="By Device" {...a11yProps(2)} />

            </Tabs>
            <Box sx={{backgroundColor:"#FFF", marginTop:"10px", borderRadius:"5px"}}>
                <TabPanel value={value} index={0}>
                    <Box>
                        Visitors Stuff 1
                    </Box>
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <Box>
                        Visitors Stuff 2
                    </Box>
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <Box>
                        Visitors Stuff 3
                    </Box>
                </TabPanel>
            </Box>
        </Box>
    );
};

export default Visitors;
