import React, {useEffect, useState} from 'react';
import api from 'utils/axiosConfig';
import {apiUrl, chk} from "App.js";
import Box from "@mui/material/Box";
import Select from 'react-select';
import {DataGrid} from "@mui/x-data-grid";
import CircularProgress from "@mui/material/CircularProgress";


export default function Engagement() {

    const durationSelect = [
        {value: '6daysAgo', label: '7 Days'},
        {value: '13daysAgo', label: '2 Weeks'},
        {value: '29daysAgo', label: '1 Month'},
        {value: '59daysAgo', label: '2 Months'},
        {value: '89daysAgo', label: '3 Months'},
        {value: '179daysAgo', label: '6 Months'},
        {value: '364daysAgo', label: '1 Year'},
    ];


    // const [labels, setLables] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    // const [data, setData] = useState([]);
    const [rows, setRows] = useState([]);

    function handleDurationChange(e) {
        setIsLoading(true);
        const ck = chk();
        const session = ck.session;
        const cs = ck.cs;

        api.post(apiUrl() + "api/v2/analyticsV2/sessionByCity/", {cs: cs, session: session, duration: e.value}).then(response => {
            setRows(response.data.metrics);
        }).then(response => {
            setIsLoading(false);
        });
    }

    useEffect(() => {
        handleDurationChange({value: "26daysAgo"})
    }, []);



    const columns = [
        {
            field: 'id',
            headerName: ' ',
            width: 5,
        },

        {
            field: 'city',
            headerName: 'City',
            width: 250,
            renderCell: (params) => (
                <span style={{cursor: "pointer", fontWeight: "bold", color: "#4157de"}}>{params.value}</span>
            )
        },

        {
            field: 'session',
            headerName: 'Sessions',
            type: 'number',
            align: 'center',
            width: 100,
        },

        {
            field: 'user',
            headerName: 'New Visitors',
            type: 'number',
            align: 'center',
            width: 125,
        }
    ];

    return (
        <Box>
            <Select
                isSearchable="false"
                placeholder={"Duration"}
                defaultValue={"29daysAgo"}
                onChange={e => handleDurationChange(e)}
                options={durationSelect}
            />
            {(isLoading) ?
                <Box sx={{textAlign:"center"}}>
                    <CircularProgress color="info" size={120}/>
                </Box>
                :
                // <Line sx={{border: "1px solid #FF0000"}} options={options} data={dataState}/>
                <DataGrid
                    rows={rows}
                    columns={columns}
                    initialState={{
                        pagination: {
                            paginationModel: {page: 0, pageSize: 10},
                        },
                    }}
                    pageSizeOptions={[10]}
                    // onCellClick={handleOnCellClick}
                    disableRowSelectionOnClick
                    // checkboxSelection
                />
            }
        </Box>
    );
}
