import Grid from "@mui/material/Grid";
import React, {useEffect, useState} from "react";
// My code
import {styled} from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import {Typography} from "@mui/material";
import colors from "assets/theme/base/colors";


import TodayIcon from "@mui/icons-material/Today";
import WifiIcon from "@mui/icons-material/Wifi";
import DateRangeIcon from "@mui/icons-material/DateRange";
import MDBox from "components/MDBox";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import {apiUrl, chk} from "../../../../App";
import api from "../../../../utils/axiosConfig";
import Invoice from "../../../billing/components/Invoice";


const Item = styled(Paper)(({theme}) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    marginBottom: theme.spacing(2),
}));

const Visitors = ({ywpYellow,
                      setVisitsMonthLoading,
                      visitsMonthLoading,
                      setVisitsTodayLoading,
                      visitsTodayLoading,
                      setVisitsWeekLoading,
                      visitsWeekLoading,
}) => {
    const [today, setToday] = useState('0');
    const [thisWeek, setThisWeek] = useState('0');
    const [live, setLive] = useState('0');


    useEffect(() => {
        // axios.defaults.withCredentials = true;
        const ck = chk();
        const session = ck.session;
        const cs = ck.cs;

        api.post(apiUrl() + "api/v2/analytics/Visitors/Month/", {cs: cs, session: session}).then(response => {
            if (response.data.status === 'ok') {
                setLive(response.data.visits_this_month);
            } else {
                setLive('[0]');
            }
            setVisitsMonthLoading(false);
        }).then(response => {

        });
    }, []);
    useEffect(() => {
        // axios.defaults.withCredentials = true;
        const ck = chk();
        const session = ck.session;
        const cs = ck.cs;

        api.post(apiUrl() + "api/v2/analytics/Visitors/Today/", {cs: cs, session: session}).then(response => {
            if (response.data.status === 'ok') {
                setToday(response.data.visits_today);
            } else {
                setToday('[0]');
            }
            setVisitsTodayLoading(false);
        }).then(response => {

        });
    }, []);
    useEffect(() => {
        // axios.defaults.withCredentials = true;
        const ck = chk();
        const session = ck.session;
        const cs = ck.cs;

        api.post(apiUrl() + "api/v2/analytics/Visitors/ThisWeek/", {cs: cs, session: session}).then(response => {
            if (response.data.status === 'ok') {
                setThisWeek(response.data.visits_this_week);
            } else {
                setThisWeek('[0]');
            }
            setVisitsWeekLoading(false);
        }).then(response => {

        });
    }, []);


    return (
        <MDBox p={2} mt={3} mb={3} sx={{flexGrow: 1}}>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={4}>
                    <Paper
                        style={{
                            height: "30px",
                            width: "30px",
                            color: "#000",
                            padding: "5px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            margin: "0 0 -15px 15px",
                            position: "relative",
                            textAlign: "center"
                        }}
                    >
                        <TodayIcon backgroundcolor='ywpYellow'/>
                    </Paper>
                    <Item sx={{textAlign: "center"}}>
                        {(visitsTodayLoading) ?
                            <Box>
                                <CircularProgress color="info" size={58}/>
                            </Box>
                            :
                            <Box sx={{textAlign: "center"}}>
                                <Typography>{today}</Typography>
                                <Typography>Visitors Today</Typography>
                            </Box>
                        }
                    </Item>


                </Grid>
                <Grid item xs={12} sm={4}>
                    <Paper
                        style={{
                            height: "30px",
                            width: "30px",
                            backgroundColor: {ywpYellow},
                            color: "#000",
                            padding: "5px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            margin: "0 0 -15px 15px",
                            position: "relative",
                        }}
                    >
                        <DateRangeIcon/>
                    </Paper>
                    <Item sx={{textAlign: "center"}}>
                        {(visitsWeekLoading) ?
                            <Box>
                                <CircularProgress color="info" size={58}/>
                            </Box>
                            :
                            <Box sx={{textAlign: "center"}}>
                                <Typography>{thisWeek}</Typography>
                                <Typography>Visitors This Week</Typography>
                            </Box>
                        }
                    </Item>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Paper
                        style={{
                            height: "30px",
                            width: "30px",
                            backgroundColor: "ywpYellow",
                            color: "#000",
                            padding: "5px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            margin: "0 0 -15px 15px",
                            position: "relative",
                        }}
                    >
                        <DateRangeIcon/>
                    </Paper>
                    <Item sx={{textAlign: "center"}}>
                        {(visitsMonthLoading) ?
                            <Box>
                                <CircularProgress color="info" size={58}/>
                            </Box>
                            :
                            <Box sx={{textAlign: "center"}}>
                                <Typography>{live}</Typography>
                                <Typography>Visitors This Month</Typography>
                            </Box>
                        }
                    </Item>
                </Grid>
            </Grid>
        </ MDBox>
    );
};

export default Visitors;