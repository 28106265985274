import React, {useEffect, useState} from "react";
import Box from "@mui/material/Box";

import colors from "assets/theme/base/colors";

const ywpBlue = colors.ywpBlue.main;
const ywpDarkBlue = colors.ywpBlue.focus;

const Advertising = () => {

    return (
        <Box mt={4} p={2} sx={{flexGrow: 1}}>
           Advertising
        </Box>
    );
};

export default Advertising;
