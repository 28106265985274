import Grid from "@mui/material/Grid";
import React, { useEffect, useState } from "react";

import Advertising from "layouts/dashboard/components/WebsiteMetrics/components/Advertising";
import Local from "layouts/dashboard/components/WebsiteMetrics/components/Local";
import PerformanceScores from "layouts/dashboard/components/WebsiteMetrics/components/PerformanceScores";
import Metrics from "layouts/dashboard/components/WebsiteMetrics/components/Metrics";
import WebsiteTraffic from "layouts/dashboard/components/WebsiteMetrics/components/WebsiteTraffic";

import NewReviews from "../NewReviews";
// import WebsiteTraffic from "../WebsiteTraffic";
// import VisitorsCount from "../VisitorsCount";
import { VisitorsCount } from "../VisitorsCount";
import { TrafficSource } from "../TrafficSource";
import PagesVisited from "../PagesVisited";
import VisitorTools from "../VisitorTools";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import MDButton from "components/MDButton";
import ywpYellow from "../../../../assets/theme/base/colors";

// My code
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";
import { Typography } from "@mui/material";

// ==============
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
// ================

import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import AssessmentIcon from "@mui/icons-material/Assessment";
import ReviewsIcon from "@mui/icons-material/Reviews";
import TodayIcon from "@mui/icons-material/Today";
import WifiIcon from "@mui/icons-material/Wifi";
import DateRangeIcon from "@mui/icons-material/DateRange";
import SmartphoneIcon from "@mui/icons-material/Smartphone";
import AccessibilityIcon from "@mui/icons-material/Accessibility";
import LaptopIcon from "@mui/icons-material/Laptop";
import SearchIcon from "@mui/icons-material/Search";
import MDBox from "components/MDBox";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Slide from "@mui/material/Slide";
import colors from "assets/theme/base/colors";

const ywpBlue = colors.ywpBlue.main;
const ywpDarkBlue = colors.ywpBlue.focus;



const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
 

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  marginBottom: theme.spacing(2),
  // textAlign: "center",
  // color: theme.palette.text.secondary,
}));

const WebsiteMetrics = ({ icon, ywpYellow }) => {
  const [value, setValue] = React.useState(0);

  

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const [isDeviceMobile, setIsMobile] = useState(false);
  function handleWindowSizeChange() {
    if (window.innerWidth <= 768) {
        setIsMobile(true);
        return true;
    } else {
        setIsMobile(false);
        return false;
    }
}

useEffect(() => {
    const mobile = handleWindowSizeChange();
    
    console.log("Is Mobile: " + mobile);

    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
        window.removeEventListener('resize', handleWindowSizeChange);
    }
}, []);

  return (
    <Box
      mt={4}
      p={2}
      sx={{ flexGrow: 1 }}
      style={{
        background:
          "linear-gradient(to bottom left, rgba(249,185,20,.2) 0%, rgba(29,115,95,.2) 100%)",
        minHeight: "43vw",
      }}
      className="metricsTabs"
    >
      <Tabs
        value={value}
        orientation={isDeviceMobile ? "vertical" : "horizontal"}
        onChange={handleTabChange}
        aria-label="metrics tabs"
        TabIndicatorProps={{
          style: {
            backgroundColor: ywpDarkBlue,
          },
        }}
        sx={{
          color: "#FF0000",
          "& button:hover": { background: ywpBlue, color: "#FFF" },
          "& button.Mui-selected": { background: ywpDarkBlue, color: "#FFF" },
        }}
        textColor="secondary"
      >
        <Tab label="Website Traffic" {...a11yProps(0)} />
        <Tab label="Website Metrics" {...a11yProps(1)} />
        {/*<Tab label="Visitors" {...a11yProps(1)} />*/}
        {/*<Tab label="Advertising" {...a11yProps(2)} />*/}
        <Tab label="Performance Scores" {...a11yProps(2)} />
      </Tabs>
    
      <Box sx={{ backgroundColor: "#FFF", marginTop: "10px", borderRadius: "5px" }}>
        <TabPanel value={value} index={0}>
          <Box>
            <WebsiteTraffic />
          </Box>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Box>
            <Metrics />
          </Box>
        </TabPanel>
        {/*<TabPanel value={value} index={1}>*/}
        {/*  <Box>*/}
        {/*    <Local />*/}
        {/*  </Box>*/}
        {/*</TabPanel>*/}
        {/*<TabPanel value={value} index={3}>*/}
        {/*  <Box>*/}
        {/*    <Advertising />*/}
        {/*  </Box>*/}
        {/*</TabPanel>*/}
        <TabPanel value={value} index={2}>
          <Box>
            <PerformanceScores />
          </Box>
        </TabPanel>
      </Box>
    </Box>
  );
};

export default WebsiteMetrics;
