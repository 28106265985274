/**
=================================
YourWebPro Contractor Portal Application V2
NodeJS  |  React  |  MaterialUI  |  Material Dash 2
2022 / 2023
=================================
==========================================================
Main components such as charts, boxes, sidenav, dash found in /components directory

Layouts such at Dash, Profile, MapYourProjects, Billing are located in /layouts

Theme files for colors and base layout definitions found in /assets/theme
==========================================================
*/

/**
  The boxShadow() function helps you to create a box shadow for an element
 */

// Material Dashboard 2 React helper functions
import rgba from "assets/theme/functions/rgba";
import pxToRem from "assets/theme/functions/pxToRem";

function boxShadow(offset = [], radius = [], color, opacity, inset = "") {
  const [x, y] = offset;
  const [blur, spread] = radius;

  return `${inset} ${pxToRem(x)} ${pxToRem(y)} ${pxToRem(blur)} ${pxToRem(spread)} ${rgba(
    color,
    opacity
  )}`;
}

export default boxShadow;
