import React, {useState, useEffect} from 'react';
import Stepper from "react-stepper-horizontal";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import {apiUrl, chk} from "App";
import api from "utils/axiosConfig";
import {useSnackbar} from "notistack";
import Typography from "@mui/material/Typography";
import TextField from '@mui/material/TextField';
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";



function Account({setActiveStep, setPassword, setIsGood}) {
    const {enqueueSnackbar} = useSnackbar();
    const [isLoading, setIsLoading] = useState(true)
    const [username, setUsername] = useState(true)
    const [tmpPass, setTmpPass] = useState(true)
    const [passErrorMessage, setPassErrorMessage] = useState('')
    const [passError, setPassError] = useState(false)
    let total_amount = 0;
    useEffect(() => {

        const ck = chk();
        const session = ck.session;
        const cs = ck.cs;

        api.post(apiUrl() + "api/v2/completeSetup/account/", {cs: cs, session: session}).then(response => {
            if (response.data.status === 'ok') {
                setUsername(response.data.username);
                setIsLoading(false);
                setIsGood(false)
            } else {
                const message = "Could not retrieve account info  --  Please contact sales or support";
                const variant = "error";
                enqueueSnackbar(message, {variant});
                setIsLoading(false);
                setActiveStep(-2);
            }

        }).then(response => {

        });
    }, []);

    function setNewPassword(newPass) {
        setPassError(false)
        setPassErrorMessage('');
        if (!/[A-Z]/.test(newPass)) {
            setPassErrorMessage('Password must contain an uppercase character');
            setPassError(true)
            return false;
        }
        if (!/[a-z]/.test(newPass)) {
            setPassErrorMessage('Password must contain a lowercase character');
            setPassError(true)
            return false;
        }
        if (!/[0-9]/.test(newPass)) {
            setPassErrorMessage('Password must contain a number');
            setPassError(true)
            return false;
        }
        if (/^[a-zA-Z0-9]+$/.test(newPass)) {
            setPassErrorMessage('Password must contain a special character');
            setPassError(true)
            return false;
        } setTmpPass(newPass);
    }


    function setRepeatPassword(e) {
        setPassError(false)
        setPassErrorMessage('');
        if(e !== tmpPass){
                setPassErrorMessage('New password and repeat do mot match');
                setPassError(true)
                return false;
        } setPassword(e)
        setIsGood(true)
    }


    if (isLoading) {
        return (
            <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
            >

                <Grid item xs={3}>
                    <CircularProgress color="info" size={120}/>
                </Grid>

            </Grid>
        );
    }

    return (
        <div style={{fontFamily: "Arial"}}>
            {/*<pre>*/}
            {/*     {JSON.stringify(contacts, null, 2)}*/}
            {/*</pre>*/}
            <Card style={{padding: 10}}>
                <Typography style={{fontSize: "14px"}}>
                    Your username is <b>{username}</b><br/>
                    Please be sure to write that down for your records. It's time to set a password.
                    A secure password must:<br/>
                    &bull; Be at least 8 characters long<br/>
                    &bull; Have at least one uppercase letter<br/>
                    &bull; Have at least one number<br/>
                    &bull; Have at least one special character IE (~!@#$%^&*)

                </Typography>
                <TextField
                    style={{backgroundColor: "#FFF", width: "300px", marginTop: "20px"}}
                    type={"password"}
                    id="outlined-helperText"
                    label="Password"
                    onKeyUp={function (e) {
                        setNewPassword(e.target.value);
                    }}
                />
                <br/>
                <TextField
                    type={"password"}
                    style={{backgroundColor: "#FFF", width: "300px", marginTop: "20px"}}
                    id="outlined-helperText"
                    label="Repeat Password"
                    onKeyUp={function (e) {
                        setRepeatPassword(e.target.value);
                    }}
                />
                {(passError) ?
                    <Box style={{
                        border: "2px solid #FF0000",
                        textAlign: "center",
                        color: "#FF0000",
                    }}
                    >
                        {passErrorMessage}
                    </Box> : <></>
                }
            </Card>
        </div>
    );
}

export default Account;