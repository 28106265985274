/**
 =================================
 YourWebPro Contractor Portal Application V2
 NodeJS  |  React  |  MaterialUI  |  Material Dash 2
 2022 / 2023
 =================================
 ==========================================================
 Main components such as charts, boxes, sidenav, dash found in /components directory

 Layouts such at Dash, Profile, MapYourProjects, Billing are located in /layouts

 Theme files for colors and base layout definitions found in /assets/theme
 ==========================================================
 */


import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import {useSnackbar} from "notistack";
import CircularProgress from "@mui/material/CircularProgress";
import api from "utils/axiosConfig";
import {apiUrl, chk} from "App";
import Cookies from 'universal-cookie';
import React from "react";

const cookies = new Cookies();


function ChangePassword() {

    const [currentPass, setCurrentPass] = React.useState('');
    const [newPass, setNewPass] = React.useState('');
    const [repeatPass, setRepeatPass] = React.useState('');
    const [updateLoading, setUpdateLoading] = React.useState(false);
    const [passError, setPassError] = React.useState(false);
    const [passErrorMessage, setPassErrorMessage] = React.useState(false);
    const {enqueueSnackbar} = useSnackbar();


    const passwordRequirements = [
        "One upper case character",
        "One special characters",
        "Min 8 characters",
        "One number (2 are recommended)"
    ];

    const renderPasswordRequirements = passwordRequirements.map((item, key) => {
        const itemKey = `element-${key}`;

        return (
            <MDBox key={itemKey} component="li" color="text" fontSize="1.25rem" lineHeight={1}>
                <MDTypography variant="button" color="text" fontWeight="regular" verticalAlign="middle">
                    {item}
                </MDTypography>
            </MDBox>
        );
    });

    function updatePassword() {

        setPassError(false)
        if (!/[A-Z]/.test(newPass)) {
            setPassErrorMessage('Password must contain an uppercase character');
            setPassError(true)
            return false;
        }
        if (!/[a-z]/.test(newPass)) {
            setPassErrorMessage('Password must contain a lowercase character');
            setPassError(true)
            return false;
        }
        if (!/[0-9]/.test(newPass)) {
            setPassErrorMessage('Password must contain a number');
            setPassError(true)
            return false;
        }
        if (/^[a-zA-Z0-9]+$/.test(newPass)) {
            setPassErrorMessage('Password must contain a special character');
            setPassError(true)
            return false;
        }
        if (repeatPass !== newPass) {
            setPassErrorMessage('New password and repeat do mot match');
            setPassError(true)
            return false;
        }
        setUpdateLoading(true);
        const ck = chk();
        const session = ck.session;
        const cs = ck.cs;
        api.post(apiUrl() + "api/v2/profile/update/password/", {
            cs: cs,
            session: session,
            currentPass: currentPass,
            newPass: newPass,
            repeatPass: repeatPass,

        }).then(response => {
            console.log(response);
            if (response.data.status === "ok") {
                const message = "Your Profile has been saved.";
                const variant = "success";
                enqueueSnackbar(message, {variant});
            } else {
                setPassErrorMessage(response.data.message);
                setPassError(true);
            }
            setUpdateLoading(false);
        });
    }


    return (
        <Card id="change-password">
            <MDBox p={3}>
                <MDTypography variant="h5">Change Password</MDTypography>
            </MDBox>
            <MDBox component="form" pb={3} px={3}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <MDInput
                            fullWidth
                            label="Current Password"
                            inputProps={{type: "password", autoComplete: ""}}
                            onKeyUp={function (e) {
                                setCurrentPass(e.target.value);
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <MDInput
                            fullWidth
                            label="New Password"
                            inputProps={{type: "password", autoComplete: ""}}
                            onKeyUp={function (e) {
                                setNewPass(e.target.value);
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <MDInput
                            fullWidth
                            label="Confirm New Password"
                            inputProps={{type: "password", autoComplete: ""}}
                            onKeyUp={(e) => setRepeatPass(e.target.value)}
                        />
                    </Grid>


                    <Grid item xs={12}>

                        {(passError) ?
                            <MDBox style={{
                                border: "2px solid #FF0000",
                                textAlign: "center",
                                color: "#FF0000",
                            }}
                            >
                                {passErrorMessage}
                            </MDBox> : <></>
                        }


                    </Grid>


                </Grid>
                <MDBox mt={6} mb={1}>
                    <MDTypography variant="h5">Password requirements</MDTypography>
                </MDBox>
                <MDBox mb={1}>
                    <MDTypography variant="body2" color="text">
                        Please follow this guide for a strong password
                    </MDTypography>
                </MDBox>
                <MDBox display="flex" justifyContent="space-between" alignItems="flex-end" flexWrap="wrap">
                    <MDBox component="ul" m={0} pl={3.25} mb={{xs: 8, sm: 0}}>
                        {renderPasswordRequirements}
                    </MDBox>

                    <MDBox ml="auto">
                        {updateLoading && (
                            <MDBox style={{width: "200px", textAlign: "center", float: "right"}}>
                                <CircularProgress color="info" size={30}/>
                            </MDBox>
                        )}
                        {!updateLoading && (
                            <MDButton variant="gradient" color="info" size="small" onClick={updatePassword}>
                                update password
                            </MDButton>
                        )}
                    </MDBox>
                </MDBox>
            </MDBox>
        </Card>
    );
}

export default ChangePassword;
