import React, {useState} from 'react';
import Stepper from 'react-stepper-horizontal';
import Fees from 'layouts/completeSetup/components/Fees';
import Contact from 'layouts/completeSetup/components/Contact';
import Account from 'layouts/completeSetup/components/Account';
import Signature from 'layouts/completeSetup/components/Signature';
import Payment from 'layouts/completeSetup/components/Payment';
import Finished from 'layouts/completeSetup/components/Finished';
import {Image} from 'mui-image';
import BG from "assets/images/desktop-bg.jpg";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

function CompleteSetup() {
    const [activeStep, setActiveStep] = useState(0);
    const [emailAddr, setEmailAddr] = useState('');
    const [password, setPassword] = useState('');
    const [isGood, setIsGood] = useState(true);


    const steps = [
        {title: 'Fees'},
        {title: 'Contact'},
        {title: 'Account'},
        {title: 'Signature'},
        {title: 'Payment'},
        {title: 'Finished'},
    ];


    function getSectionComponent() {
        switch (activeStep) {
            case 0:
                return <Fees setActiveStep={setActiveStep}/>;
            case 1:
                return <Contact
                    setActiveStep={setActiveStep}
                    setEmailAddr={setEmailAddr}
                    setIsGood={setIsGood}
                />;
            case 2:
                return <Account
                    setActiveStep={setActiveStep}
                    setPassword={setPassword}
                    setIsGood={setIsGood}
                />;
            case 3:
                return <Signature
                    setActiveStep={setActiveStep}
                    setIsGood={setIsGood}
                />;
            case 4:
                return <Payment
                    setActiveStep={setActiveStep}
                    setIsGood={setIsGood}
                />;
            case 5:
                return <Finished
                    setActiveStep={setActiveStep}
                    emailAddr={emailAddr}
                    password={password}
                />;
            default:
                return null;
        }
    }

    const styles = {
        mainWrapper: {
            backgroundImage: `url(${BG})`,
            height: '100vh',
            color: "#FFF",
            maxWidth:"750px",
            margin:"auto"
        },
    };


    return (
        <div className="App" style={styles.mainWrapper}>
            <Box sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}>
                <Image src="/images/logo-dark-bg-min.png" width={"273px"} duration={1000}/>
            </Box>
            <Box sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}>
                <Typography sx={{fontSize: "26px"}}>Your Online Showroom is almost ready . . . </Typography>
            </Box>
            <Box sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}>
                <Typography sx={{fontSize: "16px"}}>Complete the following form to launch your new site!</Typography>
            </Box>

            {(activeStep < 0) ?
                <div style={{textAlign: "center", marginTop: "15px", fontFamily: "Arial"}}>
                    There was a problem retrieving your form data. Please contact sales or support to correct this
                    issue.
                </div>
                :
                <Stepper
                    defaultTitleColor={"#FFF"}
                    steps={steps}
                    activeStep={activeStep}
                    activeTitleColor={"#5196FF"}
                    completeTitleColor={"#FFF"}
                />
            }
            <div style={{padding: '20px'}}>
                {getSectionComponent()}
                {(activeStep > 0 && activeStep !== steps.length - 1)
                    && <button style={{
                        background: '#5196FF',
                        width: 100,
                        padding: 10,
                        textAlign: 'center',
                        margin: '0 auto',
                        marginTop: 32,
                        backgroundColor: "#5196FF",
                        color: "#FFF",
                        fontWeight: "bold",
                        float: "left",
                    }} onClick={() => setActiveStep(activeStep - 1)}>Previous</button>
                }
                {activeStep >= 0 && activeStep !== steps.length - 1 && isGood
                    && <button style={{
                        background: '#5196FF',
                        width: 100,
                        padding: 10,
                        textAlign: 'center',
                        margin: '0 auto',
                        marginTop: 32,
                        backgroundColor: "#5196FF",
                        color: "#FFF",
                        fontWeight: "bold",
                        float: "right",
                    }} onClick={() => setActiveStep(activeStep + 1)}>Agree and Continue</button>
                }
            </div>
        </div>
    );
}

export default CompleteSetup;