/**
 =================================
 YourWebPro Contractor Portal Application V2
 NodeJS  |  React  |  MaterialUI  |  Material Dash 2
 2022 / 2023
 =================================
 ==========================================================
 Main components such as charts, boxes, sidenav, dash found in /components directory

 Layouts such at Dash, Profile, MapYourProjects, Billing are located in /layouts

 Theme files for colors and base layout definitions found in /assets/theme
 ==========================================================
 */

// @material-ui core components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Chip from "@mui/material/Chip";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import FormControl from '@mui/material/FormControl';
import colors from "assets/theme/base/colors"

const ywpBlue = colors.ywpBlue.main;
const ywpWhite = colors.ywpWhite.main;

// Settings page components
import FormField from "components/FormField";
import TextField from '@mui/material/TextField';
// Data
import selectData from "layouts/profile/components/BasicInfo/data/selectData";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import React, {useEffect, useState} from "react";
import Box from "@mui/material/Box";

function ProjectInfo({
                         setProjectName,
                         projectName,
                         setClientName,
                         clientName,
                         setClientEmail,
                         clientEmail,
                         setClientPhone,
                         clientPhone,
                         description,
                         setDescription,
                         setDescriptionLen,
                         descriptionLen,
                         descriptionError,
                         setDescriptionError,
                         isDeviceMobile,
                         projectNameError,
                         clientEmailError,
                         clientNameError,
                         projectDisabled,
                         invalidEmail,
                         setInvalidEmail,
                         setClientEmailError
                     }) {


    const [invalidProjectName, setInvalidProjectName] = React.useState(false);


    function handleSetProjectName(e) {
        let value = e
        setInvalidProjectName(false)
        if (/[^0-9a-zA-Z ]/.test(e)) {
            value = value.replace(/[^A-Za-z0-9 ]/ig, '');
            setInvalidProjectName(true);
        }
        setProjectName(value);
    }

    function handleSetClientEmail(e){
        setInvalidEmail(false)
        setClientEmailError(false)
        if (! e.match(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        ) ){
            setInvalidEmail(true)
            setClientEmailError(true)
        }else{
            setClientEmail(e)
        }
    }

    function handleSetDescription(e){
        setDescription(e);
        setDescriptionLen(e.length)
        if(e.length < 55){
            setDescriptionError(true)
        }else{
            setDescriptionError(false)
        }
    }



    return (
        <Card id="project-info" sx={{overflow: "visible"}}>
            <MDBox p={3}>
                <MDTypography variant="h5">Project Information</MDTypography>
            </MDBox>
            <MDBox component="form" pb={3} px={3}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <MDInput
                            disabled={projectDisabled}
                            sx={{border: (projectNameError) ? "1px solid #FF0000" : ''}}
                            onKeyUp={(e) => handleSetProjectName(e.target.value)}
                            defaultValue={projectName}
                            fullWidth
                            label="Project Name"
                            inputProps={{type: "text", autoComplete: ""}}
                        /><br/>
                        {(invalidProjectName) ?
                            <Box><p style={{color: "#FF0000", fontSize: "12px"}}>Invalid Project name changed to:</p>
                                <p style={{color: "#425e3a", fontSize: "12px"}}>{projectName}</p>
                            </Box> : <Box><p style={{ fontSize: "12px"}}>&nbsp;</p>
                                <p style={{ fontSize: "12px"}}>&nbsp;</p>
                            </Box>
                        }

                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <MDInput
                            sx={{border: (clientNameError) ? "1px solid #FF0000" : ''}}
                            onKeyUp={(e) => setClientName(e.target.value)}
                            defaultValue={clientName}
                            fullWidth
                            label="Client Name"
                            inputProps={{type: "text", autoComplete: ""}}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={6}>
                                <MDInput
                                    sx={{border: (clientEmailError) ? "1px solid #FF0000" : ''}}
                                    onKeyUp={(e) => handleSetClientEmail(e.target.value)}
                                    defaultValue={clientEmail}
                                    fullWidth
                                    label="Client Email"
                                    inputProps={{type: "text", autoComplete: ""}}
                                />
                                {(invalidEmail) ?
                                    <Box><p style={{color: "#FF0000", fontSize: "12px"}}>Email address appears to be invalid:</p>
                                    </Box> : <Box><p style={{ fontSize: "12px"}}>&nbsp;</p>
                                    </Box>
                                }
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <MDInput
                                    defaultValue={clientPhone}
                                    onKeyUp={(e) => setClientPhone(e.target.value)}
                                    fullWidth
                                    label="Client Phone"
                                    inputProps={{type: "text", autoComplete: ""}}
                                />
                            </Grid>

                        </Grid>
                    </Grid>

                    <Grid item xs={12} sm={12}>
                        <FormControl fullWidth sx={{m: 1}}>
                            <TextField
                                onKeyUp={(e) => handleSetDescription(e.target.value)}
                                defaultValue={description}
                                id="standard-multiline-flexible"
                                label="Description"
                                multiline
                                maxRows={4}
                                variant="standard"
                            />
                        </FormControl>
                        <span style={{
                            fontSize:"12px",
                            color: (descriptionError) ? "#FF0000" : "#186b18",
                        }}>{descriptionLen} / (55 characters minimum)</span>
                    </Grid>

                </Grid>
            </MDBox>
        </Card>
    );
}

export default ProjectInfo;
