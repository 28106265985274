/**
 =================================
 YourWebPro Contractor Portal Application V2
 NodeJS  |  React  |  MaterialUI  |  Material Dash 2
 2022 / 2023
 =================================
 ==========================================================
 Main components such as charts, boxes, sidenav, dash found in /components directory

 Layouts such at Dash, Profile, MapYourProjects, Billing are located in /layouts

 Theme files for colors and base layout definitions found in /assets/theme
 ==========================================================
 */
import React, {useEffect, useState} from "react";
// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Select, {SelectChangeEvent} from '@mui/material/Select';

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";


function Current({billing}) {

    const [age, setAge] = useState(0);

    const handleChange = (event: SelectChangeEvent) => {
        setAge(event.target.value);
    };

    return (
        <Card sx={{height: "100%"}}>
            <Grid
                container
                spacing={0}
            >
                <Grid item lg={6} xs={12}>
                    <MDBox p={1} display="flex">
                        <MDTypography variant="h1" fontWeight="medium" color="info">
                            {billing.billing.term_amt}<br/>
                            <MDTypography fontWeight="medium" fontSize="16px" color="info">
                                Next Payment
                                <br />
                                {billing.billing.next_bill_day}
                            </MDTypography>
                        </MDTypography>


                    </MDBox>
                </Grid>
                <Grid item
                      lg={6}
                      xs={12}

                >
                    <MDBox p={1} display="flex" justifyContent="right" mt={8} mr={4}>
                        <MDTypography variant="h6" fontWeight="medium" color="info">
                            UP TO DATE
                        </MDTypography>
                    </MDBox>
                </Grid>
            </Grid>
        </Card>


    );
}

export default Current;
