/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";
import ywpYellow from "../../../../assets/theme/base/colors"


// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import colors from "assets/theme/base/colors";


function NewLeads({newLeads}) {
    return (
        <MDBox bgColor="#154676">

            <MDBox p={1} mx={3} display="flex" alignItems="center" justifyContent="center">
                <MDTypography color="ywpYellow" fontSize="2rem">
                    {newLeads}
                </MDTypography>
            </MDBox>
            <MDBox pb={2} textAlign="center">
                <MDTypography color="white" variant="h6" fontWeight="medium" textTransform="capitalize">
                    New Leads
                </MDTypography>
            </MDBox>
        </MDBox>
    );
}


export default NewLeads;
