/**
=================================
YourWebPro Contractor Portal Application V2
NodeJS  |  React  |  MaterialUI  |  Material Dash 2
2022 / 2023
=================================
==========================================================
Main components such as charts, boxes, sidenav, dash found in /components directory

Layouts such at Dash, Profile, MapYourProjects, Billing are located in /layouts

Theme files for colors and base layout definitions found in /assets/theme
==========================================================
*/

// Material Dashboard 2 React base styles
import borders from "assets/theme/base/borders";
import boxShadows from "assets/theme/base/boxShadows";

const { borderRadius } = borders;
const { xxl } = boxShadows;

const dialog = {
  styleOverrides: {
    paper: {
      borderRadius: borderRadius.lg,
      boxShadow: xxl,
    },

    paperFullScreen: {
      borderRadius: 0,
    },
  },
};

export default dialog;
