import React, {useEffect, useState} from "react";
import Box from "@mui/material/Box";

import colors from "assets/theme/base/colors";
import AvgDuration from "layouts/dashboard/components/WebsiteMetrics/components/Metrics/components/avgSessionDuration";
import NewUsers from "layouts/dashboard/components/WebsiteMetrics/components/Metrics/components/newUsers";
import ScreenViews from "layouts/dashboard/components/WebsiteMetrics/components/Metrics/components/screenViews";
import Slide from "@mui/material/Slide";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";


const ywpBlue = colors.ywpBlue.main;
const ywpDarkBlue = colors.ywpBlue.focus;
const ywpYellow = colors.ywpYellow.main;
const ywpDarkYellow = colors.ywpYellow.focus;


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


function TabPanel(props: TabPanelProps) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </div>
    );
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

const WebTraffic = () => {
    const [value, setValue] = React.useState(0);
    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };
    const [isDeviceMobile, setIsMobile] = useState(false);
    function handleWindowSizeChange() {
        if (window.innerWidth <= 768) {
            setIsMobile(true);
            return true;
        } else {
            setIsMobile(false);
            return false;
        }
    }

    useEffect(() => {
        const mobile = handleWindowSizeChange();

        console.log("Is Mobile: " + mobile);

        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    return (
        <Box mt={4} p={2} sx={{flexGrow: 1}}>
            <Tabs
                value={value}
                onChange={handleTabChange}
                aria-label="metrics tabs"
                orientation={isDeviceMobile ? "vertical" : "horizontal"}
                TabIndicatorProps={{
                    style: {
                        backgroundColor: ywpDarkBlue,
                        // width:"25%",
                        margin:"0 auto",
                    }
                }}
                sx={{
                    color:"#FF0000",
                    "& button:hover": { background: ywpBlue, color: "#FFF" },
                    "& button.Mui-selected": { background: ywpYellow,color:ywpDarkBlue },
                    // width: "50%",

                }}

                textColor="secondary"
            >
                <Tab  label="Avg Session Duration" {...a11yProps(0)} />
                <Tab label="New Users" {...a11yProps(1)} />
                <Tab label="Screen Page Views" {...a11yProps(2)} />


            </Tabs>
            <Box sx={{backgroundColor:"#FFF", marginTop:"10px", borderRadius:"5px"}}>
                <TabPanel value={value} index={0}>
                    <Box>
                        <AvgDuration/>
                    </Box>
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <Box>
                        <NewUsers/>
                    </Box>
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <Box>
                        <ScreenViews />
                    </Box>
                </TabPanel>

            </Box>



        </Box>
    );
};

export default WebTraffic;
