// @mui material components
import Box from "@mui/material/Box";
import {useEffect} from "react";
import {apiUrl, chk} from "App";
import api from "utils/axiosConfig";
import {useState} from "react";
import React from "react";
import Typography from "@mui/material/Typography";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Grid from "@mui/material/Grid";
import MDButton from "components/MDButton";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import Icon from "@mui/material/Icon";
import {useSnackbar} from "notistack";
import MDInput from "components/MDInput";
import CircularProgress from "@mui/material/CircularProgress";


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function Dashboard() {
    const {enqueueSnackbar} = useSnackbar();
    const [isLoading, setLoading] = useState(true);
    const [isSaveLoading, setSaveLoading] = useState(false);
    const [getPageLoading, setGetPageLoading] = useState(false);
    const [isNewPage, setIsNewPage] = useState(false);
    const [customPages, setcustomPages] = useState(false);
    const [page, setPage] = useState({});
    const [domain, setDomain] = useState("https://example.com/");
    const [url, setUrl] = useState("");
    const [meta, setMeta] = useState("");
    const [title, setTitle] = useState("");
    const [name, setName] = useState("");
    const [html, setHTML] = useState("");
    const [newHtml, setNewHTML] = useState("");
    const [deleteOpen, setDeleteOpen] = useState("");
    const [pageId, setPageId] = useState("");
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [blankSlate, setBlankSlate] = useState("<div>\n" +
        "    Custom Content\n" +
        "    <br />\n" +
        "    <br />\n" +
        "    <br />\n" +
        "    <br />\n" +
        "    <br />\n" +
        "    <br />\n" +
        "</div>");


    useEffect(() => {

        const ck = chk();
        const session = ck.session;
        const cs = ck.cs;

        api.post(apiUrl() + "api/v2/Website/CustomContent/LandingPages/getAll/", {cs: cs, session: session}).then(response => {
            if (response.data.status === 'ok') {
                setcustomPages(response.data.custom_pages);
                setDomain("https://" + response.data.domain + "/");
            }
            setLoading(false);
        });
    }, []);


    function saveLandingPage() {
        setSaveLoading(true);
        const ck = chk();
        const session = ck.session;
        const cs = ck.cs;
        if (!name) {
            const message = "Name Cannot Be Empty";
            const variant = "error";
            enqueueSnackbar(message, {variant});
            setSaveLoading(false);
            return false;
        }
        if (!title) {
            const message = "Title Cannot Be Empty";
            const variant = "error";
            enqueueSnackbar(message, {variant});
            setSaveLoading(false);
            return false;
        }
        if (!url) {
            const message = "URL Cannot Be Empty";
            const variant = "error";
            enqueueSnackbar(message, {variant});
            setSaveLoading(false);
            return false;
        }
        if (!meta) {
            const message = "Meta Description Be Empty";
            const variant = "error";
            enqueueSnackbar(message, {variant});
            setSaveLoading(false);
            return false;
        }
        api.post(apiUrl() + "api/v2/Website/CustomContent/LandingPages/save/", {
            id: pageId,
            name: name,
            title: title,
            url: url,
            meta: meta,
            html: newHtml,
            cs: cs,
            session: session
        }).then(response => {
            if (response.data.status === 'ok') {
                const message = "Page Successfully Saved";
                const variant = "success";
                enqueueSnackbar(message, {variant});
                setPage(response.data.page);
                setcustomPages(response.data.pages);
            } else {
                const message = response.data.message;
                const variant = "error";
                enqueueSnackbar(message, {variant});
            }
            setSaveLoading(false);
        });
    }

    function saveNewLandingPage() {
        setSaveLoading(true)
        const ck = chk();
        const session = ck.session;
        const cs = ck.cs;
        if (!name) {
            const message = "Name Cannot Be Empty";
            const variant = "error";
            enqueueSnackbar(message, {variant});
            setSaveLoading(false);
            return false;
        }
        if (!title) {
            const message = "Title Cannot Be Empty";
            const variant = "error";
            enqueueSnackbar(message, {variant});
            setSaveLoading(false);
            return false;
        }
        if (!url) {
            const message = "URL Cannot Be Empty";
            const variant = "error";
            enqueueSnackbar(message, {variant});
            setSaveLoading(false);
            return false;
        }
        if (!meta) {
            const message = "Meta Description Be Empty";
            const variant = "error";
            enqueueSnackbar(message, {variant});
            setSaveLoading(false);
            return false;
        }
        api.post(apiUrl() + "api/v2/Website/CustomContent/LandingPages/create/", {
            name: name,
            title: title,
            url: url,
            meta: meta,
            html: newHtml,
            cs: cs,
            session: session
        }).then(response => {
            if (response.data.status === 'ok') {
                const message = "Page Successfully Saved";
                const variant = "success";
                enqueueSnackbar(message, {variant});
                setPage(response.data.page);
                setPageId(response.data.page.id);
                setcustomPages(response.data.pages);
            } else {
                const message = response.data.message;
                const variant = "error";
                enqueueSnackbar(message, {variant});
            }
            setSaveLoading(false);
        });
    }

    function addPage() {
        setIsNewPage(true);
        setPage({});
        setHTML(blankSlate);
        setNewHTML(blankSlate)
        setTitle('');
        setName('');
        setUrl('');
        setMeta('');
    }

    const getPage = (e) => {
        setIsNewPage(false);
        setGetPageLoading(true);
        setPage({});
        const id = e.currentTarget.getAttribute("data-id")
        setPageId(id);
        const ck = chk();
        const session = ck.session;
        const cs = ck.cs;
        api.post(apiUrl() + "api/v2/Website/CustomContent/LandingPages/get/", {cs: cs, session: session, id: id}).then(response => {
            if (response.data.status === 'ok') {
                setPage(response.data.page);
                setHTML(response.data.page.html)
                setNewHTML(response.data.page.html)
                setName(response.data.page.name)
                setTitle(response.data.page.title)
                setUrl(response.data.page.url)
                setMeta(response.data.page.meta)
                setDomain("https://" + response.data.page.domain + '/')
            }
            setGetPageLoading(false);
        });
    }


    function handleSetUrl(e) {
        const regExp = /^[A-Za-z0-9-_]+$/;
        if (e.match(regExp)) {
            setUrl(e)
        } else {
            const message = "Your URL can only contain numbers, letters, dashes and underscores.";
            const variant = "error";
            enqueueSnackbar(message, {variant});
            const modUrl = e.replace(/[^A-Za-z0-9-_]/g, '');
            setUrl(modUrl)
        }
    }

    function handleSetMeta(e) {
        const regExp = /^[A-Za-z0-9-_| ]+$/;
        if (e.match(regExp)) {
            setMeta(e)
        } else {
            const message = "Your Description can only contain [A-Z] [0-9] - _ | and spaces.";
            const variant = "error";
            enqueueSnackbar(message, {variant});
            const modUrl = e.replace(/[^A-Za-z0-9-_| ]/g, '');
            setMeta(modUrl)
        }
    }

    function handleSetTitle(e) {
        const regExp = /^[A-Za-z0-9-_| ]+$/;
        if (e.match(regExp)) {
            setTitle(e)
        } else {
            const message = "Your Title can only contain [A-Z] [0-9] - _ | and spaces.";
            const variant = "error";
            enqueueSnackbar(message, {variant});
            const modUrl = e.replace(/[^A-Za-z0-9-_| ]/g, '');
            setTitle(modUrl)
        }
    }

    function handleSetName(e) {
        const regExp = /^[A-Za-z0-9-_| ]+$/;
        if (e.match(regExp)) {
            setName(e)
        } else {
            const message = "Your Name can only contain [A-Z] [0-9] - _ | and spaces.";
            const variant = "error";
            enqueueSnackbar(message, {variant});
            const modUrl = e.replace(/[^A-Za-z0-9-_| ]/g, '');
            setName(modUrl)
        }
    }

    const handleDeleteClose = () => {
        setDeleteOpen(false);
    };
    const handleDeleteOpen = (e) => {
        const page_id = e.currentTarget.getAttribute("data-page_id");
        setPageId(page_id);
        setDeleteOpen(true);
    };

    function handleDeletePage() {
        setDeleteLoading(true);
        const ck = chk();
        const session = ck.session;
        const cs = ck.cs;
        api.post(apiUrl() + "api/v2/Website/CustomContent/LandingPages/delete/", {cs: cs, session: session, id: pageId}).then(response => {
            if (response.data.status === 'ok') {
                const message = "Page successfully deleted.";
                const variant = "success";
                enqueueSnackbar(message, {variant});
            } else {
                const message = "There was a problem deleting the page, please try again.";
                const variant = "error";
                enqueueSnackbar(message, {variant});
            }
            setIsNewPage(false);
            setPage({})
            setcustomPages(response.data.pages);
            setDeleteLoading(false);
            setPageId('');
            handleDeleteClose();
        });

    }

    const handleInput = (event) => {
        setNewHTML(event.currentTarget.innerHTML.replaceAll('&lt;', '<').replaceAll('&gt;', '>'))
    }


    if (isLoading) {
        return (
            <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
                style={{minHeight: '80vh'}}
            >
                <Grid item xs={3}>
                    <CircularProgress color="info" size={120}/>
                </Grid>
            </Grid>
        )
    }

    return (
        <Box sx={{minHeight: "300px;", margin: "50px"}}>
            <Grid container spacing={3}>
                <Grid p={2} item lg={3} sx={{minHeight: "300px;", backgroundColor: "#FFF"}}>
                    <Box sx={{borderBottom: "1px solid #DDD", maxHeight: "550px", overflowX: "hidden", overflowY: "scroll"}}>
                        {
                            customPages.map((item) =>
                                <Grid container spacing={3} sx={{marginTop: "-35px"}}>
                                    <Grid p={2} item lg={10}>
                                        <MDButton
                                            key={item.id}
                                            data-id={item.id}
                                            sx={{marginTop: "15px", width: "100%"}}
                                            color="info"
                                            size="small"
                                            onClick={getPage}>
                                            {item.name}
                                        </MDButton>
                                    </Grid>
                                    <Grid item lg={2} style={{fontSize: "40px", cursor: "pointer"}}>
                                        <Icon sx={{
                                            color: "#FF0000",
                                            marginTop: "10px",
                                        }}
                                              onClick={handleDeleteOpen}
                                              data-page_id={item.id}
                                        >delete_forever</Icon>
                                    </Grid>
                                </Grid>
                            )
                        }
                    </Box>
                    <MDButton variant="outlined"
                              sx={{marginTop: "15px", width: "100%"}}
                              color="info"
                              size="small"
                              onClick={addPage}>
                        + Add Custom Landing Page
                    </MDButton>
                </Grid>
                <Grid item lg={9} sx={{minHeight: "400px;"}}>
                    {(isNewPage || page.html) ?
                        <>
                            <Grid container spacing={3}>
                                <Grid p={2} item lg={4}>
                                    <MDInput
                                        fullWidth
                                        label="Page Name"
                                        inputProps={{type: "text"}}
                                        onChange={(e) => handleSetName(e.target.value)}
                                        value={name}
                                    />
                                </Grid>
                                <Grid p={2} item lg={4}>
                                    <MDInput
                                        fullWidth
                                        label="Page Title"
                                        inputProps={{type: "text"}}
                                        onChange={(e) => handleSetTitle(e.target.value)}
                                        value={title}
                                    />
                                </Grid>
                                <Grid p={2} item lg={4}>

                                    <MDInput
                                        fullWidth
                                        label="Page URL"
                                        inputProps={{type: "text"}}
                                        onChange={(e) => handleSetUrl(e.target.value)}
                                        value={url}

                                    />

                                </Grid>
                                <Grid p={2} item lg={12}>
                                    <MDInput
                                        fullWidth
                                        label="Meta Description"
                                        inputProps={{type: "text", maxLength: 128}}
                                        onChange={(e) => handleSetMeta(e.target.value)}
                                        value={meta}
                                        maxLength={10}
                                    />
                                </Grid>
                            </Grid>
                            Custom HTML
                            <Box sx={{textAlign: "right"}} p={2}>
                                <Typography sx={{fontSize: "14px", fontWeight:"bold"}}>
                                    <a href={domain + url} target={"_blank"}>{domain + url}</a>
                                </Typography>
                                {(isSaveLoading) ?
                                    <CircularProgress color="info" size={40}/> :
                                    <>
                                        {(page.name) ? <MDButton color="info" onClick={saveLandingPage}>Save Landing Page</MDButton> : <MDButton color="info" onClick={saveNewLandingPage}>Create Landing Page</MDButton>}
                                    </>
                                }
                            </Box>
                            <Box sx={{maxHeight:"700px", overflowX: "hidden", overflowY: "scroll"}}>


                            <pre
                                contentEditable="true"
                                style={{
                                    whiteSpace: "pre-wrap",
                                    wordWrap: "break-word",
                                    fontSize: "12px",
                                    width: "98%",
                                    backgroundColor: "#FFF",
                                    border:"1px solid #DDD",
                                    padding: "10px 0px 0px 30px",
                                    color: "#1D73C3",
                                    outline: "0px solid transparent",
                                }}
                                // onInput={handleInput}
                                onKeyUp={e => setNewHTML(e.currentTarget.innerText)}
                            >
                            {html}
                            </pre>
                            </Box>
                            <Box sx={{textAlign: "right"}} p={2}>
                                {(isSaveLoading) ?
                                    <CircularProgress color="info" size={40}/> :
                                    <>
                                        {(page.name) ? <MDButton color="info" onClick={saveLandingPage}>Save Landing Page</MDButton> : <MDButton color="info" onClick={saveNewLandingPage}>Create Landing Page</MDButton>}
                                    </>
                                }
                            </Box>
                        </>
                        :
                        <>
                            {/*<pre>{JSON.stringify(customPages, null, 2)}</pre>*/}
                        </>
                    }
                    {(getPageLoading) ?
                        <Grid
                            container
                            spacing={0}
                            direction="column"
                            alignItems="center"
                            justifyContent="center"
                            style={{minHeight: '40vh'}}
                        >

                            <Grid item xs={3}>
                                <CircularProgress color="info" size={120}/>
                            </Grid>

                        </Grid>
                        :
                        <>
                            {/*<pre>{JSON.stringify(page, null, 2)}</pre>*/}
                        </>
                    }
                </Grid>
            </Grid>


            <Dialog
                open={deleteOpen}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleDeleteClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{"Are you sure you want to delete this page?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        This will permanently remove the page from {domain}.
                        This action cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {(deleteLoading) ?
                        <CircularProgress color="info" size={40}/>
                        :
                        <>
                            <MDButton onClick={handleDeleteClose} variant="outlined" color="info">Cancel</MDButton>
                            <MDButton id="deletePinSubmit" onClick={handleDeletePage} variant="outlined" color="error">
                                Delete Page
                            </MDButton>
                        </>
                    }
                </DialogActions>
            </Dialog>


        </Box>
    );
}

export default Dashboard;