import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from "@mui/material/Grid";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";

export default function CircularIndeterminate() {
    return (

        <DashboardLayout>
            <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
                style={{minHeight: '80vh'}}
            >

                <Grid item xs={3}>
                    <CircularProgress color="info" size={120}/>
                </Grid>

            </Grid>
        </DashboardLayout>

    );
}